

const TestCaseData = (val, language) => {
  let data = 
    {
      javascript: {
        maincode: ` // Don't change the function name(main)
function main(args1 , args2){ 
              // You can change the parameters
              // Make sure to return the output
              // No need to call function
}`,
        testcode: `
      const obj = { fn: main };
      const results = [];
      ${JSON.stringify(val)}.forEach(parameters => {
        if (typeof obj['fn'] === 'function') {
          const result = obj['fn'](...parameters);
          results.push([result]);
        } else {
          console.error('Function does not exist');
        }
      });
      console.log(results);
    `,
      },
      python:{
        maincode:`
# Don't change the defination name(main)
def main(args1):  
        # You can change the parameters    
        # Add your logic here
        # Make sure you are returning the output`,
        testcode: `\n    
obj = {'fn': main}
results = []
        
for parameters in ${JSON.stringify(val)}:
    if callable(obj['fn']):
         result = obj['fn'](*parameters)
         results.append([result])
    else:
         print('Function does not exist')
        
print(results)
        `
      }
    }
  


  return data[language];
};

export { TestCaseData };
