import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { replaceEducation } from "../../../../store/EducationSlice";
import { postrequest } from "../../../Middleware/managerequest";

const AddEducationModal = ({ show, setShow }) => {

  const { user } = useSelector((state) => state.Data);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    year: "",
    title: "",
    description: "",
    CurrentlyAppearing: false,
  });

  const handleClose = () => setShow(false);

  const handleSave = (data) => {
    // Handle saving data
    if (formData.title !== "" && formData.description !== "") {
      postrequest(
        `/api/v1/user/personal_details/${user.Email}/?sec=education&&operation=add`,
        formData
      )
        .then((res) => {
          dispatch(replaceEducation(res.data.EducationDetails));
          setFormData({
            year: "",
            title: "",
            description: "",
            CurrentlyAppearing: false,
          });
        })
        .catch((err) => {
          console.log(err);
        });

      handleClose();
    } else {
      alert("Please fill all the required details");
    }
  };

  const handleCheckboxChange = () => {
    setFormData({
      ...formData,
      CurrentlyAppearing: !formData.CurrentlyAppearing,
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Education</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="degree">
              <Form.Label>Degree</Form.Label>
              <Form.Control
                type="text"
                name="degree"
                value={formData.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="university">
              <Form.Label>University</Form.Label>
              <Form.Control
                type="text"
                name="university"
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="yearCompleted">
              <Form.Label>Year Completed</Form.Label>
              <Form.Control
                type="text"
                name="yearCompleted"
                value={formData.year}
                onChange={(e) =>
                  setFormData({ ...formData, year: e.target.value })
                }
                disabled={formData.CurrentlyAppearing}
              />
            </Form.Group>
            <Form.Group controlId="currentlyAppearing">
              <Form.Check
                type="checkbox"
                label="Currently Appearing"
                checked={formData.CurrentlyAppearing}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleSave(formData)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddEducationModal;
