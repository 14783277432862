import styles from "./FreeTrailCreate.module.css";
import Carousel from "react-bootstrap/Carousel";
import { useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Overlay from "react-bootstrap/Overlay";
import { toast, ToastContainer } from "react-toastify";
import { postrequest } from "../../Middleware/managerequest";
import data from "../../../utils/data.json";
import { EyeInvisibleFilled, LoadingOutlined } from "@ant-design/icons";
import assessments1 from "../../../icons/assessments1.jpg";
import questionhuge from "../../../icons/image 5.svg";
import JustDigikullLogo from "../../../icons/JUSTDigikull-Logo-7000x7000 1.svg";
import fullname from "../../../icons/mingcute_user-4-fill.svg";
import organizationname from "../../../icons/Group.svg";
import email from "../../../icons/clarity_email-solid.svg";
import password from "../../../icons/mdi_password.svg";
import eye from "../../../icons/ion_eye.svg";
import country from "../../../icons/fluent-mdl2_world.svg";
import { Input, Modal } from "antd";

const FreeTrailCreate = () => {
  const [index, setIndex] = useState(0);
  const [showpass, setShowpass] = useState(false);
  const [show, setShow] = useState(-1);
  const target = useRef();
  const target1 = useRef();
  const target2 = useRef();
  const target3 = useRef();
  const target4 = useRef();
  const target5 = useRef();
  const [issendotp, setIssendotp] = useState(false);
  const [Temp, setTempData] = useState({ email: "abc@xyz.com" });
  const [isotpentered, setIsotpentered] = useState(false);
  const [userdata, setUserdata] = useState({
    name: "",
    companyname: "Digikull",
    email: "",
    mobile: "",
    password: "",
    country: "",
  });
  
  const [isuserreg, setIsuserreg] = useState(false);
  const [isworkmail, setIsworkmail] = useState(false);
  const [iscompanyreg, setCompanyreg] = useState(false);

  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  // Final Submit

  const handleSubmit = (event) => {

    if (userdata.name === "") {
      setShow(0);
    } else if (userdata.companyname === "") {
      setShow(1);
    } else if (userdata.email === "") {
      setShow(2);
    } else if (userdata.mobile.length !== 12) {
      setShow(3);
    } else if (userdata.password === "" || passwordError !== "") {
      setShow(4);
    } else if (userdata.country === "") {
      setShow(5);
    }
    else{

    

    const domainRegex = /@(.+)/;
    const domainMatch = userdata.email
      .toLowerCase()
      .match(domainRegex);
    if (domainMatch && domainMatch.length > 1) {
      const mailproviders = [
        "gmail.com",
        "hotmail.com",
        "yahoo.com",
        "outlook.com"
      ];
      if (mailproviders.includes(domainMatch[1])) {
        setShow(2)
        setIsworkmail(true);

      } else {
        let obj = {
          name: userdata.name,
          mobile: "+"+userdata.mobile,
          email: userdata.email,
          password:userdata.password,
          subdomain: domainMatch[1].toLowerCase(),
          company:userdata.companyname,
          country:userdata.country
        };
        postrequest(`/home/companyregistration`,obj)
          .then((res) => {
            if (res.status === 200) {
              setTempData(obj)
              setIssendotp(true)
              // navigate("/userverification",{state:{org:true}});
            } else if (res.status === 201) {
              toast.error("Error while Sending OTP", {
                position: "top-center",
                autoClose: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response.status === 400) {
              setShow(2)
              setIsuserreg(true);
            } else if (err.response.status === 401) {
              setShow(1)
              setCompanyreg(true);
            } else {
              toast.error("Server Not Responding", {
                position: "top-center",
                autoClose: 1500,
              });
            }
          });
      }
    }
  }
  };

  const validatePassword = (value) => {
    const errors = [];

    if (value.length < 8) {
      errors.push("Password must be at least 8 characters long.");
    }

    if (!/[A-Z]/.test(value)) {
      errors.push("Password must contain at least one uppercase letter.");
    }

    if (!/\d/.test(value)) {
      errors.push("Password must contain at least one number.");
    }

    if (!/[@#$%^&+=]/.test(value)) {
      errors.push("Password must contain at least one special character.");
    }

    if (errors.length > 0 && value.length !== 0) {
      setPasswordError(errors);
    } else {
      setPasswordError("");
    }
  };

  const getColorForIndex = (ind) => {
    if (index === ind) {
      return "#08213E";
    } else {
      return "#CEE8FF";
    }
  };

  const containerStyle = {
    width: window.innerWidth < 750 ? "230px" : "250px",
    maxWidth: "250px",
    marginLeft: "0px",
    height: "40px",
    border: "1px solid #CEE8FF",
    borderRadius: "10px",
    marginRight: window.innerWidth < 550 ? "27px" : "0",
  };

  const resend = () => {
    let obj = {
      email: Temp.email,
      subdomain: Temp.subdomain,
    };
    postrequest(`/home/userverification`, obj).then((res) => {
      if (res.status === 201) {
        toast.success("OTP send successfully", {
          position: "top-center",
          autoClose: 1500,
        });
      }
    });
  };

  const Submit = (OTP) => {
    console.log(OTP);
    let obj = {
      ...Temp,
      otp: OTP,
    };
    setIsotpentered(true);
    postrequest(`/home/userverification`, obj)
      .then((res) => {
        setIsotpentered(false);
        if (res.status === 201) {
          toast.error("OTP is Expired", {
            position: "top-center",
            autoClose: 1500,
          });
        } else {
          toast.success("OTP verified", {
            position: "top-center",
            autoClose: 1500,
          });
         
            navigate("/subscribed");
          
        }
      })
      .catch((err) => {
        setIsotpentered(false);
        if (err?.response?.status === 400) {
          toast.error("Please check OTP", {
            position: "top-center",
            autoClose: 1500,
          });
        } else {
          toast.error("Server not responding", {
            position: "top-center",
            autoClose: 1500,
          });
        }
      });
  };


  return (
    <div className={styles.main}>
       <Modal
        title="OTP Verification"
        closable={false}
        footer={null}
        maskClosable={false}
        open={issendotp}
      >
        {isotpentered ? (
          <center>
            <LoadingOutlined style={{ fontSize: "25px" }} />
            <br />
            <label>
              We are verifying your OTP and signing you in. Please wait.
            </label>
          </center>
        ) : (
          <center>
            <label>
              We send One-Time-Password to <b>{Temp?.email ?? ""}</b>
            </label>
          
            <Input.OTP
              disabled={isotpentered}
              length={4}
              onChange={(e) => Submit(e)}
            />
            <br />
            <label style={{ cursor: "pointer" }} onClick={() => resend()}>
              <u>Resend OTP</u>
            </label>
          </center>
        )}
      </Modal>
      <ToastContainer />
      <div className={styles.container}>
        <div className={styles.leftSidebar}>
          <div className={styles.navbar}>
            <img src={JustDigikullLogo} alt="error" />
            <span>Digikull</span>
          </div>

          <div className={styles.rightSidebarContext}>
            <label className={styles.label1}>Create Your Account </label>
            <br />
            <label className={styles.label2}>
              We will personalize your setup experience accordingly
            </label>

            <div className={styles.formSection}>
              {/* First Line */}
              <div className={styles.Firstline}>
                {/* Full Name */}
                <div className={styles.childdivs}>
                  <label>Full Name</label>
                  <div className={styles.leftInput} ref={target}>
                    <img src={fullname} alt="fullname" />
                    <input
                      type="text"
                      placeholder="Full Name"
                      onChange={(e) => {
                        setUserdata({ ...userdata, name: e.target.value });
                        setShow(-1);
                      }}
                      value={userdata.name}
                    />
                  </div>
                  <Overlay
                    target={target.current}
                    show={show === 0}
                    placement="right"
                  >
                    {({
                      placement: _placement,
                      arrowProps: _arrowProps,
                      show: _show,
                      popper: _popper,
                      hasDoneInitialMeasure: _hasDoneInitialMeasure,
                      ...props
                    }) => (
                      <div
                        {...props}
                        style={{
                          position: "absolute",
                          backgroundColor: "rgba(255, 100, 100, 0.85)",
                          padding: "2px 10px",
                          color: "white",
                          borderRadius: 3,
                          ...props.style,
                        }}
                      >
                        Please enter full name
                      </div>
                    )}
                  </Overlay>
                </div>
                {/*  Organization name */}
                <div className={styles.childdivs}>
                  <label>Organization Name</label>
                  <div className={styles.leftInput} ref={target1}>
                    <img src={organizationname} alt="org" />
                    <input
                      type="text"
                      placeholder="Company or Institute Name"
                      onChange={(e) => {
                        setUserdata({
                          ...userdata,
                          companyname: e.target.value,
                        });
                        setShow(-1);
                      }}
                      value={userdata.companyname}
                    />
                    <Overlay
                      target={target1.current}
                      show={show === 1}
                      placement="left"
                    >
                      {({
                        placement: _placement,
                        arrowProps: _arrowProps,
                        show: _show,
                        popper: _popper,
                        hasDoneInitialMeasure: _hasDoneInitialMeasure,
                        ...props
                      }) => (
                        <div
                          {...props}
                          style={{
                            position: "absolute",
                            backgroundColor: "rgba(255, 100, 100, 0.85)",
                            padding: "2px 10px",
                            color: "white",
                            borderRadius: 3,
                            ...props.style,
                          }}
                        >
                          {iscompanyreg
                            ? "Company is already registered."
                            : "Please enter Company or Institude name."}
                        </div>
                      )}
                    </Overlay>
                  </div>
                </div>
              </div>
              {/* Second Line */}
              <div className={styles.secondline}>
                {/*Business Email */}
                <div className={styles.childdivs}>
                  <label>Organization Email</label>
                  <div className={styles.leftInput} ref={target2}>
                    <img src={email} alt="email" />
                    <input
                      type="text"
                      placeholder="Business Email"
                      onChange={(e) => {
                        setUserdata({ ...userdata, email: e.target.value });
                        setShow(-1);
                      }}
                      value={userdata.email}
                    />
                  </div>
                  <Overlay
                    target={target2.current}
                    show={show === 2}
                    placement="right"
                  >
                    {({
                      placement: _placement,
                      arrowProps: _arrowProps,
                      show: _show,
                      popper: _popper,
                      hasDoneInitialMeasure: _hasDoneInitialMeasure,
                      ...props
                    }) => (
                      <div
                        {...props}
                        style={{
                          position: "absolute",
                          backgroundColor: "rgba(255, 100, 100, 0.85)",
                          padding: "2px 10px",
                          color: "white",
                          borderRadius: 3,
                          ...props.style,
                        }}
                      >
                        {isworkmail ? "Please enter business email.":isuserreg?"User Already Present.":"Please enter email"}
                        
                        
                      </div>
                    )}
                  </Overlay>
                </div>
                {/* Mobile Number */}
                <div className={styles.phoneSection} >
                  <label className="mx-2">Phone Number</label>

                  <div className={styles.mobilenumber} ref={target3}>
                    <PhoneInput
                      country={"in"}
                      enableSearch={true}
                      inputStyle={containerStyle}
                      searchClass={styles.search}
                      onChange={(e) => {
                        setUserdata({ ...userdata, mobile: e });
                        setShow(-1);
                      }}
                      value={userdata.mobile}
                    />
                  </div>
                  <Overlay
                    target={target3.current}
                    show={show === 3}
                    placement="left"
                  >
                    {({
                      placement: _placement,
                      arrowProps: _arrowProps,
                      show: _show,
                      popper: _popper,
                      hasDoneInitialMeasure: _hasDoneInitialMeasure,
                      ...props
                    }) => (
                      <div
                        {...props}
                        style={{
                          position: "absolute",
                          backgroundColor: "rgba(255, 100, 100, 0.85)",
                          padding: "2px 10px",
                          color: "white",
                          borderRadius: 3,
                          ...props.style,
                        }}
                      >
                        Please enter valid mobile number.
                      </div>
                    )}
                  </Overlay>
                </div>
              </div>
              {/* Third Line */}
              <div className={styles.thirdline}>
                {/* Password */}
                <div className={styles.childdivs}>
                  <label>Password</label>
                  <div className={styles.password} ref={target4}>
                    <div className={styles.pswInp}>
                      <img src={password} alt="password" />
                      <input
                        type={!showpass ? "password" : "text"}
                        placeholder="Password"
                        onChange={(e) => {
                          setUserdata({
                            ...userdata,
                            password: e.target.value,
                          });
                          setShow(-1);
                          validatePassword(e.target.value);
                        }}
                        value={userdata.password}
                      />
                    </div>
                    <Overlay
                      target={target4.current}
                      show={show === 4}
                      placement="right"
                    >
                      {({
                        placement: _placement,
                        arrowProps: _arrowProps,
                        show: _show,
                        popper: _popper,
                        hasDoneInitialMeasure: _hasDoneInitialMeasure,
                        ...props
                      }) => (
                        <div
                          {...props}
                          style={{
                            position: "absolute",
                            backgroundColor: "rgba(255, 100, 100, 0.85)",
                            padding: "2px 10px",
                            color: "white",
                            borderRadius: 3,
                            ...props.style,
                          }}
                        >
                          Please enter valid password.
                        </div>
                      )}
                    </Overlay>

                    <div>
                      <div className={styles.eye}>
                        {!showpass ? (
                          <img
                            src={eye}
                            alt="show"
                            style={{ width: "16px" }}
                            onClick={() => setShowpass(true)}
                          />
                        ) : (
                          <EyeInvisibleFilled
                            onClick={() => setShowpass(false)}
                            className={styles.hidepass}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {passwordError && (
                    <div className={styles.password_validation}>
                      <ul>
                        {" "}
                        {passwordError?.map((item, index) => {
                          return <li key={index}>{item}</li>;
                        })}
                      </ul>
                    </div>
                  )}
                </div>

                {/* Country */}
                <div className={styles.childdivs}>
                  <label>Country</label>
                  <div className={styles.leftInput} ref={target5}>
                    <img src={country} alt="error" />
                    <input
                      type="text"
                      placeholder="Country"
                      onChange={(e) =>
                        setUserdata({ ...userdata, country: e.target.value })
                      }
                      value={userdata.country}
                    />
                  </div>
                  <Overlay
                    target={target5.current}
                    show={show === 5}
                    placement="left"
                  >
                    {({
                      placement: _placement,
                      arrowProps: _arrowProps,
                      show: _show,
                      popper: _popper,
                      hasDoneInitialMeasure: _hasDoneInitialMeasure,
                      ...props
                    }) => (
                      <div
                        {...props}
                        style={{
                          position: "absolute",
                          backgroundColor: "rgba(255, 100, 100, 0.85)",
                          padding: "2px 10px",
                          color: "white",
                          borderRadius: 3,
                          ...props.style,
                        }}
                      >
                        Please enter country name.
                      </div>
                    )}
                  </Overlay>
                </div>
              </div>
            </div>
{/* Submit and login */}
            <div className={styles.btnDiv}>
              <button className={styles.btn} onClick={() => handleSubmit()}>
                Create Account
              </button>
              <label
                className={styles.alreadyaccount}
                onClick={() => {
                  
                  if (state?.id !== undefined) {
                    let url=state?.external?encodeURIComponent(`id=${state.id}&client_id=${state.client_id}&ref=external`):encodeURIComponent(`id=${state.id}&client_id=${state.client_id}`)
                    navigate(`/?${url}`, {
                      state: { id: state.id,client_id:state?.client_id },
                    });
                  } else {
                    navigate("/");
                  }
                }}
              >
                I already have a Account.
              </label>
            </div>
          </div>
        </div>
{/* Right Side */}
        <div className={styles.rightSidebar}>
          <div className={styles.rightImage}>
            <Carousel
              data-bs-theme="dark"
              indicators={false}
              controls={false}
              interval={1000}
              activeIndex={index}
              onSelect={handleSelect}
            >
              <Carousel.Item>
                <div className={styles.images}>
                  <img
                    className={styles.carousel}
                    src={
                      "https://c4.wallpaperflare.com/wallpaper/306/656/57/question-question-mark-red-black-wallpaper-preview.jpg"
                    }
                    alt="Third slide"
                    height={130}
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className={styles.images}>
                  <img
                    className={styles.carousel}
                    src={questionhuge}
                    alt="Third slide"
                    height={130}
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className={styles.images}>
                  <img
                    className={styles.carousel}
                    src={assessments1}
                    alt="Third slide"
                    height={130}
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>

          <center>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "50%",
                marginTop: "10px", // Adjust the width as needed
              }}
            >
              {data?.map((item, ind) => {
                return (
                  <div
                    style={{
                      flex: 1,
                      height: "3px",
                      background: getColorForIndex(ind),
                    }}
                    key={ind}
                  ></div>
                );
              })}
            </div>
          </center>

          <div className={styles.rightSidebarTxt}>
            <label className={styles.first}>{data[index].heading}</label>
            <br />
            <label className={styles.descriptiontext}>
              <small>{data[index].Description}</small>
            </label>
          </div>

          <div className={styles.Dot}>
            <div
              className={index === 0 ? styles.dot_sub1 : styles.dot_sub2}
            ></div>
            <div
              className={index === 1 ? styles.dot_sub1 : styles.dot_sub2}
            ></div>
            <div
              className={index === 2 ? styles.dot_sub1 : styles.dot_sub2}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FreeTrailCreate;
