import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  getrequest,
  postrequest,
  putrequest,
} from "../../Middleware/managerequest";
import {
  Button,
  Table,
  Modal,
  notification,
  Select,
  Tooltip,
  Input,
  Space,
} from "antd";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import { setAttendance } from "../../../store/Reducer";
import styles from "./styles/attendance.module.css";
import Text from "antd/es/typography/Text";
import {
  SearchOutlined,
  RedoOutlined,
  CloseOutlined,
  SwapOutlined,
  LoadingOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const Attendance_Manager = ({ gid, open, handlmodal, user }) => {
  const [api, contextHolder] = notification.useNotification();

  const dispatch = useDispatch();
  const [filteredInfo, setFilteredInfo] = useState({
    percentage: ["percentage"],
  });
  const [Trainer, setTrainer] = useState({ TrainerName: "", TrainerEmail: "" });
  const { Attendance } = useSelector((state) => state.Data);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(false);
  const searchInput = useRef(null);
  const [filtered, setFiltered] = useState({ startdate: "", enddate: "" });
  const [hidden, setHidden] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [selectedField, setSelectedField] = useState([]);
  const [selectedColumn, setSelectedColumns] = useState(null);
  const [newStatus, setNewStatus] = useState(selectedField?.details?.Status);

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };
  const key = "updatable";
  const openNotification = useCallback(
    (placement, type, message, icon) => {
      api[type]({
        key,
        description: message,
        placement,
        icon,
      });
    },
    [api]
  );

  const fetcher = useCallback(() => {
    setLoading(true);
    getrequest(`/fetchattendance/${gid}?type=sessions`)
      .then((res) => {
        res.data?.Attendance.length > 0 &&
          dispatch(setAttendance(res.data.Attendance));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [gid, dispatch]);

  useEffect(() => {
    fetcher();
  }, [fetcher]);

  // Add Trainer
  const AddTrainer = () => {
    postrequest(`/addtrainer/${gid}`, Trainer)
      .then((res) => {
        openNotification("topRight", "info", res.data);
        handlmodal();
        fetcher();
        setTrainer({ TrainerEmail: "", TrainerName: "" });
      })
      .catch((err) => console.log(err));
  };

  // Handle Search
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  // Handle Reset Search
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  // Search User
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
          width: "300px",
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <div className="d-flex justify-content-end mt-1 mb-1 mx-2">
          <CloseOutlined
            style={{ fontSize: "20px", cursor: "pointer" }}
            onClick={() => close()}
            title="Close"
          />
        </div>
        <Input
          ref={searchInput}
          placeholder={`Search by Name`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space className="d-flex justify-content-end">
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 50,
            }}
            title="Search"
            variant="outline-primary"
          />

          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({
                closeDropdown: true,
              });
            }}
            size="small"
            style={{
              width: 50,
            }}
            title="Reset"
            variant="outline-primary"
            icon={<RedoOutlined />}
          />
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const ConvertToNormal = (val) => {
    if (val) {
      const date = new Date(val);
      // Extract the day, month, and year components
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      const formattedDate = `${day}/${month}/${year}`;
      return formattedDate;
    } else {
      return <label>Absent</label>;
    }
  };

  const ConvertToNormalWithTime = (val) => {
    if (val) {
      const date = new Date(val);
      // Extract the day, month, and year components
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");

      const formattedDate = `${day}/${month}/${year},${hours}:${minutes}`;
      return formattedDate;
    } else {
      return "Not Added";
    }
  };

  // Custom comparator functions for sorting
  const Sorting = (a, b) => {
    if (a === "Present" && b === "Absent") return -1;
    if (a === "Absent" && b === "Present") return 1;
    return 0;
  };

  const HandleRightClick = (e) => {
    e.preventDefault();
    setIsVisible(true);
    setPosition({ x: e.clientX, y: e.clientY });
  };

  const HandleDeleteAttendance = () => {
    let confirm = window.confirm("Are you want to Delete?");
    if (confirm) {
      openNotification(
        "topRight",
        "info",
        "Deleting Attendance",
        <LoadingOutlined />
      );
      putrequest(`/deleteattendance/${gid}/${user?.Email}`, selectedColumn)
        .then((res) => {
          openNotification("topRight", "success", "Deleted Successfully");
          fetcher();
        })
        .catch((err) => {
          openNotification(
            "topRight",
            "error",
            err?.response?.data ?? "Error while deleting Attendance"
          );
        });
    }
    setIsVisible(false);
    setPosition({ x: 0, y: 0 });
  };

  // Attendance Columns
  const GetRestColumns = () => {
    let attendance = [];
    if (Attendance.length > 0 && Attendance[0].Attendance.length > 0) {
      let newarr = [...Attendance[0].Attendance];
      const parseDate = (dateString) => {
        const [date, time] = dateString.split(", ");
        const [month, day, year] = date.split("/");
        const [hours, minutes, seconds] = time.split(":");
        const [seco] = seconds.split(" ");
        return new Date("20" + year, month - 1, day, hours, minutes, seco);
      };

      // Sort sessions by Session_Start date
      newarr
        ?.sort((a, b) => {
          const dateA = parseDate(a.Session_Start);
          const dateB = parseDate(b.Session_Start);
          return dateA - dateB;
        })
        .reverse();

      let filtered1 = newarr.filter((item) => {
        if (filtered?.startdate && filtered?.enddate) {
          return (
            new Date(item.Session_Start) >= new Date(filtered.startdate) &&
            new Date(item.Session_Start) <= new Date(filtered.enddate)
          );
        } else if (filtered?.startdate) {
          return new Date(item.Session_Start) >= new Date(filtered.startdate);
        } else if (filtered?.enddate) {
          return new Date(item.Session_Start) <= new Date(filtered.enddate);
        } else {
          return true; // If both startdate and enddate are empty, include all items
        }
      });

      attendance = filtered1.map((item, ind) => {
        return {
          title: () => {
            return (
              <>
                {isVisible && (
                  <div
                    style={{
                      top: position.y,
                      left: position.x + 50,
                    }}
                    className={styles.rightMenu}
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      onMouseLeave={() => {
                        setIsVisible(false);
                        setPosition({ x: 0, y: 0 });
                      }}
                      onClick={() => HandleDeleteAttendance(item)}
                    >
                      <DeleteOutlined style={{ cursor: "pointer" }} />{" "}
                      <label className="mx-2" style={{ cursor: "pointer" }}>
                        Delete
                      </label>
                    </div>
                  </div>
                )}
                <Text
                  ellipsis={{ tooltip: item.Title }}
                  style={{ cursor: "pointer" }}
                  onContextMenu={(e) => {
                    HandleRightClick(e);
                    setSelectedColumns(item);
                  }}
                  title="right click here to delete"
                >
                  {item.Title}
                </Text>
              </>
            );
          },
          width: 250,
          responsive: ["md", "lg"],

          children: [
            {
              title: () => {
                return (
                  <Tooltip
                    title={() => {
                      return (
                        <>
                          <div className="d-flex">
                            <label className="w-50">Start at </label>
                            <label className="w-50 mx-1">
                              <i>
                                {ConvertToNormalWithTime(item?.Session_Start)}
                              </i>
                            </label>
                          </div>
                          <div className="d-flex">
                            <label className="w-50">End at </label>
                            <label className="w-50 mx-1">
                              <i>
                                {ConvertToNormalWithTime(item?.Session_End)}
                              </i>
                            </label>
                          </div>
                          <div className="d-flex">
                            <label className="w-50">Duration </label>
                            <label className="w-50 mx-1">
                              <i>{item?.Session_Duration}</i>
                            </label>
                          </div>
                        </>
                      );
                    }}
                  >
                    <label style={{ cursor: "pointer" }}>
                      {ConvertToNormal(item?.Session_Start.split(",")[0])}
                    </label>
                  </Tooltip>
                );
              },
              align: "center",
              sorter: (a, b) => {
                let Index = a?.Attendance?.findIndex(
                  (val) => item.Title === val.Title
                );
                if (Index !== -1) {
                  return Sorting(
                    a?.Attendance[Index].Status,
                    b?.Attendance[Index].Status
                  );
                }
              },
              width: 120,
              render: (_, record) => {
                let Index = record?.Attendance?.findIndex(
                  (val) =>
                    item.Title === val.Title &&
                    item.Session_Start === val.Session_Start
                );
                return (
                  <center>
                    <Tooltip
                      title={
                        record.Attendance[Index]?.Duration !== 0
                          ? () => {
                              return (
                                <>
                                  <div className="d-flex">
                                    <label className="w-50">Joined </label>
                                    <label className="w-50 mx-1">
                                      <i>
                                        {ConvertToNormalWithTime(
                                          record.Attendance[Index]?.First_Join
                                        ) ?? "Absent"}
                                      </i>
                                    </label>
                                  </div>
                                  <div className="d-flex">
                                    <label className="w-50">Left at </label>
                                    <label className="w-50 mx-1">
                                      <i>
                                        {ConvertToNormalWithTime(
                                          record.Attendance[Index]?.Last_Leave
                                        ) ?? "Absent"}
                                      </i>
                                    </label>
                                  </div>
                                  <div className="d-flex">
                                    <label className="w-50">Duration </label>
                                    <label className="w-50 mx-1">
                                      <i>
                                        {record.Attendance[Index]?.Duration ??
                                          "Absent"}
                                      </i>
                                    </label>
                                  </div>
                                </>
                              );
                            }
                          : null
                      }
                    >
                      <label
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            record.Attendance[Index]?.Status === "Present"
                              ? "#5f9c5f"
                              : record.Attendance[Index]?.Status === "Absent"
                              ? "#f56c6c"
                              : "gray",
                          width: "100%",
                        }}
                        onClick={() => {
                          setSelectedField({
                            user: { name: record.Name, email: record.Email },
                            details: record.Attendance[Index],
                          });
                          setNewStatus(record.Attendance[Index]?.Status);
                          setOpenDetails(true);
                        }}
                        title="Click to Edit"
                      >
                        {record.Attendance[Index]?.Status ?? "Not Added"}
                      </label>
                    </Tooltip>
                  </center>
                );
              },
            },
          ],
        };
      });
    }

    return attendance ?? [];
  };

  // Helper functions
  const findEarliestSession = (sessions) => {
    return (
      sessions.reduce(
        (earliest, current) =>
          new Date(earliest.Session_Start) < new Date(current.Session_Start)
            ? earliest
            : current,
        sessions[0]
      ) ?? null
    );
  };

  const findLatestSession = (sessions) => {
    return (
      sessions.reduce(
        (latest, current) =>
          new Date(latest.Session_Start) > new Date(current.Session_Start)
            ? latest
            : current,
        sessions[0]
      ) ?? null
    );
  };

  const getRelativeDate = (days) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  };

  const isDateInRange = (dateString, startDate, endDate) => {
    const sessionDate = new Date(dateString);
    return sessionDate >= startDate && sessionDate <= endDate;
  };

  const getTotalSessionsInRange = (sessions, startDate, endDate) => {
    return sessions.filter((session) =>
      isDateInRange(session.Session_Start, startDate, endDate)
    ).length;
  };

  // Filter the data
  const FilterRender = (record) => {
    if (Attendance.length === 0) return null;

    const TotalSessions = record.Attendance.filter(
      (item) => item.Status === "Present" || item.Status === "Absent"
    );
    const PresentSessions = TotalSessions.filter(
      (item) => item.Status === "Present"
    );
    const totalSessionsLength = TotalSessions.length;

    switch (filteredInfo.percentage[0]) {
      case "total_sessions": //Render Total Sessions
        return <label>{totalSessionsLength}</label>;

      case "present_session": //Show how many sessions student is present out of total sessions
        return PresentSessions.length + "/" + totalSessionsLength;

      case "percentage": //Percentage of present and total sessions
        return totalSessionsLength !== 0
          ? Math.round(
              (PresentSessions.length / Number(totalSessionsLength)) * 100
            )
          : 0;

      case "first_present": //When student joined first session
        const earliestSession = findEarliestSession(PresentSessions);
        return ConvertToNormal(earliestSession?.Session_Start);

      case "last_present": //when student joined last session
        const latestSession = findLatestSession(PresentSessions);
        return ConvertToNormal(latestSession?.Session_Start);

      case "last_week":
      case "last_month": //for last week and last month
        const startDate =
          filteredInfo.percentage[0] === "last_week"
            ? getRelativeDate(-7)
            : getRelativeDate(-28);
        const endDate = new Date();

        return (
          PresentSessions.filter((session) =>
            isDateInRange(session.Session_Start, startDate, endDate)
          ).length +
          "/" +
          getTotalSessionsInRange(TotalSessions, startDate, endDate)
        );

      default:
        return null;
    }
  };

  const options = [
    {
      label: "Total Sessions",
      value: "total_sessions",
    },
    {
      label: "Present Sessions",
      value: "present_session",
    },
    {
      label: "Percentage",
      value: "percentage",
    },

    {
      label: "First Present",
      value: "first_present",
    },
    {
      label: "Last Present",
      value: "last_present",
    },
    {
      label: "Last Week",
      value: "last_week",
    },
    {
      label: "Last Month",
      value: "last_month",
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "name",
      width: 120,
      fixed: "left",
      align: "center",
      filteredValue: filteredInfo?.name || null,
      ...getColumnSearchProps("Name"),
      // responsive: ["md"],
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "email",
      align: "center",
      width: 150,
      fixed: "left",
      // responsive: ["md", "lg"]
    },
    {
      title: () => {
        return (
          <div>
            {options.find((item) => item.value === filteredInfo.percentage[0])
              ?.label ?? "Title"}
          </div>
        );
      },

      dataIndex: filteredInfo?.percentage ?? "filter",
      key: "percentage",
      align: "center",
      width: 150,
      fixed: "left",
      // responsive: ["md", "lg"],
      filteredValue: filteredInfo?.percentage || null,
      sorter: [
        "present_session",
        "last_week",
        "last_month",
        "percentage",
      ].includes(filteredInfo?.percentage[0])
        ? (a, b) => {
            let na = FilterRender(a);
            let nb = FilterRender(b);
            if (filteredInfo?.percentage[0] === "percentage") {
              if (na > nb) return 1;
              else if (na < nb) return -1;
              else return 0;
            } else if (
              ["present_session", "last_week", "last_month"].includes(
                filteredInfo?.percentage[0]
              )
            ) {
              let splitedvalue = na.split("/")[0];
              let splitedtwo = nb.split("/")[0];
              if (Number(splitedvalue) > Number(splitedtwo)) return 1;
              else if (Number(splitedvalue) < Number(splitedtwo)) return -1;
              else return 0;
            }
          }
        : null,
      render: (value, record) => {
        return FilterRender(record);
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Select
            mode="single"
            style={{ width: 200, marginBottom: 8, display: "block" }}
            value={selectedKeys[0]}
            defaultValue={"percentage"}
            onChange={(value) => setSelectedKeys(value ? [value] : [])}
            options={options}
            onSelect={() => {
              confirm({
                closeDropdown: true,
              });
            }}
          />
        </div>
      ),
    },
    ...GetRestColumns(),
    {
      title: "Status",
      dataIndex: "Status",
      key: "status",
      align: "center",
      width: 150,
      fixed: "right",
      hidden: !hidden,
      filters: (Attendance || [])
        .map((item) => ({
          text: item?.Status ?? "No Data",
          value: item?.Status ?? "No Data",
        }))
        .filter(
          (obj, index, self) =>
            index ===
            self.findIndex(
              (o) => o.label === obj.label && o.value === obj.value
            )
        ),
      onFilter: (value, record) => {
        return record.Status.indexOf(value) === 0;
      },
      filteredValue: filteredInfo?.status || null,
    },
  ];

  const HandleAttendanceEdit = () => {
    setLoading(true);
    openNotification(
      "topRight",
      "info",
      "Changing Attendance Status",
      <LoadingOutlined />
    );
    putrequest(`/changeattendance/${gid}/${user?.Email}`, {
      selectedField,
      newStatus,
    })
      .then((res) => {
        fetcher();
        openNotification("topRight", "success", "Changed Successfully");
        setOpenDetails(false);
        setSelectedField(null);
        setNewStatus("");
      })
      .catch((err) => {
        setLoading(false);
        openNotification("topRight", "error", "Error while changing Status");
        setOpenDetails(false);
        setSelectedField(null);
        setNewStatus("");
      });
  };

  return (
    <>
      <div>
        <div className={styles.firstrow}>
          <span className={styles.showstatus}>
            <input
              className={styles.statusinput}
              id="checkstatus"
              onChange={(e) => {
                setHidden(e.target.checked);
              }}
              type="checkbox"
            />
            <label className={styles.statuslabel} htmlFor="checkstatus">
              Show Status
            </label>
          </span>

          <span className={`${styles.statuslabel} mx-3`}>
            <label>Filter : </label>
            <input
              type="date"
              max={filtered.enddate}
              className={styles.datepicker}
              onChange={(e) =>
                setFiltered((val) => ({ ...val, startdate: e.target.value }))
              }
              value={filtered.startdate}
            />
            <label className="mx-2">
              <SwapOutlined />
            </label>
            <input
              type="date"
              min={filtered.startdate}
              className={styles.datepicker}
              onChange={(e) =>
                setFiltered((val) => ({ ...val, enddate: e.target.value }))
              }
              value={filtered.enddate}
            />
            <label
              className="mx-2"
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => setFiltered({ startdate: "", enddate: "" })}
            >
              <u>Clear filter</u>
            </label>
          </span>
          {!loading ? (
            <RedoOutlined
              title="Refresh"
              style={{ cursor: "pointer", fontSize: "18px" }}
              onClick={() => fetcher()}
            />
          ) : (
            <LoadingOutlined />
          )}
        </div>
        <Table
          bordered
          size="small"
          columns={columns}
          dataSource={Attendance}
          loading={loading}
          onChange={handleChange}
          scroll={{
            x: "calc(600px + 50%)",
            y: "58vh",
          }}
        />
      </div>
      {contextHolder}
      {/* Modals */}
      {/* Add Trainer */}
      <Modal
        open={open}
        centered
        title="Add Trainer"
        closable={false}
        onCancel={() => handlmodal()}
        onOk={() => AddTrainer()}
      >
        <div>
          <span className={styles.titles}>Trainer Name</span>
          <br />
          <input
            value={Trainer.TrainerName}
            onChange={(e) =>
              setTrainer((val) => ({ ...val, TrainerName: e.target.value }))
            }
            type={"text"}
            className={styles.trainer_inputs}
            placeholder="Trainer Name"
          />
          <br />
          <br />
          <span className={styles.titles}>Trainer Email</span>
          <br />
          <input
            value={Trainer.TrainerEmail}
            onChange={(e) =>
              setTrainer((val) => ({ ...val, TrainerEmail: e.target.value }))
            }
            type={"email"}
            className={styles.trainer_inputs}
            placeholder="Trainer Email"
          />
        </div>
      </Modal>

      {/* Details view Modal */}

      <Modal
        open={openDetails}
        title={selectedField?.user?.name}
        onCancel={() => {
          setOpenDetails(false);
          setSelectedField(null);
          setNewStatus("");
        }}
        okText={selectedField?.details?.Status !== newStatus ? "Update" : "Ok"}
        okButtonProps={{
          disabled: loading,
          loading: loading,
        }}
        onOk={() => {
          if (selectedField?.details?.Status !== newStatus) {
            HandleAttendanceEdit();
          } else {
            setOpenDetails(false);
            setSelectedField(null);
            setNewStatus("");
          }
        }}
      >
        <div className="d-flex flex-column">
          <div className="d-flex ">
            <span className="w-25">Session Date </span>
            <span>
              {ConvertToNormalWithTime(selectedField?.details?.Session_Start)}
            </span>
          </div>
          <div className="d-flex ">
            <span className="w-25">Session Duration </span>
            <span>{selectedField?.details?.Session_Duration}</span>
          </div>
          <div className="d-flex ">
            <span className="w-25">Session Duration </span>
            <span>{selectedField?.details?.Duration}</span>
          </div>
          <div className="d-flex ">
            <span className="w-25">Joined at </span>
            <span>
              {ConvertToNormalWithTime(selectedField?.details?.First_Join)}
            </span>
          </div>
          <div className="d-flex ">
            <span className="w-25">Left at </span>
            <span>
              {ConvertToNormalWithTime(selectedField?.details?.Last_Leave)}
            </span>
          </div>
          <div className="d-flex ">
            <span className="w-25">Status </span>
            {/* <span>{selecctedField?.details?.Status}</span> */}

            <Select
              value={newStatus}
              onChange={(e) => {
                setNewStatus(e);
              }}
              style={{ width: "150px" }}
              options={[
                {
                  label: "Not Added",
                  value: "Not Added",
                },
                {
                  label: "Present",
                  value: "Present",
                },
                {
                  label: "Absent",
                  value: "Absent",
                },
                {
                  label: "Shifted",
                  value: "Shifted",
                },
                {
                  label: "Placement",
                  value: "Placement",
                },
                {
                  label: "Collection",
                  value: "Collection",
                },
                {
                  label: "Dropped",
                  value: "Dropped",
                },
                {
                  label: "Paid Termination",
                  value: "Paid Termination",
                },
              ]}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Attendance_Manager;
