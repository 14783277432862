import styles from "./CreateUser.module.css";
import Carousel from "react-bootstrap/Carousel";
import { Button, Input, Modal } from "antd";
import { useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Overlay from "react-bootstrap/Overlay";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import data from "../../../utils/data.json";
import { EyeInvisibleFilled, LoadingOutlined } from "@ant-design/icons";
import assessments1 from "../../../icons/assessments1.jpg";
import questionhuge from "../../../icons/image 5.svg";
import fullname from "../../../icons/mingcute_user-4-fill.svg";
import JustDigikullLogo from "../../../icons/JUSTDigikull-Logo-7000x7000 1.svg";
import email from "../../../icons/clarity_email-solid.svg";
import password from "../../../icons/mdi_password.svg";
import eye from "../../../icons/ion_eye.svg";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import { postrequest } from "../../Middleware/managerequest";
import { setIslogin, setAdmin, setuser } from "../../../store/Reducer";

const CreateUser = () => {
  const [index, setIndex] = useState(0);
  const [showpass, setShowpass] = useState(false);
  const [issendotp, setIssendotp] = useState(false);
  const [Temp, setTempData] = useState({ email: "abc@xyz.com" });
  const [IsLoading, setIsLoding] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [isotpentered, setIsotpentered] = useState(false);
  const [userdata, setUserdata] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
  });
  const [isuserreg, setIsuserreg] = useState(false);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(-1);
  const target = useRef();
  const target1 = useRef();
  const target2 = useRef();
  const target3 = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  //eslint-disable-next-line
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  // submit
  const handleSubmit = () => {
    const domainRegex = /@(.+)/;
    setIsLoding(false);
    const domainMatch = userdata.email.toLowerCase().match(domainRegex);
    if (userdata.name === "") {
      setShow(0);
    } else if (userdata.email === "") {
      setShow(1);
    } else if (userdata.mobile === "" && userdata.mobile.length !== 12) {
      setShow(2);
    } else if (userdata.password === "" && passwordError !== "") {
      setShow(3);
    } else if (domainMatch[1] === "digikull.com") {
      setOpen(true);
      return;
    } else {
      setIsLoding(true);
      let obj = {
        name: userdata.name,
        email: userdata.email.toLowerCase(),
        password: userdata.password,
        mobile: "+" + userdata.mobile,
        subdomain: state?.client_id ?? "main",
      };

      postrequest(`/home/SignUp`, obj)
        .then((res) => {
          setIsLoding(false);
          if (res.status === 200) {
            // dispatch(setTemp(obj));
            setTempData(obj);
            setIssendotp(true);
            // if(state?.id!==undefined){
            //   navigate("/userverification",{state:{id:state?.id,client_id:state?.client_id,external:state?.external}});
            // }
            // else{
            //   navigate("/userverification")
            // }
          } else if (res.status === 201) {
            setIsLoding(false);
            toast.error("Error while Sending OTP", {
              position: "top-center",
              autoClose: 1500,
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setShow(1);
            setIsLoding(false);
            setIsuserreg(true);
          } else {
            setIsLoding(false);
            toast.error("Server Not Responding", {
              position: "top-center",
              autoClose: 1500,
            });
          }
        });
    }
  };

  const validatePassword = (value) => {
    const errors = [];

    if (value.length < 8) {
      errors.push("Password must be at least 8 characters long.");
    }

    if (!/[A-Z]/.test(value)) {
      errors.push("Password must contain at least one uppercase letter.");
    }

    if (!/\d/.test(value)) {
      errors.push("Password must contain at least one number.");
    }

    if (!/[@#$%^&+=]/.test(value)) {
      errors.push("Password must contain at least one special character.");
    }

    if (errors.length > 0 && value.length !== 0) {
      setPasswordError(errors);
    } else {
      setPasswordError("");
    }
  };

  const getColorForIndex = (ind) => {
    if (index === ind) {
      return "#08213E";
    } else {
      return "#CEE8FF";
    }
  };

  const resend = () => {
    let obj = {
      email: Temp.email,
      subdomain: Temp.subdomain,
    };
    postrequest(`/home/userverification`, obj).then((res) => {
      if (res.status === 201) {
        toast.success("OTP send successfully", {
          position: "top-center",
          autoClose: 1500,
        });
      }
    });
  };

  const Submit = (OTP) => {
    console.log(OTP);
    let obj = {
      ...Temp,
      otp: OTP,
    };
    setIsotpentered(true);
    postrequest(`/home/userverification`, obj)
      .then((res) => {
        setIsotpentered(false);
        if (res.status === 201) {
          toast.error("OTP is Expired", {
            position: "top-center",
            autoClose: 1500,
          });
        } else {
          toast.success("OTP verified", {
            position: "top-center",
            autoClose: 1500,
          });
          if (state?.org) {
            navigate("/subscribed");
          } else {
            const decodetoken = jwtDecode(res.data.token);
            if (process.env.REACT_APP_ENVIRONMENT === "local") {
              Cookies.set("digikull_local", res.data.token, { expires: 1 });
            }

            let obj = {
              Email: decodetoken.Email,
              FullName: res.data.FullName ?? decodetoken.FullName,
              subdomain: decodetoken.subdomain,
            };
            dispatch(setuser(obj));
            dispatch(setAdmin(decodetoken.isAdmin));
            localStorage.setItem(
              "FullName",
              res.data.FullName ?? decodetoken.FullName
            );
            sessionStorage.setItem("Email", decodetoken.Email);
            sessionStorage.setItem("secret", decodetoken.isAdmin);
            sessionStorage.setItem("subdomain", decodetoken.subdomain);
            let flag = true;
            dispatch(setIslogin(flag));

            if (state?.id) {
              navigate(`/MyMock/${state?.id}`, {
                state: { external: state?.external },
              });
            } else {
              navigate("/user");
            }
          }
        }
      })
      .catch((err) => {
        setIsotpentered(false);
        if (err?.response?.status === 400) {
          toast.error("Please check OTP", {
            position: "top-center",
            autoClose: 1500,
          });
        } else {
          toast.error("Server not responding", {
            position: "top-center",
            autoClose: 1500,
          });
        }
      });
  };

  return (
    <div className={styles.main}>
      {/* Verify OTP */}
      <Modal
        title="OTP Verification"
        closable={false}
        footer={null}
        maskClosable={false}
        open={issendotp}
      >
        {isotpentered ? (
          <center>
            <LoadingOutlined style={{ fontSize: "25px" }} />
            <br />
            <label>
              We are verifying your OTP and signing you in. Please wait.
            </label>
          </center>
        ) : (
          <center>
            <label>
              We send One-Time-Password to <b>{Temp?.email ?? ""}</b>
            </label>
            <Input.OTP
              disabled={isotpentered}
              length={4}
              onChange={(e) => Submit(e)}
            />
            <br />
            <label style={{ cursor: "pointer" }} onClick={() => resend()}>
              <u>Resend OTP</u>
            </label>
          </center>
        )}
      </Modal>
      <Modal
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
        title={
          <>
            <img src={JustDigikullLogo} width={25} alt="error" /> Digikull Login
          </>
        }
      >
        <label>
          For Digikull user Kindly login with{" "}
          <u
            onClick={() => {
              if (state?.id !== undefined) {
                let url = state?.external
                  ? encodeURIComponent(
                      `id=${state.id}&client_id=${state.client_id}&ref=external`
                    )
                  : encodeURIComponent(
                      `id=${state.id}&client_id=${state.client_id}`
                    );
                navigate(`/?${url}`, {
                  state: { id: state.id, client_id: state?.client_id },
                });
              } else {
                navigate("/");
              }
            }}
            style={{ cursor: "pointer", color: "blue" }}
          >
            Digikull Login
          </u>{" "}
          Option
        </label>
      </Modal>
      <ToastContainer />
      <div className={styles.container}>
        <div className={styles.leftSidebar}>
          <div className={styles.navbar}>
            <img src={JustDigikullLogo} alt="error" />
            <span>Digikull</span>
          </div>

          <div className={styles.rightSidebarContext}>
            <label className={styles.label1}>Create Your Account </label>
            <br />
            <label className={styles.label2}>
              And start testing your Skills for company <br /> standards
            </label>

            <div className={styles.form}>
              {/* Full Name */}
              <div>
                <label>Full Name</label>
                <div className={styles.formInput} ref={target}>
                  <img
                    src={fullname}
                    alt="error"
                    placeholder="Write Your Name"
                  />
                  <input
                    type="text"
                    placeholder="What we call you ?"
                    onChange={(e) => {
                      setUserdata({ ...userdata, name: e.target.value });
                      setShow(-1);
                    }}
                    value={userdata.name}
                  />
                </div>
                <Overlay
                  target={target.current}
                  show={show === 0}
                  placement="right"
                >
                  {({
                    placement: _placement,
                    arrowProps: _arrowProps,
                    show: _show,
                    popper: _popper,
                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                    ...props
                  }) => (
                    <div
                      {...props}
                      style={{
                        position: "absolute",
                        backgroundColor: "rgba(255, 100, 100, 0.85)",
                        padding: "2px 10px",
                        color: "white",
                        borderRadius: 3,
                        ...props.style,
                      }}
                    >
                      Please enter full name
                    </div>
                  )}
                </Overlay>
              </div>
              {/* Email */}
              <div>
                <label>Email</label>
                <div className={styles.formInput} ref={target1}>
                  <img src={email} alt="error" placeholder="Write Your Name" />
                  <input
                    type="email"
                    placeholder="Email"
                    onChange={(e) => {
                      setUserdata({ ...userdata, email: e.target.value });
                      setShow(-1);
                    }}
                    value={userdata.email}
                  />
                </div>
                <Overlay
                  target={target1.current}
                  show={show === 1}
                  placement="right"
                >
                  {({
                    placement: _placement,
                    arrowProps: _arrowProps,
                    show: _show,
                    popper: _popper,
                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                    ...props
                  }) => (
                    <div
                      {...props}
                      style={{
                        position: "absolute",
                        backgroundColor: "rgba(255, 100, 100, 0.85)",
                        padding: "2px 10px",
                        color: "white",
                        borderRadius: 3,
                        ...props.style,
                      }}
                    >
                      {isuserreg
                        ? "User Already Present"
                        : "Please enter email."}
                    </div>
                  )}
                </Overlay>
              </div>
              {/* MObile Number */}
              <div className={styles.phoneSection} ref={target2}>
                <label>Phone Number</label>
                <br />
                <PhoneInput
                  country={"in"}
                  enableSearch={true}
                  inputStyle={{
                    width: "100%",
                    marginLeft: "0px",
                    height: "40px",
                    border: "1px solid #CEE8FF",
                    borderRadius: "10px",
                  }}
                  searchClass={styles.search}
                  onChange={(e) => {
                    setUserdata({ ...userdata, mobile: e });
                    setShow(-1);
                  }}
                  value={userdata.mobile}
                />
              </div>
              <Overlay
                target={target2.current}
                show={show === 2}
                placement="right"
              >
                {({
                  placement: _placement,
                  arrowProps: _arrowProps,
                  show: _show,
                  popper: _popper,
                  hasDoneInitialMeasure: _hasDoneInitialMeasure,
                  ...props
                }) => (
                  <div
                    {...props}
                    style={{
                      position: "absolute",
                      backgroundColor: "rgba(255, 100, 100, 0.85)",
                      padding: "2px 10px",
                      color: "white",
                      borderRadius: 3,
                      ...props.style,
                    }}
                  >
                    Please enter valid mobile number.
                  </div>
                )}
              </Overlay>

              {/* Password */}
              <div>
                <label>Password</label>
                <div className={styles.passwordInp} ref={target3}>
                  <div className={styles.password}>
                    <img
                      src={password}
                      alt="error"
                      placeholder="What we call you?"
                    />
                    <input
                      type={!showpass ? "password" : "text"}
                      placeholder="Password"
                      onChange={(e) => {
                        validatePassword(e.target.value);

                        setUserdata({ ...userdata, password: e.target.value });
                        setShow(-1);
                      }}
                      value={userdata.password}
                    />
                  </div>
                  <div className={styles.eye}>
                    {!showpass ? (
                      <img
                        src={eye}
                        alt="error"
                        style={{ width: "16px", cursor: "pointer" }}
                        onClick={() => setShowpass(true)}
                      />
                    ) : (
                      <EyeInvisibleFilled
                        onClick={() => setShowpass(false)}
                        className={styles.hidepass}
                      />
                    )}
                  </div>
                </div>
                <Overlay
                  target={target3.current}
                  show={show === 3}
                  placement="right"
                >
                  {({
                    placement: _placement,
                    arrowProps: _arrowProps,
                    show: _show,
                    popper: _popper,
                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                    ...props
                  }) => (
                    <div
                      {...props}
                      style={{
                        position: "absolute",
                        backgroundColor: "rgba(255, 100, 100, 0.85)",
                        padding: "2px 10px",
                        color: "white",
                        borderRadius: 3,
                        ...props.style,
                      }}
                    >
                      Please enter valid password.
                    </div>
                  )}
                </Overlay>
                {passwordError && (
                  <div className={styles.password_validation}>
                    <ul>
                      {" "}
                      {passwordError?.map((item, index) => {
                        return <li key={index}>{item}</li>;
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className={styles.btnDiv}>
              <Button
                type="primary"
                loading={IsLoading}
                iconPosition={"center"}
                className={styles.btn}
                onClick={() => handleSubmit()}
              >
                Create Account
              </Button>
              <label
                onClick={() => {
                  if (state?.id !== undefined) {
                    let url = state?.external
                      ? encodeURIComponent(
                          `id=${state.id}&client_id=${state.client_id}&ref=external`
                        )
                      : encodeURIComponent(
                          `id=${state.id}&client_id=${state.client_id}`
                        );
                    navigate(`/?${url}`, {
                      state: { id: state.id, client_id: state?.client_id },
                    });
                  } else {
                    navigate("/");
                  }
                }}
                className={styles.alreadyaccount}
              >
                I already have a Account.
              </label>
            </div>
          </div>
        </div>

        <div className={styles.rightSidebar}>
          <div className={styles.rightImage}>
            <Carousel
              data-bs-theme="dark"
              indicators={false}
              controls={false}
              interval={1000}
              activeIndex={index}
              onSelect={handleSelect}
            >
              <Carousel.Item>
                <div className={styles.images}>
                  <img
                    className={styles.carousel}
                    src={questionhuge}
                    alt="Third slide"
                    height={130}
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className={styles.images}>
                  <img
                    className={styles.carousel}
                    src={
                      "https://c4.wallpaperflare.com/wallpaper/306/656/57/question-question-mark-red-black-wallpaper-preview.jpg"
                    }
                    alt="Third slide"
                    height={130}
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className={styles.images}>
                  <img
                    className={styles.carousel}
                    src={assessments1}
                    alt="Third slide"
                    height={130}
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>

          <center>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "50%",
                marginTop: "10px",
              }}
            >
              {data?.map((item, ind) => {
                return (
                  <div
                    style={{
                      flex: 1,
                      height: "3px",
                      background: getColorForIndex(ind),
                    }}
                    key={ind}
                  ></div>
                );
              })}
            </div>
          </center>

          <div className={styles.rightSidebarTxt}>
            <label className={styles.first}>{data[index].heading}</label>
            <br />
            <label className={styles.descriptiontext}>
              <small>{data[index].Description}</small>
            </label>
          </div>

          <div className={styles.Dot}>
            <div
              className={index === 0 ? styles.dot_sub1 : styles.dot_sub2}
            ></div>
            <div
              className={index === 1 ? styles.dot_sub1 : styles.dot_sub2}
            ></div>
            <div
              className={index === 2 ? styles.dot_sub1 : styles.dot_sub2}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateUser;
