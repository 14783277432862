import styles from "./ForgotPassword.module.css";
import Carousel from "react-bootstrap/Carousel";
import { useCallback, useEffect, useState } from "react";
import data from "../../../utils/data.json";
import JustDigikullLogo from "../../../icons/JUSTDigikull-Logo-7000x7000 1.svg";

import "react-toastify/dist/ReactToastify.css";
import assessments1 from "../../../icons/assessments1.jpg";
import questionhuge from "../../../icons/image 5.svg";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { reset } from "../../../store/Reducer";
import { Input, InputNumber, Spin } from "antd";
import { postrequest } from "../../Middleware/managerequest";
import { LockOutlined, MessageOutlined, MailOutlined,ArrowLeftOutlined } from "@ant-design/icons";
const ForgotPassword = () => {
  const [index, setIndex] = useState(0);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isenterotp, setIsenterotp] = useState(false);
  const [sendrequest, setSendrequest] = useState(false);
  const [validotp,setValidotp]=useState(false)
  const [passwordError, setPasswordError] = useState("");
  const [isSubmit,setSubmit]=useState(false)
  const {resetpass} =useSelector(state=>state.Data)
  const [userdata, setUserdata] = useState({
    email: "",
    password: "",
    otp: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [id, setId] = useState(null);
  const [flag, setFlag] = useState(false);

  // Send OTP Request
  const handleSubmit = () => {
    let obj = {
      email: userdata.email,
    };
    setSendrequest(true);
    postrequest(`/home/forget`, obj)
      .then((res) => {
        setSendrequest(false);
        if (res.status === 201) {
          setFlag(true);
          setId(res.data.id);
          dispatch(reset(res.data.id));
          toast.success("OTP send SuccessFully.", {
            position: "top-center",
            autoClose: 1500,
          });
        }
      })
      .catch((err) => {
        setSendrequest(false);
        if (err.response.status === 401) {
          toast.error("Email is not Registered", {
            position: "top-center",
            autoClose: 1500,
          });
        } else {
          toast.error("Server not responding", {
            position: "top-center",
            autoClose: 1500,
          });
        }
      });
  };

  //   Resend
  const resend = () => {
    let obj = {
      id: id,
    };
    setIsenterotp(true);
    postrequest(`/home/verificationemail`, obj).then((res) => {
      if (res.status === 201) {
        setIsenterotp(false);
        toast.success("OTP send successfully", {
          position: "top-center",
          autoClose: 1500,
        });
      }
    });
  };

  //   Submit OTP
  const Submit = useCallback(() => {
    let obj = {
      id: id,
      otp: userdata.otp,
    };
    setIsenterotp(true);
    postrequest(`/home/verificationemail`, obj)
      .then((res) => {
        setIsenterotp(false);
        if (res.status === 201) {
          toast.error("OTP is Expired", {
            position: "top-center",
            autoClose: 1500,
          });
        } else {
         
          setValidotp(true)
      //     navigate("/recovery", { state: { id: state?.id } });
        }
      })
      .catch((err) => {
        setIsenterotp(false);
        if (err.response.status === 400) {
          toast.error("Please check OTP", {
            position: "top-center",
            autoClose: 1500,
          });
        } else {
          toast.error("Server not responding", {
            position: "top-center",
            autoClose: 1500,
          }); 
        }
      });
  }, [id,  userdata]);

  useEffect(() => {
    if (userdata.otp?.toString().length === 4 &&!validotp) {
      Submit();
    }
  }, [userdata.otp, Submit,validotp]);

  //     Validate Password
  const validatePassword = (value) => {
    const errors = [];

    if (value.length < 8) {
      errors.push("Password must be at least 8 characters long.");
    }

    if (!/[A-Z]/.test(value)) {
      errors.push("Password must contain at least one uppercase letter.");
    }

    if (!/\d/.test(value)) {
      errors.push("Password must contain at least one number.");
    }

    if (!/[@#$%^&+=]/.test(value)) {
      errors.push("Password must contain at least one special character.");
    }

    if (errors.length > 0 && value.length !== 0) {
      setPasswordError(errors);
    } else {
      setPasswordError("");
    }
  };


//   FInal Submit the Password
const handleFinalSubmit = (e) => {
   
       if (passwordError===""&&userdata.password!=="") {
        let obj = {
          id: resetpass,
          password: userdata.password,
        };
        setSubmit(true)
        postrequest(`/home/recovery`,obj)
          .then((res) => {
            setSubmit(false)
            if (res.status === 201) {
              toast.error("Error While Resetting Password ", {
                position: "top-center",
                autoClose: 1500,
              });
            } else {
              
              if(state.id!==undefined){
                let encoded=encodeURIComponent(`id=${state.id}&client_id=${state.client_id}${state?.external ? "&ref=external" : ""}`)
                navigate(`/?${encoded}`);

              }
              else{
                navigate("/");
              }
             
            }
          })
          .catch((err) => {
            setSubmit(false)
            toast.error("Server not responding", {
              position: "top-center",
              autoClose: 1500,
            });
          });
      } else {
        toast.error("Please check confirm password", {
          position: "top-center",
          autoClose: 1500,
        });
      }
  
   
    };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const getColorForIndex = (ind) => {
    if (index === ind) {
      return "#08213E";
    } else {
      return "#CEE8FF";
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.leftSidebar}>
          <div className={styles.navbar}>
            <img src={JustDigikullLogo} alt="error" />
            <span>Digikull</span>
          </div>
          <ToastContainer />
          <div className={styles.carouselIndicators}>
            <Carousel
              data-bs-theme="dark"
              indicators={false}
              controls={false}
              interval={1000}
              activeIndex={index}
              onSelect={handleSelect}
            >
              <Carousel.Item>
                <div className={styles.images}>
                  <img
                    className={styles.carousel}
                    src={questionhuge}
                    alt="Third slide"
                    height={130}
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className={styles.images}>
                  <img
                    className={styles.carousel}
                    src={
                      "https://c4.wallpaperflare.com/wallpaper/306/656/57/question-question-mark-red-black-wallpaper-preview.jpg"
                    }
                    alt="Third slide"
                    height={130}
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className={styles.images}>
                  <img
                    className={styles.carousel}
                    src={assessments1}
                    alt="Third slide"
                    height={130}
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>

          <center>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "50%",
                marginTop: "10px", // Adjust the width as needed
              }}
            >
              {data?.map((item, ind) => {
                return (
                  <div
                    style={{
                      flex: 1,
                      height: "3px",
                      background: getColorForIndex(ind),
                    }}
                    key={ind}
                  ></div>
                );
              })}
            </div>
          </center>

          <div>
            <div className={styles.tool}>
              <label className={styles.first}>{data[index].heading}</label>
              <br />
              <label className={styles.descriptiontext}>
                <small>{data[index].Description}</small>
              </label>
            </div>
          </div>

          <div className={styles.Dot}>
            {data?.map((item, ind) => {
              return (
                <div
                  className={index === ind ? styles.dot_sub1 : styles.dot_sub2}
                  key={ind}
                ></div>
              );
            })}
          </div>
        </div>

        <div className={styles.rightSidebar}>
          <label className={styles.heading}>Forgot Password?</label>
          <label className={styles.subtitle}>
            Don't worry. you can reset here.
          </label>

          <Input
            size="large"
            placeholder="Enter email"
            prefix={<MailOutlined />}
            className="mt-3"
            type="email"
            onChange={(e) => {
              setUserdata({ ...userdata, email: e.target.value });
              setFlag(false);
            }}
          />
          <div className="d-flex justify-content-end mt-2">
            <label></label>
            {userdata.email !== "" && !flag && (
              <button className={styles.sendbtn} onClick={() => handleSubmit()}>
                {" "}
                {!sendrequest ? "Send OTP" : <Spin spinning={sendrequest} />}
              </button>
            )}
          </div>
          {flag && (
            <div className="mt-3">
              <InputNumber
                max={9999}
                maxLength={4}
                size="large"
                placeholder="Enter OTP"
                className={styles.otpenter}
                prefix={<MessageOutlined />}
                onChange={(e) => setUserdata({ ...userdata, otp: e })}
              />
              <div className="d-flex justify-content-end align-items-center">
                {isenterotp ? (
                  <Spin spinning={isenterotp} className="mt-2" />
                ) : (
                  <label onClick={() => resend()} style={{ cursor: "pointer",fontSize:"14px" }}>
                    <u>Resend OTP.</u>
                  </label>
                )}
              </div>

            {validotp&&<><Input.Password
                placeholder="Enter New Password"
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: setPasswordVisible,
                }}
                size="large"
                prefix={<LockOutlined />}
                style={{ position: "relative" }}
                onChange={(e) => {
                  validatePassword(e.target.value);
                  setUserdata({ ...userdata, password: e.target.value });
                }}
              />
              {passwordError && (
                <div className={styles.password_validation}>
                  <ul>
                    {" "}
                    {passwordError?.map((item, index) => {
                      return <li>{item}</li>;
                    })}
                  </ul>
                </div>
              )}
              <div className="d-flex justify-content-between align-items-center mt-3">
                <label style={{cursor:"pointer"}} onClick={()=>{
                  if(state.id!==undefined){
                    let url=state?.external?encodeURIComponent(`id=${state.id}&client_id=${state.client_id}&ref=external`):encodeURIComponent(`id=${state.id}&client_id=${state.client_id}`)
                    navigate(`/?${url}`, {
                      state: { id: state.id,client_id:state?.client_id },
                    });
                      }
                      else{
                        navigate("/");
                      }
                }}><ArrowLeftOutlined /> back</label>
                <button className={styles.finalsubmit} onClick={()=>handleFinalSubmit()} disabled={userdata?.password==="" ||passwordError!=="" || isSubmit}>Submit</button>
              </div></>}

            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
