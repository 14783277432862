import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styles from "./createmock.module.css";
import {
  InfoCircleOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  HomeOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";

import {
  Select,
  Input,
  Button,
  InputNumber,
  Switch,
  message,
  Tooltip,
  Modal,
  notification,
} from "antd";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { getrequest, postrequest } from "../../Middleware/managerequest";
import DashboardSlider from "../../Navigator_Footer/DashboardSlider";
import ProfileSlider from "../../Navigator_Footer/ProfileSlider";
import LatestNavbar from "../../Navigator_Footer/LatestNavbar";
const minutesMapping = {
  Coding: {
    Easy: 600,
    Medium: 900,
    Hard: 1200,
  },
  Question: {
    Easy: 60,
    Medium: 80,
    Hard: 120,
  },
  Mcq: {
    Easy: 50,
    Medium: 70,
    Hard: 110,
  },
};
const { TextArea } = Input;

// Menu
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const CreateMock = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [TestName, setTestName] = useState("");
  const [menuKey, setMenuKey] = useState("");
  const [openDwa, setOpenDwa] = useState(false);
  const { user, clientdetails } = useSelector((state) => state.Data);
  const [mockcat, setMockcat] = useState(
    searchParams.get("add") ?? "One Time Test"
  );

  const [Dates, setDates] = useState({ startdate: "", enddate: "" });
  const [questionbank, setquestionbank] = useState({
    global: false,
    personal: false,
  });
  // Original Data
  const [QuestionData, setQuestionData] = useState({
    globaldata: [],
    personaldata: [],
  });
  // Data after filter Topics
  const [QuestionFinaldata, setQuestionFinaldata] = useState({
    globaldata: [],
    personaldata: [],
  });
  // Data after filter Tags
  const [globalfilter, setGlobalFilter] = useState([]);
  const [personalfilter, setPersonalFilter] = useState([]);
  // Original Topics and Tags data
  const [Selection, setSelection] = useState({ Topics: [], Tags: [] });

  // Selected Topics and Tags data
  const [Selected, setSelected] = useState({ Topics: [], Tags: [] });
  const [TopicswiseTags, setTopicwiseTags] = useState();
  const [loading, setLoading] = useState(false);
  const [totaltype, setTotalType] = useState({ mcq: 0, coding: 0, simple: 0 });
  const [countfinalquestion, setCountfinalquestion] = useState({
    Personal: 0,
    Global: 0,
  });
  const [Typeselection, setTypeSelection] = useState({
    Question: false,
    Coding: false,
    Mcq: false,
  });
  const [edit, setEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [Mockid, setMockId] = useState(-1);

  // Settings
  const [settings, setSettings] = useState({
    copypaste: true,
    autofeedback: false,
    TestTimer: false,
    visible: false,
  });
  const [warning, setWarning] = useState(false);
  const [Capture, setIsCapturing] = useState({ screen: true, user: true });
  const [closeontab, setCloseOnTab] = useState({ close: true, limit: 3 });
  const [Practicemode, setPracticemode] = useState(false);
  const [Introduction, setIntroduction] = useState(false);
  const [finaldata, setFinalData] = useState([]);
  const [totalselectedtheory, setTotalSelectedTheory] = useState(0);

  const [Questionandduration, setQuestionandduration] = useState({
    duration: 0,
    totalquestion: 0,
  });

  const [api, contextHolder2] = notification.useNotification();
  const openNotificationWithIcon = (type, val) => {
    api[type]({
      message: "Alert",
      description: val,
    });
  };

  // // Fetch the category
  // useEffect(() => {
  //   if (user) {
  //     getrequest(`/questions/${user?.subdomain}/?category=true`).then((res) => {
  //       setCategory((c) => ({
  //         ...c,
  //         category: ["Select Section", ...res.data],
  //       }));
  //     });
  //   }
  // }, [user]);

  const convertDateIntoNormalend = (val) => {
    // Create a Date object from the input string
    const date = new Date(val);

    // Extract the day, month, and year components
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    // Extract the hours and minutes components
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Construct the formatted date string
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formattedDate;
  };

  const convertDateIntoNormal2 = () => {
    // Create a Date object from the current date and time
    const date = new Date();

    // Extract the day, month, and year components
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    // Extract the hours and minutes components
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Construct the formatted date string
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formattedDate;
  };

  //handleQuestion Bank
  const handleQuestionBank = (e) => {
    setLoading(true);
    if (e.target.value === "global") {
      setquestionbank({ ...questionbank, global: e.target.checked });
    }
    if (e.target.value === "personal") {
      setquestionbank({ ...questionbank, personal: e.target.checked });
    }
  };

  //  Select Topics
  //Select Main Topics
  const onchangemaintopics = (e, globalq, personalq, isgloabl, ispersonl) => {
    setSelected({ ...Selected, Topics: e });
    let Globalquestion = globalq || QuestionData.globaldata;
    let Personalquestion = personalq || QuestionData.personaldata;
    let globalflag = isgloabl || questionbank.global;
    let personalflag = ispersonl || questionbank.personal;
    if (!e) return;

    setGlobalFilter([]);
    setPersonalFilter([]);
    const filterQuestions = (questions, flag) => {
      return questions.filter((item) => {
        if (item.maintopics) {
          return e.some((val) => item.maintopics.includes(val));
        }
        return false;
      });
    };

    const extractUniqueConcepts = (questions) => {
      const uniqueConcepts = new Set();
      questions.forEach((question) => {
        question.concept.forEach((concept) => {
          uniqueConcepts.add(concept);
        });
      });
      return Array.from(uniqueConcepts);
    };

    setQuestionFinaldata({ personaldata: [], globaldata: [] });

    let personalsubtopics = [];
    let globalsubtopics = [];
    let personal = [],
      global = [];
    if (personalflag) {
      personal = filterQuestions(Personalquestion, personalflag);
      personalsubtopics = extractUniqueConcepts(personal);
      setQuestionFinaldata((data) => ({ ...data, personaldata: personal }));
    }

    if (globalflag) {
      global = filterQuestions(Globalquestion, globalflag);
      globalsubtopics = extractUniqueConcepts(global);
      setQuestionFinaldata((data) => ({ ...data, globaldata: global }));
    }

    const mergedSubtopics = [...personalsubtopics, ...globalsubtopics];
    const uniqueSubtopics = Array.from(new Set(mergedSubtopics));
    let filteredArr2 = finaldata.filter((item) => e.includes(item.MainTags));
    setFinalData(filteredArr2);
    const finaldata1 = uniqueSubtopics.map((item) => ({
      label: item,
      value: item,
    }));

    if (Selected?.Tags?.length > 0) {
      filteredtypes(Selected?.Tags, global, personal, e);
    }
    setSelection((tp) => ({ ...tp, Tags: finaldata1 }));
  };

  // Fetch questions
  const fetchquestions = useCallback(
    (g, p) => {
      setGlobalFilter([]);
      setPersonalFilter([]);
      setLoading(true);
      if ((g ?? questionbank.global) || (p ?? questionbank.personal)) {
        getrequest(
          `/createmock/${user?.subdomain}/${questionbank.global}/${questionbank.personal}`
        )
          .then((res) => {
            setSelection({ ...Selection, Topics: res.data.MainTopics });
            // If both personal and global question selected
            if (res.data.global && res.data.personal) {
              setQuestionData({
                globaldata: res.data.global,
                personaldata: res.data.personal,
              });
              if (Selected?.Topics?.length > 0) {
                onchangemaintopics(
                  Selected?.Topics,
                  res.data.global,
                  res.data.personal,
                  true,
                  true
                );
              }
            }
            // If only global question want
            else if (res.data.global) {
              setQuestionData({
                globaldata: res.data.global,
                personaldata: [],
              });
              if (Selected?.Topics?.length > 0 && !res.data.personal) {
                onchangemaintopics(
                  Selected?.Topics,
                  res.data.global,
                  QuestionData.personaldata,
                  true,
                  questionbank.personal
                );
              }
            }
            // If only personal questions want
            else if (res.data.personal) {
              setQuestionData({
                globaldata: [],
                personaldata: res.data.personal,
              });
              if (Selected?.Topics?.length > 0 && !res.data.global) {
                onchangemaintopics(
                  Selected?.Topics,
                  QuestionData.globaldata,
                  res.data.personal,
                  questionbank.global,
                  true
                );
              }
            }
            // Disable loading
            setLoading(false);
          })
          .catch((err) => {
            toast.warning("There is Error", {
              position: "top-right",
              autoClose: 1500,
            });
          });
      }
    },
    //eslint-disable-next-line
    [questionbank.global, questionbank.personal]
  );

  const CheckfinalData = useCallback(
    (val) => {
      const propertiesMapping = {
        Coding: ["Easy", "Medium", "Hard"],
        Question: ["Easy", "Medium", "Hard"],
        Mcq: ["Easy", "Medium", "Hard"],
      };

      const applyFilter = (item, filters, type) => {
        filters?.forEach((filter) => {
          if (filter.Topic === item.MainTags) {
            Object.keys(propertiesMapping)?.forEach((category) => {
              propertiesMapping[category]?.forEach((difficulty) => {
                const personalProp = item[type]?.[category]?.[difficulty];
                const personalValProp = filter[category]?.[difficulty];
                if (personalValProp === 0 && personalProp > 0) {
                  item[type][category][difficulty] = 0;
                } else if (personalValProp < personalProp) {
                  item[type][category][difficulty] = personalValProp;
                }
                if (!Typeselection[category]) {
                  item[type][category][difficulty] = 0;
                }
              });
            });
          }
        });

        let qb = { Global: 0, Personal: 0 };
        let types = { Mcq: 0, Question: 0, Coding: 0 };

        Object.keys(item[type]).forEach((category) => {
          Object.keys(item[type]?.[category]).forEach((difficulty) => {
            qb[type] += item[type]?.[category]?.[difficulty];
          });
        });
        // Calculate total Question from Global and Personal
        ["Global", "Personal"].forEach((category) => {
          ["Question", "Coding", "Mcq"].forEach((type) => {
            ["Easy", "Medium", "Hard"].forEach((difficulty) => {
              types[type] += item[category]?.[type]?.[difficulty];
            });
          });
        });
        item.Mcq = types.Mcq;
        item.Coding = types.Coding;
        item.Question = types.Question;

        if (type === "Global") {
          item.globalcount = qb.Global;
        } else if (type === "Personal") {
          item.personalcount = qb.Personal;
        }
      };

      let totaltheory = 0;
      val.forEach((item) => {
        totaltheory += item.Question;

        if (personalfilter.length > 0 && globalfilter.length === 0) {
          Object.keys(propertiesMapping)?.forEach((category) => {
            propertiesMapping[category]?.forEach((difficulty) => {
              item.Global[category][difficulty] = 0;
            });
          });
        } else if (personalfilter.length === 0 && globalfilter.length > 0) {
          Object.keys(propertiesMapping)?.forEach((category) => {
            propertiesMapping[category]?.forEach((difficulty) => {
              item.Personal[category][difficulty] = 0;
            });
          });
        }
        applyFilter(item, personalfilter, "Personal");
        applyFilter(item, globalfilter, "Global");
      });

      if (Typeselection.Question) {
        setTotalSelectedTheory(totaltheory);
      } else {
        setTotalSelectedTheory(0);
      }

      setFinalData(val);
      const totalTime = calculateTotalTime(val, minutesMapping);

      setQuestionandduration({
        duration: totalTime.totalTime,
        totalquestion: totalTime.totalquestions,
      });
    },
    [personalfilter, globalfilter, Typeselection]
  );

  // On every question bank selection this useEffect call
  useEffect(() => {
    fetchquestions();
  }, [fetchquestions]);
  useEffect(() => {
    CheckfinalData(finaldata);
  }, [Typeselection, Selected?.Tags, questionbank, CheckfinalData, finaldata]);

  //Filtering Diff
  const filterDiff = async (mcq, Coding, Simple, qbank, TP) => {
    let total = { Mcq: 0, Question: 0, Coding: 0 };
    let Topics = TP ?? Selected?.Topics;
    let finalarr = Topics.map((item) => {
      let obj = {
        Topic: item,
        Question: { Easy: 0, Medium: 0, Hard: 0 },
        Mcq: { Easy: 0, Medium: 0, Hard: 0 },
        Coding: { Easy: 0, Medium: 0, Hard: 0 },
      };

      mcq.forEach((value) => {
        if (value.maintopics.includes(item)) {
          obj["Mcq"][value.level]++;

          total["Mcq"]++;
        }
      });

      Coding.forEach((value) => {
        if (value.maintopics.includes(item)) {
          obj["Coding"][value.level]++;
          total["Coding"]++;
        }
      });

      Simple.forEach((value) => {
        if (value.maintopics.includes(item)) {
          obj["Question"][value.level]++;

          total["Question"]++;
        }
      });

      return obj;
    });
    setTotalType((prevTotalType) => ({
      mcq: prevTotalType.mcq + total.Mcq,
      coding: prevTotalType.coding + total.Coding,
      simple: prevTotalType.simple + total.Question,
    }));

    // Final filtered data
    if (qbank === "personal") {
      setPersonalFilter(finalarr);
    }
    if (qbank === "global") {
      setGlobalFilter(finalarr);
    }
  };

  //Filtering Question Types {Mcq,Coding and Question}
  const filterqstntypes = async (val, type, TP) => {
    let mcq = await val.filter((item) => item.type === "Mcq");
    let Coding = await val.filter((item) => item.type === "Coding");
    let Simple = await val.filter((item) => item.type === "Question");
    filterDiff(mcq, Coding, Simple, type, TP);
  };

  //Filtering Question Tags
  const filteredtypes = (val, gbl, psnl, TP) => {
    let global = gbl ?? QuestionFinaldata.globaldata;
    let personal = psnl ?? QuestionFinaldata.personaldata;
    let obj = {};
    setCountfinalquestion({ Global: 0, Personal: 0 });
    setTotalType((prevTotalType) => ({
      mcq: 0,
      coding: 0,
      simple: 0,
    }));
    if (questionbank.global) {
      let find = global.filter((item) =>
        val.some((value) => item.concept.includes(value))
      );
      global.forEach((item) => {
        Selected.Topics?.forEach((it) => {
          if (item.maintopics.includes(it)) {
            if (!obj[it]) {
              obj[it] = new Set();
            }
            item.concept.forEach((value) => {
              if (val.includes(value)) {
                obj[it].add(value);
              }
            });
          }
        });
      });

      // Convert Set values to arrays in the object
      Object.keys(obj).forEach((key) => {
        obj[key] = Array.from(obj[key]);
      });

      filterqstntypes(find, "global", TP);
      setCountfinalquestion((prevCount) => ({
        ...prevCount,
        Global: find.length,
      }));
    }

    if (questionbank.personal) {
      let find = personal.filter((item) =>
        val.some((value) => item.concept.includes(value))
      );
      filterqstntypes(find, "personal", TP);
      setCountfinalquestion((prevCount) => ({
        ...prevCount,
        Personal: find.length,
      }));
      personal.forEach((item) => {
        Selected.Topics?.forEach((it) => {
          if (item.maintopics.includes(it)) {
            if (!obj[it]) {
              obj[it] = new Set();
            }
            item.concept.forEach((value) => {
              if (val.includes(value)) {
                obj[it].add(value);
              }
            });
          }
        });
      });

      // Convert Set values to arrays in the object
      Object.keys(obj).forEach((key) => {
        obj[key] = Array.from(obj[key]);
      });
    }
    setTopicwiseTags(obj);
  };

  //Select Tags
  const onchangetopics = (e) => {
    setSelected({ ...Selected, Tags: e });

    if (e) {
      filteredtypes(e);
    }
  };

  // Calculate Total Duration and Question
  const calculateTotalTime = (finaldata, minutesMapping) => {
    let totalTime = 0;
    let totalquestions = 0;

    finaldata.forEach((obj) => {
      ["Mcq", "Coding", "Question"].forEach((type) => {
        ["Easy", "Medium", "Hard"].forEach((difficulty) => {
          totalquestions +=
            obj?.Global[type]?.[difficulty] + obj?.Personal[type]?.[difficulty];
          totalTime +=
            (obj?.Global[type]?.[difficulty] +
              obj?.Personal[type]?.[difficulty]) *
            minutesMapping[type][difficulty];
        });
      });
    });
    return { totalTime: Math.ceil(totalTime / 60), totalquestions };
  };

  //HandleInputs
  const handleInputs = (e, name) => {
    let ind = finaldata.findIndex(
      (item) => item.MainTags === name.split("_")[3]
    );
    if (ind !== -1) {
      let obj = finaldata.filter(
        (item) => item.MainTags === name.split("_")[3]
      )[0];

      obj["MainTags"] = name.split("_")[3];
      obj["Tags"] = Selected?.Tags?.map((item) => item);
      let qbank = name.split("_")[2],
        Type = name.split("_")[0],
        Diff = name.split("_")[1];
      if (obj["MainTags"] === name.split("_")[3]) {
        obj[qbank][Type][Diff] = Number(e);
      }

      let qb = { Global: 0, Personal: 0 };
      let type = { Mcq: 0, Question: 0, Coding: 0 };
      Object.keys(obj[name.split("_")[2]]).forEach((category) => {
        Object.keys(obj[name.split("_")[2]][category]).forEach((difficulty) => {
          qb[name.split("_")[2]] +=
            obj[name.split("_")[2]][category][difficulty];
        });
      });
      // Calculate total Question from Global and Personal
      for (const category of ["Global", "Personal"]) {
        for (const difficulty of ["Easy", "Medium", "Hard"]) {
          type.Question += obj[category].Question[difficulty];
        }
      }
      // Calculate total Code from Global and Personal
      for (const category of ["Global", "Personal"]) {
        for (const difficulty of ["Easy", "Medium", "Hard"]) {
          type.Coding += obj[category].Coding[difficulty];
        }
      }
      // Calculate total MCQs from Global and Personal
      for (const category of ["Global", "Personal"]) {
        for (const difficulty of ["Easy", "Medium", "Hard"]) {
          type.Mcq += obj[category].Mcq[difficulty];
        }
      }

      obj["Mcq"] = type.Mcq;
      obj["Coding"] = type.Coding;
      obj["Question"] = type.Question;

      obj["globalcount"] = qb.Global;
      obj["personalcount"] = qb.Personal;

      let duplicate = [...finaldata];
      duplicate[ind] = obj;
      setFinalData(duplicate);
    } else {
      let obj = {
        MainTags: "",
        Tags: "",
        globalcount: 0,
        personalcount: 0,
        Mcq: 0,
        Coding: 0,
        Question: 0,
        Global: {
          Mcq: {
            Easy: 0,
            Medium: 0,
            Hard: 0,
          },
          Coding: {
            Easy: 0,
            Medium: 0,
            Hard: 0,
          },
          Question: {
            Easy: 0,
            Medium: 0,
            Hard: 0,
          },
        },
        Personal: {
          Mcq: {
            Easy: 0,
            Medium: 0,
            Hard: 0,
          },
          Coding: {
            Easy: 0,
            Medium: 0,
            Hard: 0,
          },
          Question: {
            Easy: 0,
            Medium: 0,
            Hard: 0,
          },
        },
      };
      obj["MainTags"] = name.split("_")[3];
      obj["Tags"] = Selected?.Tags?.map((item) => item);
      let qbank = name.split("_")[2],
        Type = name.split("_")[0],
        Diff = name.split("_")[1];

      obj[qbank][Type][Diff] = Number(e);

      let qb = { Global: 0, Personal: 0 };
      let type = { Mcq: 0, Question: 0, Coding: 0 };

      Object.keys(obj[name.split("_")[2]]).forEach((category) => {
        Object.keys(obj[name.split("_")[2]][category]).forEach((difficulty) => {
          qb[name.split("_")[2]] +=
            obj[name.split("_")[2]][category][difficulty];
        });
      });

      // Calculate total Question from Global and Personal
      for (const category of ["Global", "Personal"]) {
        for (const difficulty of ["Easy", "Medium", "Hard"]) {
          type.Question += obj[category].Question[difficulty];
        }
      }
      // Calculate total Code from Global and Personal
      for (const category of ["Global", "Personal"]) {
        for (const difficulty of ["Easy", "Medium", "Hard"]) {
          type.Coding += obj[category].Coding[difficulty];
        }
      }
      // Calculate total MCQs from Global and Personal
      for (const category of ["Global", "Personal"]) {
        for (const difficulty of ["Easy", "Medium", "Hard"]) {
          type.Mcq += obj[category].Mcq[difficulty];
        }
      }

      obj["Mcq"] = type.Mcq;
      obj["Coding"] = type.Coding;
      obj["Question"] = type.Question;

      obj["globalcount"] = qb.Global;
      obj["personalcount"] = qb.Personal;

      setFinalData([...finaldata, obj]);
    }
  };

  // Warning Message
  const Warning = (val) => {
    messageApi.open({
      type: "warning",
      content: val,
    });
  };

  //  Edit mock
  useEffect(() => {
    if (location.state) {
      const {
        MockTitle,
        Test_Name,
        MockData,
        Duration,
        MockStart,
        Mockid,
        NoofQ,
        Testid,
        Extra,
        Settings,
      } = location.state.details;

      let editstartdate = new Date(MockStart),
        editenddate = new Date(
          location.state.details.Mockend !== " " &&
            location.state.details.Mockend
        );
      let editedstartdate =
        editstartdate.getDate() < 10
          ? "0" + editstartdate.getDate()
          : editstartdate.getDate();
      let editedstartmonth =
        editstartdate.getMonth() + 1 < 10
          ? editstartdate.getMonth() + 1
          : editstartdate.getMonth() + 1;
      let finalstart = `${editstartdate.getFullYear()}-${editedstartmonth}-${editedstartdate}  ${editstartdate.getHours()}:${editstartdate.getMinutes()}`;
      let editedenddate =
        editenddate.getDate() < 10
          ? "0" + editenddate.getDate()
          : editenddate.getDate();
      let editedendmonth =
        editenddate.getMonth() + 1 < 10
          ? editenddate.getMonth() + 1
          : editenddate.getMonth() + 1;
      let finalend = `${editenddate.getFullYear()}-${editedendmonth}-${editedenddate} ${editenddate.getHours()}:${editenddate.getMinutes()}`;

      setTestName(MockTitle ?? Test_Name);

      setDates({
        startdate: new Date(finalstart),
        enddate: location.state.details.Mockend !== " " && new Date(finalend),
      });
      setQuestionandduration({ duration: Duration, totalquestion: NoofQ });
      setMockcat(location.state.details.type ?? "One Time Test");
      setEdit(true);

      setquestionbank({
        global: Extra?.isglobal ?? MockData[0].globalcount > 0,
        personal: Extra?.ispersonal ?? MockData[0].personalcount > 0,
      });
      setFinalData(MockData);

      let arr = [],
        Tags = [];
      MockData.map((item) => {
        arr.push(item.MainTags);
        Tags.push(item.Tags);
        return true;
      });
      let newTopics = [...new Set(arr)],
        newTags = [...new Set(Tags)];

      setSelected({
        Topics: Extra?.Topics ?? newTopics,
        Tags: Extra?.Tags ?? newTags,
      });
      setMockId(Mockid ?? Testid);
      setTypeSelection({ Mcq: true, Question: true, Coding: true });
      setIsCapturing({
        user: Settings?.capture_user ?? true,
        screen: Settings?.capture_screen ?? true,
      });

      setSettings({
        copypaste: Settings?.copypaste ?? true,
        autofeedback: Settings?.autofeedback ?? false,
        TestTimer: Settings?.TestTimer??false,
    visible: Settings?.visible??false,
      });

      setCloseOnTab({
        close: Settings?.close_on_tab?.close ?? true,
        limit: Settings?.close_on_tab?.limit ?? 3,
      });
      setPracticemode(Settings?.practice_mode ?? false);
      setIntroduction(Settings?.capture_intro ?? false);
    } else {
      setEdit(false);
    }
    // eslint-disable-next-line
  }, [location.state]);

  // Final Submit
  const FinalSubmit = () => {
    setShow(false);
    let modifiedfinaldate = new Date();
    modifiedfinaldate.setDate(modifiedfinaldate.getDate() + 10);
    let newenddate = Dates.enddate === "" ? modifiedfinaldate : Dates.enddate;
    let obj = {
      title: TestName,
      mockstart: Dates.startdate,
      mockend: newenddate,
      createdby: user.FullName,
      duration: Questionandduration.duration,
      mockdata: finaldata,
      user: [], // selectedusers,
      NoOfQuestions: Questionandduration.totalquestion,
      edit: edit,
      type: mockcat,
      Settings: {
        capture_user: Capture.user,
        capture_screen: Capture.screen,
        close_on_tab: closeontab,
        practice_mode: Practicemode,
        capture_intro: Introduction,
        copypaste: settings?.copypaste,
        autofeedback: settings?.autofeedback,
        TestTimer: settings?.TestTimer,
        visible: settings?.visible,
      },
      Extra: {
        Topics: Selected?.Topics,
        Tags: Selected?.Tags,
        isglobal: questionbank?.global,
        ispersonal: questionbank?.personal,
        TopicsWiseTags: TopicswiseTags,
      },
    };

    if (mockcat === "One Time Test" || location?.state?.testtype === "OTT") {
      postrequest(`/createmock/${user?.subdomain}/${Mockid}`, obj)
        .then((res) => {
          if (res.status === 201) {
            navigate("/admin");
          } else if (res.status === 202) {
            toast.warning("Subscription Expired.", { position: "top-center" });
            navigate("/admin");
          } else {
            navigate("/admin");
          }
        })
        .catch((err) =>
          toast.warning("server error", {
            position: "top-right",
            autoClose: 1500,
          })
        );
    } else if (
      location?.state?.testtype === "CATupdate" ||
      mockcat !== "One Time Test"
    ) {
      postrequest(`/createcategory/${user?.subdomain}/${Mockid}`, obj)
        .then((res) => {
          if (res.status === 201) {
            navigate(`/admin?cat=${mockcat}`);
          } else if (res.status === 202) {
            toast.warning("Subscription Expired.", { position: "top-center" });

            navigate(`/admin?cat=${mockcat}`);
          } else {
            navigate(`/admin?cat=${mockcat}`);
          }
        })
        .catch((err) =>
          toast.warning("server error", {
            position: "top-right",
            autoClose: 1500,
          })
        );
    }
  };
  // Submit Confirmation
  const HandleSubmit = ({ show }) => {
    return (
      <Modal
        open={show}
        closable={false}
        onOk={() => FinalSubmit()}
        onCancel={() => setShow(false)}
        title="Confirm"
        centered
      >
        <label>Are you want to submit?</label>
      </Modal>
    );
  };

  // Before Submit
  const Submit = () => {
    if (totalselectedtheory > 0) {
      setIsCapturing((val) => ({ ...val, user: true }));
    }
    if (TestName === "") {
      Warning("Please enter the Test Name.");
    } else if (mockcat === "One Time Test" && Dates?.startdate === "") {
      Warning("Please enter Start Date");
    } else if (finaldata.length === 0) {
      Warning("Select at least one question.");
    } else {
      setShow(true);
    }
  };

  // Create a reusable function for InputNumber component
  const renderInputNumber = (
    globalValue,
    itemValue,
    type,
    difficulty,
    data
  ) => {
    const defaultval = (val, bnk) => {
      const filteredItem = data.find((item) => item.MainTags === val?.Topic);

      if (filteredItem) {
        return filteredItem[bnk]?.[type]?.[difficulty];
      }
      // Return a default value if the filtered item is not found or doesn't contain the expected structure
      return 0;
    };
    return (
      <>
        {(globalValue?.[type]?.[difficulty] === undefined ||
          globalValue?.[type]?.[difficulty] === 0) &&
        (itemValue?.[type]?.[difficulty] === undefined ||
          itemValue?.[type]?.[difficulty] === 0) ? (
          "--"
        ) : (
          <>
            {" "}
            <InputNumber
              className={styles.numberinput}
              addonBefore={"G"}
              addonAfter={`${globalValue?.[type]?.[difficulty] ?? "0"}`}
              controls={false}
              size="small"
              style={{
                display:
                  globalValue?.[type]?.[difficulty] === undefined ||
                  globalValue?.[type]?.[difficulty] === 0
                    ? "none"
                    : null,
              }}
              max={globalValue?.[type]?.[difficulty]}
              onChange={(e) =>
                handleInputs(
                  e,
                  `${type}_${difficulty}_Global_${globalValue?.Topic}`
                )
              }
              defaultValue={defaultval(globalValue, "Global")}
            />
            <InputNumber
              className={styles.numberinput}
              addonBefore={"P"}
              addonAfter={`${itemValue?.[type]?.[difficulty] ?? "0"}`}
              controls={false}
              size="small"
              style={{
                display:
                  itemValue?.[type]?.[difficulty] === undefined ||
                  itemValue?.[type]?.[difficulty] === 0
                    ? "none"
                    : null,
              }}
              max={itemValue?.[type]?.[difficulty]}
              onChange={(e) =>
                handleInputs(
                  e,
                  `${type}_${difficulty}_Personal_${itemValue?.Topic}`
                )
              }
              defaultValue={defaultval(itemValue, "Personal")}
            />
          </>
        )}
      </>
    );
  };
  // Menu Items
  const menuItems = [
    getItem(
      <span className={styles.menulabel}>Dashboard</span>,
      "admin",
      <HomeOutlined />
    ),
    getItem(
      <span className={styles.menulabel}>Question Bank</span>,
      "questions",
      <UnorderedListOutlined />,
      null,
      null,
      true
    ),
    (clientdetails?.isgroup ?? false) &&
      getItem(
        <span className={styles.menulabel}>Batches</span>,
        "managegroups",
        <UsergroupAddOutlined />,
        null,
        null,
        true
      ),
    getItem(
      <span className={styles.menulabel}>Courses</span>,
      "course",
      <ReconciliationOutlined />
    ),
  ];

  const handlerClick = (e) => {
    setMenuKey(e.key);
    if (["admin", "questions", "managegroups","course"].includes(e.key)) {
      navigate(`/${e.key}`);
    }

    setOpenDwa(false);
  };

  // Check whether which questions need to render
  const filterToMap =
    personalfilter.length > 0 && globalfilter.length > 0
      ? personalfilter // Both arrays have elements, choose personalfilter
      : globalfilter.length > 0
      ? globalfilter // Only globalfilter has elements
      : personalfilter; //

  return (
    <>
      <div className={styles.mainBox}>
        <div className={styles.slider}>
          <DashboardSlider
            menuItems={menuItems}
            handlerClick={handlerClick}
            // setMenuKey={setMenuKey}
            activekey={menuKey}
          />
        </div>
        <div className={styles.main}>
          {/* Heading */}
          <LatestNavbar setOpenDwa={setOpenDwa} openDwa={openDwa} />
          <ToastContainer />
          {contextHolder}
          {contextHolder2}
          <HandleSubmit show={show} />

          <div className={styles.heading}>
            <h2>Create A Test Here</h2>
          </div>
          {/* First Row Data */}
          <div className={styles.first_row}>
            {/* Test Name */}
            <div
              className={styles.first_row_item}
              style={{ width: mockcat === "One Time Test" ? null : "600px" }}
            >
              <label>Test Name</label>
              <TextArea
                autoSize={{ minRows: 1, maxRows: 2 }}
                className={styles.first_row_input}
                onChange={(e) => setTestName(e.target.value)}
                value={TestName}
                style={{ width: mockcat === "One Time Test" ? null : "600px" }}
              />
            </div>

            {/* Start Date */}
            {mockcat === "One Time Test" && (
              <div className={styles.first_row_item}>
                <label
                  style={{
                    textDecoration:
                      mockcat !== "One Time Test" ? "line-through" : null,
                  }}
                >
                  Start Date
                </label>
                <input
                  type="datetime-local"
                  value={
                    Dates.startdate && convertDateIntoNormalend(Dates.startdate)
                  }
                  className={styles.first_row_input}
                  min={convertDateIntoNormal2()}
                  onChange={(e) =>
                    setDates({
                      ...Dates,
                      startdate: new Date(e.target.value),
                    })
                  }
                  disabled={mockcat !== "One Time Test"}
                  style={{
                    backgroundColor:
                      mockcat !== "One Time Test" ? "#dee3e0" : null,
                  }}
                />
              </div>
            )}

            {/* End Date */}
            {mockcat === "One Time Test" && (
              <div className={styles.first_row_item}>
                <label>End Date (optional)</label>{" "}
                <input
                  type="datetime-local"
                  value={
                    Dates.enddate && convertDateIntoNormalend(Dates.enddate)
                  }
                  onChange={(e) =>
                    setDates({ ...Dates, enddate: new Date(e.target.value) })
                  }
                  className={styles.first_row_input}
                  min={convertDateIntoNormalend(Dates.startdate)}
                />
              </div>
            )}

            {/* Question Bank */}
            <div className={styles.first_row_item}>
              <label>Question Bank </label>

              <div className={styles.second_row_questionbank}>
                {/* Global Question Bank Selction */}
                <div className={styles.checkboxes}>
                  <input
                    type="checkbox"
                    id="global"
                    checked={questionbank.global}
                    value="global"
                    onChange={(e) => handleQuestionBank(e)}
                    className={styles.questionbankcheckbox}
                  />{" "}
                  <label htmlFor="global" className={styles.questionbanklabel}>
                    Global
                  </label>
                </div>
                {/* Personal question bank selection */}
                <div className={styles.checkboxes}>
                  <input
                    type="checkbox"
                    id="personal"
                    checked={questionbank.personal}
                    value="personal"
                    onChange={(e) => handleQuestionBank(e)}
                    className={styles.questionbankcheckbox}
                  />{" "}
                  <label
                    htmlFor="personal"
                    className={styles.questionbanklabel}
                  >
                    Personal
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* Second Row */}
          {loading && (questionbank.global || questionbank.personal) && (
            // Loading
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "50px",
              }}
            >
              <span
                style={{
                  width: "230px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <label className={styles.myspinner}></label>
                <label style={{ fontWeight: "600", fontSize: "18px" }}>
                  Fetching Questions...
                </label>
              </span>
            </div>
          )}

          {(questionbank.global || questionbank.personal) && !loading && (
            <div className={styles.second_row}>
              {/* Topics */}
              <div className={styles.first_row_item}>
                <label>Topics</label>

                <Select
                  maxTagCount={"responsive"}
                  options={Selection.Topics}
                  defaultValue={Selected?.Topics}
                  onChange={(e) =>
                    onchangemaintopics(
                      e,
                      QuestionData.globaldata,
                      QuestionData.personaldata,
                      questionbank.global,
                      questionbank.personal
                    )
                  }
                  showSearch
                  mode="multiple"
                  style={{ width: "250px", borderColor: "black" }}
                />
                <div className="d-flex justify-content-between">
                  {questionbank.global &&
                    QuestionFinaldata.globaldata.length > 0 && (
                      <label>
                        Global : {QuestionFinaldata.globaldata.length}
                      </label>
                    )}
                  {questionbank.personal &&
                    QuestionFinaldata.personaldata.length > 0 && (
                      <label>
                        Personal : {QuestionFinaldata.personaldata.length}
                      </label>
                    )}
                </div>
              </div>
              {/* Tags */}
              <div className={styles.first_row_item}>
                {Selected?.Topics?.length > 0 &&
                  (QuestionFinaldata.personaldata.length > 0 ||
                    QuestionFinaldata.globaldata.length > 0) && (
                    <>
                      <label>Tags</label>
                      <Select
                        maxTagCount={"responsive"}
                        options={Selection.Tags}
                        value={Selected?.Tags}
                        onChange={(e) => onchangetopics(e)}
                        showSearch
                        mode="multiple"
                        style={{ width: "250px", borderColor: "black" }}
                      />
                      <div
                        className="d-flex justify-content-between"
                        style={{ width: "230px" }}
                      >
                        {countfinalquestion?.Global > 0 && (
                          <label>Global : {countfinalquestion?.Global}</label>
                        )}
                        {countfinalquestion?.Personal > 0 && (
                          <label>
                            Personal : {countfinalquestion?.Personal}
                          </label>
                        )}
                      </div>
                    </>
                  )}
              </div>
              {/* Question Types */}
              <div className={styles.first_row_item}>
                {(countfinalquestion?.Global > 0 ||
                  countfinalquestion.Personal > 0) && (
                  <>
                    <label>Types</label>
                    <div
                      className="d-flex justify-content-between mt-2"
                      style={{ width: "280px" }}
                    >
                      {/* Question Question Selection */}
                      {totaltype.simple > 0 && (
                        <span className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            className={styles.questionbankcheckbox}
                            onChange={(e) => {
                              setTypeSelection({
                                ...Typeselection,
                                Question: e.target.checked,
                              });
                            }}
                            id="theory"
                            defaultChecked={Typeselection.Question}
                          />
                          <label htmlFor="theory" className={styles.typeslabel}>
                            Theory
                          </label>
                        </span>
                      )}
                      {/* Coding Question Selection */}
                      {totaltype.coding > 0 && (
                        <span className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            className={styles.questionbankcheckbox}
                            onChange={(e) => {
                              setTypeSelection({
                                ...Typeselection,
                                Coding: e.target.checked,
                              });
                            }}
                            id="coding"
                            defaultChecked={Typeselection.Coding}
                          />
                          <label htmlFor="coding" className={styles.typeslabel}>
                            Coding
                          </label>
                        </span>
                      )}
                      {/* Mcq question selection */}
                      {totaltype.mcq > 0 && (
                        <span className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            className={styles.questionbankcheckbox}
                            onChange={(e) => {
                              setTypeSelection({
                                ...Typeselection,
                                Mcq: e.target.checked,
                              });
                            }}
                            id="mcq"
                            defaultChecked={Typeselection.Mcq}
                          />
                          <label htmlFor="mcq" className={styles.typeslabel}>
                            MCQ
                          </label>
                        </span>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className={styles.first_row_item}>
                {(Typeselection?.Mcq ||
                  Typeselection?.Coding ||
                  Typeselection?.Question) && (
                  <>
                    <div>Note </div>
                    <div>
                      <label>P - Personal</label>
                      <label className="mx-4">G - Global</label>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          <div className={styles.settingandselection}>
            {/* Settings */}

            <div className={styles.settings}>
              <label>MISC Settings</label>

              <div className="d-flex justify-content-between align-items-center flex-wrap mt-4">
                {/* Screen Capture */}
                <div className={styles.lefttogglesetting}>
                  <label>Capture Screen</label>
                  <Switch
                    // defaultValue={Capture?.screen}
                    onChange={(val) =>
                      setIsCapturing({ ...Capture, screen: val })
                    }
                    checked={Capture?.screen}
                  />
                </div>
                {/* User Capture */}
                <div className={styles.lefttogglesetting}>
                  <label>Capture User</label>
                  <Switch
                    // defaultValue={Capture?.user}
                    onChange={(val) => {
                      if (totalselectedtheory !== 0 && val === false) {
                        openNotificationWithIcon(
                          "warning",
                          "To proceed with the theory questions, we need to enable the 'Capture User' option."
                        );
                      } else {
                        setIsCapturing({ ...Capture, user: val });
                      }

                      if (val === false) {
                        setIntroduction(false);
                      }
                    }}
                    checked={Capture?.user}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center flex-wrap mt-4">
                {/* Copy Paste */}
                <div className={styles.lefttogglesetting}>
                  <label>Enable Copy/Paste ?</label>
                  <Switch
                    // defaultValue={Capture?.screen}
                    onChange={(val) =>
                      setSettings({ ...settings, copypaste: val })
                    }
                    checked={settings?.copypaste}
                  />
                </div>
                {/* Auto Feedback */}
                <div className={styles.lefttogglesetting}>
                  <label>Auto Feedback</label>
                  <Switch
                    // defaultValue={Capture?.user}
                    onChange={(val) =>
                      setSettings({ ...settings, autofeedback: val })
                    }
                    checked={settings?.autofeedback}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-start flex-wrap  mt-3">
                {/* Close on Tab Change  */}
                <div className={styles.leftsettings}>
                  <label>Close on Tab Change</label>
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <Switch
                      defaultValue={closeontab?.close}
                      onChange={(val) => {
                        setCloseOnTab({ ...closeontab, close: val });
                      }}
                      checked={closeontab?.close}
                    />
                    <InputNumber
                      disabled={closeontab?.close === false}
                      value={closeontab?.limit}
                      onChange={(e) =>
                        setCloseOnTab({ ...closeontab, limit: e })
                      }
                      className="w-50"
                      defaultValue={3}
                      max={10}
                      size="small"
                    />{" "}
                  </div>
                </div>

                {/* Practice Mode  */}
                <div className={styles.practicemode}>
                  {(searchParams.size > 0 || location.state !== null) && (
                    <div
                      className={styles.lefttogglesetting}
                      style={{ width: "100%" }}
                    >
                      <label>
                        Practice Mode{" "}
                        <Tooltip title="This will enable practice mode">
                          {" "}
                          <InfoCircleOutlined style={{ cursor: "pointer" }} />
                        </Tooltip>
                      </label>
                      <Switch
                        defaultValue={Practicemode}
                        onChange={(e) => setPracticemode(e)}
                        checked={Practicemode}
                      />
                    </div>
                  )}

                  <div
                    className={styles.lefttogglesetting}
                    style={{ width: "100%", marginTop: "15px" }}
                  >
                    <label>User Introduction</label>
                    <Switch
                      defaultValue={Introduction}
                      onChange={(e) => {
                        if (!Capture.user && e === true) {
                          openNotificationWithIcon(
                            "warning",
                            "To proceed with the introduction, you'll need to enable the 'Capture User' option."
                          );
                        } else {
                          setIntroduction(e);
                        }
                      }}
                      checked={Introduction}
                    />
                  </div>
                </div>

                {/* Test Timer */}
                {(searchParams.size === 0 || location.state === null) && (
                  <div className={`${styles.lefttogglesetting} mt-3`}>
                    <label>Overall Timer</label>
                    <Switch
                      // defaultValue={Capture?.user}
                      onChange={(val) =>
                        setSettings({ ...settings, TestTimer: val })
                      }
                      checked={settings?.TestTimer}
                    />
                  </div>
                )}
                {/* Visibility */}
                <div className={`${styles.lefttogglesetting} mt-3`}>
                  <label>Public</label>
                  <Switch
                    // defaultValue={Capture?.user}
                    onChange={(val) => {
                      if (val) {
                        setWarning(true);
                      } else {
                        setSettings({ ...settings, visible: false });
                      }
                    }}
                    checked={settings?.visible}
                  />
                </div>
                <Modal
                  open={warning}
                  onCancel={() => setWarning(false)}
                  onOk={() => {setSettings({ ...settings, visible: true });setWarning(false)}}
                  closable={false}
                  maskClosable={false}
                  okText="Yes"
                  cancelText="No"
                  title="Warning"
                >
                  Test will be visible to everyone. Are you sure you want to make the test
public?

                </Modal>
              </div>

              <div className="d-flex justify-content-between flex-wrap w-100 mt-4">
                {/* Screen Capture */}
                <div className={styles.lefttogglesetting}>
                  <label>
                    Min Test Duration{" "}
                    <Tooltip
                      placement="right"
                      title={() => {
                        return (
                          <div style={{ width: "200px" }}>
                            * It's the minimum test duration, which may vary
                            based on random questions for individual users.
                            <br /> * However, the time allocated per specific
                            question remains consistent.
                          </div>
                        );
                      }}
                    >
                      <InfoCircleOutlined style={{ cursor: "pointer" }} />
                    </Tooltip>
                  </label>
                  <label>
                    {settings?.TestTimer ? (
                      <InputNumber
                        value={Questionandduration.duration}
                        controls={false}
                        onChange={(val) =>
                          setQuestionandduration((prev) => ({
                            ...prev,
                            duration: val,
                          }))
                        }
                        addonAfter="Mins"
                        width={50}
                        size="small"
                        style={{ width: "100px" }}
                      />
                    ) : (
                      Questionandduration.duration + "/Mins"
                    )}
                  </label>
                </div>
                {/* User Capture */}
                <div className={styles.lefttogglesetting}>
                  <label>Total Questions</label>
                  <label>{Questionandduration.totalquestion}</label>
                </div>
              </div>
              {/* Final Submit Button */}
              {Questionandduration.totalquestion > 0 && (
                <div className="d-flex justify-content-center mb-3 mt-3">
                  <div className="d-flex flex-column align-items-center">
                    <Button
                      className={styles.submitbtn}
                      onClick={() => Submit()}
                      type="primary"
                      ghost
                    >
                      {mockcat === "One Time Test"
                        ? edit
                          ? "Update Test"
                          : "Create Test"
                        : edit
                        ? `Update ${mockcat} Section `
                        : `Add to ${mockcat} Section`}
                    </Button>
                    {mockcat === "One Time Test" && (
                      <label>*All Questions will be random</label>
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* Right Selection */}
            <div className={styles.selection}>
              {(Typeselection?.Mcq ||
                Typeselection?.Coding ||
                Typeselection?.Question) &&
                Selected.Tags.length > 0 &&
                Selected.Topics.length > 0 &&
                (questionbank.global || questionbank.personal) &&
                filterToMap.length > 0 &&
                filterToMap.map((item, index) => {
                  return (
                    <div className={styles.selectionmain} key={index}>
                      <table>
                        <thead>
                          <tr>
                            <th>{item.Topic}</th>
                            {index === 0 && (
                              <>
                                <th>Easy</th>
                                <th>Medium</th>
                                <th>Hard</th>
                              </>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {/* Loop through types */}
                          {["Question", "Coding", "Mcq"].map((type) => {
                            return (
                              <React.Fragment key={type}>
                                {Typeselection?.[type] &&
                                  !(
                                    !item?.[type]?.Easy &&
                                    !item?.[type]?.Medium &&
                                    !item?.[type]?.Hard
                                  ) && (
                                    <tr>
                                      <td>
                                        {type === "Mcq"
                                          ? "MCQ"
                                          : type === "Question"
                                          ? "Theory"
                                          : type}
                                      </td>

                                      <td>
                                        {renderInputNumber(
                                          globalfilter[index],
                                          personalfilter[index],
                                          type,
                                          "Easy",
                                          finaldata
                                        )}
                                      </td>

                                      <td>
                                        {renderInputNumber(
                                          globalfilter[index],
                                          personalfilter[index],
                                          type,
                                          "Medium",
                                          finaldata
                                        )}
                                      </td>
                                      <td>
                                        {renderInputNumber(
                                          globalfilter[index],
                                          personalfilter[index],
                                          type,
                                          "Hard",
                                          finaldata
                                        )}
                                      </td>
                                    </tr>
                                  )}
                              </React.Fragment>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <ProfileSlider
        setOpenDwa={setOpenDwa}
        openDwa={openDwa}
        user={user}
        menuItems={menuItems}
        handlerClick={handlerClick}
        setMenuKey={setMenuKey}
      />
    </>
  );
};

export default CreateMock;
