import React, { useState,useEffect } from "react";
import {  State } from "country-state-city";
import styles from "./styles/profile_update.module.css";
import { Modal, Select, Button, message } from "antd";
import { putrequest } from "../../Middleware/managerequest";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

const Profileupdate = ({ details,updateProfile ,user,accounts}) => {

  const [messageApi, contextHolder] = message.useMessage();
  const warning = (msg) => {
    messageApi.warning(msg);
  };
  const [loading,setLoading]=useState(false)
  const [status, setStatus] = useState("ad");
  const [state, setState] = useState("");
  const [personaldetails, setPersonalDetails] = useState();
  const [educationdetails, setEducation_details] = useState([]);
  const [tempeducation, setTemp_Education] = useState("");

  useEffect(()=>{
 
     if(!details?.ispersonal){

setStatus("ed")
     }
  },[details?.ispersonal])

  const AddToItems = () => {
    if (
      !tempeducation?.year ||
      tempeducation?.CurrentlyAppearing === undefined ||
      !tempeducation?.title ||
      !tempeducation?.description
    ) {
      warning("All fields are mandatory");
    } else if (tempeducation?.year.length !== 4) {
      warning("Year is invalid");
    } else {
      setEducation_details((prev) => [...prev, tempeducation]);
      setTemp_Education("");
    }
  };
const key="updating"
  const FinalSubmit = () => {
    if (details.ispersonal) {
      if (
        !personaldetails?.firstLine ||
        !personaldetails?.state ||
        !personaldetails?.city
      ) {
        warning("Kindly fill the complete address");
        return;
      }
    }
    if (details.iseducations) {
      if (educationdetails.length === 0) {
        warning("Add at least one education details");
        return;
      }
    }

    let obj = null;
    if (details?.iseducations && !details.ispersonal) {
      obj = {
        educationdetails,
      };
    } else if (!details?.iseducations && details.ispersonal) {
      let findstate = State.getStatesOfCountry("IN").filter(
        (item) => item.isoCode === personaldetails.state
      )[0].name;
      let duplicatepersonal = { ...personaldetails };
      duplicatepersonal.state = findstate;
      obj = {
        duplicatepersonal,
      };
    } else {
      let findstate = State.getStatesOfCountry("IN").filter(
        (item) => item.isoCode === personaldetails.state
      )[0].name;
      let duplicatepersonal = { ...personaldetails };
      duplicatepersonal.state = findstate;
      obj = {
        duplicatepersonal,
        educationdetails,
      };
    }
   setLoading(true) 
   
    messageApi.loading({content:"Saving the details",key})
    putrequest(`/user/update_personal_details/${user?.Email}/?fullname=${user?.FullName}&&clientid=${user?.subdomain}`, obj)
      .then((res) =>{
        setLoading(false)
        messageApi.success({
          key,
          content:"successfully saved"
        })

        updateProfile()
        
      })
      .catch((err) => {
        setLoading(false)
        messageApi.error({
          key,
          content:"Error while updating"
        })
        updateProfile()
      });
  };
  
  return (
    <Modal
      open={details?.ispersonal || details?.iseducations}
      centered
      width={800}
      maskClosable={false}
      closable={false}
      footer={null}
    >
      {contextHolder}
      {details?.ispersonal && status === "ad" && (
        <>
          <h5>Personal Details</h5>

          <div className={styles.FirstLine_Address}>
            <div className={styles.child_Address}>
              <label className={styles.address_label}>Address Line 1 *</label>
              <input
                placeholder="Address Line 1"
                className={styles.child_input1}
                onChange={(e) =>
                  setPersonalDetails((prev) => ({
                    ...prev,
                    firstLine: e.target.value,
                  }))
                }
                value={personaldetails?.firstLine ?? ""}
              />
            </div>
            <div className={styles.child_Address}>
              <label className={styles.address_label}>
                Address Line 2 (optional)
              </label>
              <input
                placeholder="Address Line 2"
                className={styles.child_input1}
                onChange={(e) =>
                  setPersonalDetails((prev) => ({
                    ...prev,
                    secondLine: e.target.value,
                  }))
                }
                value={personaldetails?.secondLine ?? ""}
              />
            </div>
          </div>

          <div className={styles.FirstLine_Address}>
          <div className={styles.child_Address}>
              <label className={styles.address_label}>City *</label>

              <input
                placeholder="City"
                className={styles.child_input1}
                onChange={(e) =>
                  setPersonalDetails((prev) => ({
                    ...prev,
                    city: e.target.value,
                  }))
                }
                value={personaldetails?.city ?? ""}
              />
            </div>
            <div className={styles.child_Address}>
              <label className={styles.address_label}>State *</label>
              <Select
                onChange={(e) => {
                  setState(e);
                  setPersonalDetails((prev) => ({ ...prev, state: e }));
                }}
                className={styles.child_input}
                options={State.getStatesOfCountry("IN").map((item) => {
                  return {
                    label: item.name,
                    value: item.isoCode,
                  };
                })}
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Select the State"
                defaultValue={personaldetails?.state ?? state}
              />
            </div>
           
          </div>
        </>
      )}

      {details?.iseducations && status === "ed" && (
        <>
          <h5>Education Details</h5>
          <div className={styles.FirstLine_Address}>
            <div className={styles.child_Address}>
              <label className={styles.address_label}>Qualification *</label>
              <input
                placeholder="Degree or College"
                className={styles.child_input1}
                value={tempeducation?.title ?? ""}
                onChange={(e) =>
                  setTemp_Education((prev) => ({
                    ...prev,
                    title: e.target.value,
                  }))
                }
              />
            </div>
            <div className={styles.child_Address}>
              <label className={styles.address_label}>
                University or College *
              </label>
              <input
                placeholder="University or College"
                className={styles.child_input1}
                value={tempeducation?.description ?? ""}
                onChange={(e) =>
                  setTemp_Education((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
              />
            </div>
          </div>

          <div className={styles.FirstLine_Address}>
            <div className={styles.child_Address}>
              <label className={styles.address_label}>Year of Passing *</label>
              <input
                placeholder="Year"
                className={styles.child_input1}
                type="number"
                value={tempeducation?.year ?? ""}
                onChange={(e) =>
                  setTemp_Education((prev) => ({
                    ...prev,
                    year: e.target.value,
                  }))
                }
              />
            </div>
            <div className={styles.child_Address}>
              <label className={styles.address_label}>Status *</label>

              <Select
                className={styles.child_input}
                value={tempeducation?.CurrentlyAppearing ?? ""}
                onChange={(e) =>
                  setTemp_Education((prev) => ({
                    ...prev,
                    CurrentlyAppearing: e,
                  }))
                }
                options={[
                  { label: "Completed", value: false },
                  { label: "Appearing", value: true },
                ]}
              />
            </div>
          </div>

          <center className="mt-2">
            <Button
              type="primary"
              onClick={() => AddToItems()}
              icon={<PlusCircleOutlined />}
            >
              {" "}
              Add To List
            </Button>
          </center>
          {educationdetails.length > 0 && (
            <table
              style={{ width: "100%", height: "200px", marginTop: "10px" }}
            >
              <thead>
                <tr>
                  <th>Qualification</th>
                  <th>University or College</th>
                  <th>Year of Passing</th>
                  <th>Status</th>
                  <th>Remove</th>
                </tr>
              </thead>

              <tbody style={{ height: "100px", overflow: "auto" }}>
                {educationdetails.map((item, ind) => {
                  return (
                    <tr key={ind}>
                      <td>{item?.title}</td>
                      <td>{item?.description}</td>
                      <td>{item?.year}</td>
                      <td>{item?.status ? "Appearing" : "Completed"}</td>
                      <td
                        className={styles.remove_education}
                        onClick={() => {
                          let duplicate = [...educationdetails];
                          duplicate.splice(ind, 1);
                          setEducation_details(duplicate);
                        }}
                      >
                        Remove
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </>
      )}

      {status === "ad" && details?.iseducations ? (
        <div className={`d-flex justify-content-end mt-3 mx-3`}>
          <label onClick={() => setStatus("ed")} style={{ cursor: "pointer" }}>
            Education Details
            <ArrowRightOutlined
              style={{ fontSize: "15px" }}
              title="Education Details"
              className="mx-2"
            />
          </label>
        </div>
      ) : (
        status === "ad" && (
          <div className={`d-flex justify-content-end mt-3 mx-3`}>
            <Button
              ghost
              type="primary"
              style={{ cursor: "pointer" }}
              onClick={() => FinalSubmit()}
              disabled={loading}
              loading={loading}
            >
              Submit
            </Button>
          </div>
        )
      )}

      {status === "ed" && details?.ispersonal ? (
        <div className={`d-flex justify-content-between mt-3 mx-1`}>
          <label onClick={() => setStatus("ad")} style={{ cursor: "pointer" }}>
            <ArrowLeftOutlined
              title="Address Details"
              style={{ fontSize: "15px" }}
              className="mx-2"
            />
            Personal Details
          </label>
          {educationdetails.length > 0 && (
            <Button
              onClick={() => FinalSubmit()}
              ghost
              type="primary"
              style={{ cursor: "pointer" }}
              disabled={loading}
              loading={loading}
            >
              Submit
            </Button>
          )}
        </div>
      ) : (
        status === "ed" && (
          <div className={`d-flex justify-content-end mt-3 mx-1`}>
           {educationdetails.length > 0 && (
            <Button
              onClick={() => FinalSubmit()}
              ghost
              type="primary"
              style={{ cursor: "pointer" }}
            >
              Submit
            </Button>
          )}
          </div>
        )
      )}
    </Modal>
  );
};

export default Profileupdate;
