import React, { useState } from "react";
import styles from "./Introduction.module.css";
import Webcam from "react-webcam";
import { captureVideo, stopVideo } from "../../Services/RecordingCam";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

const url = process.env.REACT_APP_BACKEND_SERVER;
let fd = new FormData();
const Introduction = ({  setmockstart, setIntroduction }) => {
  const { id } = useParams();
  const [isRecording, setRecording] = useState(false);
  const [recorded, setRecorded] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const data = useSelector((state) => state.Data);
  const { user } = data;

  const videoConstraints = {
    width: 1280,
    height: 800,
    facingMode: "user",
  };

  const StartRecording = () => {
    captureVideo()
      .then(() => setRecording(true))
      .catch((err) => alert(err));
  };

  const StopRecording = async () => {
    try {
      const res = await stopVideo();
      let url = null;
       fd=new FormData()
      for (const [key, value] of res.entries()) {
        fd.append(key, value);
        url = URL.createObjectURL(value);
      }
      setVideoUrl(url);
      setRecorded(true);
      setRecording(false);
    } catch (err) {
      console.error(err);
    }
  };

  const Reset = () => {
    setVideoUrl("");
    setRecorded(false);
  };

  const Submit = () => {
    const question = {
      question: "Kindly Introduce Yourself",
    };
    const obj = {
      Question: question,
      realpoints: 5,
      answer: "",
      point: 0,
      Email: user?.Email,
      Options: "",
      FullName: user?.FullName,
      Type: "Question",
    };

   
    fd.append("sample", JSON.stringify(obj));
    axios
      .post(`${url}/MyMock/${id}/${user?.subdomain}/${user?.Email}`, fd, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.error(err);
      });

    setIntroduction(false);
    setmockstart(true);
  };

  return (
    <div className="d-flex justify-content-center">
      <div className={styles.instruction_container}>
        <div className={styles.headings}>
          <h2 className={styles.ins}>Introduction</h2>
        </div>
        <label className={styles.headings}>
          Greeting {user?.FullName} !!!
        </label>
        <div className="d-flex justify-content-center">
          <div className={styles.container}>
            {recorded ? (
              <video src={videoUrl} controls className={styles.recorderdvideo} autoPlay />
            ) : (
              <Webcam
                audio={false}
                height={"100%"}
                width={"100%"}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                className={styles.cam}
                mirrored={true}
              />
            )}
          </div>
        </div>
        <div className={styles.headingsins}>
          <label>
            {isRecording
              ? "Recording..."
              : !recorded
              ? (
                <label>
                  *Click on <b>Start</b> button and introduce yourself
                </label>
              ) : (
                <label>If video and Audio are good, you can start.</label>
              )}
          </label>
        </div>
        <div>
          {isRecording && !recorded && (
            <button
              className={styles.RecordingStop}
              onClick={() => StopRecording()}
            >
              Stop
            </button>
          )}
          {!isRecording && !recorded && (
            <button
              className={styles.RecordingStart}
              onClick={() => StartRecording()}
            >
              Start
            </button>
          )}
          {!isRecording && recorded && (
            <button className={styles.RecordingStop} onClick={() => Reset()}>
              Reset
            </button>
          )}
        </div>
        <div className="d-flex justify-content-end mb-5 mx-5">
          {recorded && (
            <button onClick={() => Submit()} className={styles.next_btn}>
              Start Mock
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Introduction;
