import React from "react";
import { Popconfirm, Steps } from "antd";
import styles from "./styles/comps.module.css";
import { useSelector, useDispatch } from "react-redux";
import { postrequest } from "../../Middleware/managerequest";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { replaceExperience } from "../../../store/ExperienceSlice";

const ExpSteps = ({ setShowEditExpModal, setShow, setEditExp }) => {
  const Experience = useSelector((state) => state.Experience);
  const { user } = useSelector((state) => state.Data);
  const dispatch = useDispatch();

  return (
    <div style={{ width: "100%", padding: "60px 40px 0 40px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
           marginBottom: "20px"
        }}
      >
        <h4 style={{ fontFamily: "Poppins" }}>
          Experience journey
        </h4>
        <span onClick={() => setShow(true)}>
          <h6 style={{ fontFamily: "Poppins" }} className={styles.AddExp}>
            Add
          </h6>
        </span>
      </div>
      <div>
        {Experience.length !== 0 ? (
          <Steps
            progressDot
            current={Experience?.length - 1}
            direction="vertical"
            items={Experience?.map((step, index) => {
              return {
                key: index,

                title: (
                  <div className={styles.singleExp}>
                    <div>
                      {step.title} - {step?.experience ?? ""}
                      &nbsp;&nbsp;&nbsp;
                    </div>
                    <div className={styles.singleEduIcons}>
                      <span
                        onClick={() => {
                          setEditExp({ ...step, id: index });
                          setShowEditExpModal(true);
                        }}
                      >
                        <EditOutlined />
                      </span>
                      <Popconfirm
                        title={"Are you sure to delete?"}
                        placement="left"
                        okText="Yes"
                        onConfirm={() => {
                          postrequest(
                            `/api/v1/user/personal_details/${user?.Email}/?sec=experience&&operation=delete&&ind=${index}`
                          )
                            .then((res) => {
                              dispatch(replaceExperience(res.data.Experience));
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                      >
                        <span>
                          <DeleteOutlined />
                        </span>
                      </Popconfirm>
                    </div>
                  </div>
                ),

                description: step.description,

                status: "finish",
              };
            })}
          ></Steps>
        ) : (
          <h6
            style={{
              fontFamily: "Poppins",
              // marginBlock: "7vh",
              marginTop: "4vh",
            }}
          >
            No experience added yet
          </h6>
        )}
      </div>
    </div>
  );
};

export default ExpSteps;
