import styles from "./AdminHome.module.css";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { IoInformationCircleOutline, IoTimerOutline } from "react-icons/io5";
import {
  Result,
  Select,
  Modal,
  Skeleton,
  Tag,
  Card,
  Switch,
  InputNumber,
  message,
  Radio,
  Dropdown,
} from "antd";
import Cookies from "js-cookie";

import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  ContainerOutlined,
  PieChartOutlined,
  UsergroupAddOutlined,
  HomeOutlined,
  UnorderedListOutlined,
  LoadingOutlined,
  ReconciliationOutlined,
  FileOutlined,
  UploadOutlined,
  SettingFilled,
} from "@ant-design/icons";

import {
  EditOutlined,
  EllipsisOutlined,
  CopyOutlined,
  ShareAltOutlined,
  LinkOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
  SettingOutlined,
  CalendarOutlined,
  FontColorsOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Excel from "exceljs";
import { saveAs } from "file-saver";

import { SiMicrosoftexcel } from "react-icons/si";
import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs";
import { getrequest, postrequest } from "../Middleware/managerequest";
import CreatableSelect from "react-select/creatable";
import DashboardSlider from "../Navigator_Footer/DashboardSlider";
import ProfileSlider from "../Navigator_Footer/ProfileSlider";
import LatestNavbar from "../Navigator_Footer/LatestNavbar";
const { Meta } = Card;

// Menu
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const NewAdminHome = () => {
  const [openfileuploader, setFileUploader] = useState(false);
  const [selectedfiles, setSelectedFiles] = useState({});
  const { state } = useLocation();
  const [messageApi, contextHolder] = message.useMessage();
  const [IsCatogory, setIsCatogory] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openName, setOpenName] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const searchTimeout = useRef(null);
  const [item, setItem] = useState("");
  const [openDwa, setOpenDwa] = useState(false);
  const [menuKey, setMenuKey] = useState("dashboard");
  const [mockdata, setMockdata] = useState([]);
  const data = useSelector((state) => state.Data);
  const { user,  clientdetails,access } = data;
  const [isdata, setIsdata] = useState(true);
  const [isAll, setIsAll] = useState(true);
  const [Selecteduser, setSelecteduser] = useState([]);
  const [pageno, setPageno] = useState(1);
  const [search, setSearch] = useState("");
  const [Users, setUsers] = useState([]);
  const [sendingrequest, setSendingrequest] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showadd, setShowadd] = useState(false);
  const [isSegment, setIsSegment] = useState(false);
  const [practiceopen, setpracticeopen] = useState("Tests");
  const handleClose = () => {
    setShow(false);
    setSelecteduser([]);
  };
  const handleShow = () => setShow(true);
  const [Segment, setSegment] = useState([]);
  const [category, setCategory] = useState([]);
  const [selected_Section, setSelected_Section] = useState("");
  const [categoryList, setcategoryList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [totalpages, setTotalpages] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [opendate, setOpenDate] = useState(false);
  const [Startdate, setStartdate] = useState("");
  const [endDate, setEnddate] = useState("");
  const [loading,setLoading]=useState(false)

  // Warning
  const notificationmessage = useCallback(
    (val, err) => {
      messageApi.open({
        type: err,
        content: val,
      });
    },
    [messageApi]
  );

  useEffect(() => {
    let token =
      process.env.REACT_APP_ENVIRONMENT === "local"
        ? Cookies.get("digikull_local")
        : Cookies.get("digikull", { domain: ".skillhubpro.com" });

    if (!token) {
      navigate("/sessionexpired");
    }
  }, [navigate]);

  // Menu Items
  const menuItems = [
    getItem(
      <span className={styles.menulabel}>Dashboard</span>,
      "dashboard",
      <HomeOutlined style={{ fontWeight: "600", fontSize: "17px" }} />
    ),
    getItem(
      <span className={styles.menulabel}>Create One Time Test</span>,
      "createmock",
      <ContainerOutlined style={{ fontWeight: "600", fontSize: "17px" }} />,
      null,
      null,
      true
    ),
    getItem(
      <span className={styles.menulabel}>Predefined Test</span>,
      "section",
      <PieChartOutlined style={{ fontWeight: "600", fontSize: "17px" }} />,

      [
        ...category.slice(1).map((ele, index) => {
          return getItem(ele, ele);
        }),
        getItem(
          <span className={styles.menulabel}>Create Segment</span>,
          "Create Segment",
          <PlusCircleOutlined
            style={{ fontWeight: "600", fontSize: "17px" }}
          />,
          null,
          null,
          true
        ),
      ]
    ),

    getItem(
      <span className={styles.menulabel}>Question Bank</span>,
      "questions",
      <UnorderedListOutlined style={{ fontWeight: "600", fontSize: "17px" }} />,
      null,
      null
    ),
    access&&access?.role==="Admin" &&
      getItem(
        <span className={styles.menulabel}>Manage Users</span>,
        "manageusers",
        <SettingFilled
          style={{ fontWeight: "600", fontSize: "17px" }}
        />,
        null,
        null
      ),
      (clientdetails?.isgroup??false) &&
      getItem(
        <span className={styles.menulabel}>Batch</span>,
        "managegroups",
        <UsergroupAddOutlined
          style={{ fontWeight: "600", fontSize: "17px" }}
        />,
        null,
        null
      ),
    "main" === user?.subdomain &&
      getItem(
        <span className={styles.menulabel}>Manage Organizations</span>,
        "my_organizations",
        <UsergroupAddOutlined
          style={{ fontWeight: "600", fontSize: "17px" }}
        />,
        null,
        null
      ),
      getItem(
        <span className={styles.menulabel}>Courses</span>,
        "course",
        <ReconciliationOutlined
          style={{ fontWeight: "600", fontSize: "17px" }}
        />,
        null,
        null
      ),
  ];

  //  Get Particular Category
  const handleCategory = useCallback(
    (Name, user2) => {
      setIsAll(false);
      setIsCatogory(true);
      let user1 = user2 || user;
      setSelected_Section(Name);
      getrequest(`/getchallcategory/${user1?.subdomain}/${Name}`)
        .then((res) => {
          setMockdata(res.data?.alldata.reverse());
          setCategory(res.data?.allcategory?.Category);
          let length = res.data?.alldata.filter(
            (ele) => !ele?.Settings?.practice_mode ?? false
          ).length;
          setTotalpages(length);
          setIsCatogory(false);
        })
        .catch((err) => {
          notificationmessage("server error code-174");
        });
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    const myParam = searchParams.get("cat");
    if (myParam) {
      setMenuKey(myParam);
      setIsCatogory(true);
      setIsAll(false);
      handleCategory(myParam, user);
      setSearchParams(new URLSearchParams());
    }
  }, [state, user, handleCategory, searchParams, setSearchParams]);

  //Request for Update
  const mockupdate = () => {
    const myParam2 = searchParams.get("cat");
    if (!myParam2) {
      setIsdata(false);
      setIsAll(true);

      const myParam = searchParams.get("pageno");
      getrequest(`/viewmocks/${user?.subdomain}/?pageno=${myParam ?? pageno}`)
        .then((res) => {
          setIsAll(true);
          setIsdata(true);
          setMockdata(res.data?.alldata);
          setTotalpages(res.data?.totalCount);
          setCategory(res.data?.allcategory?.Category);
          let list = res.data?.allcategory?.Category?.map((ele) => {
            return {
              isDisabled: true,
              label: ele,
              value: ele,
            };
          });
          setcategoryList(list);
        })
        .catch((err) => notificationmessage("server error code-118", "error"));
    }
  };

  //Call when page change and first render
  useEffect(() => {
    if (user && isAll) {
      mockupdate();
    }
    // eslint-disable-next-line
  }, [user, pageno, searchParams]);

  // Sidebar Navigation
  const handlerClick = (e) => {
    setMenuKey(e.key);
    if (
      [
        "createmock",
        "managegroups",
        "questions",
        "manageusers",
        "my_organizations",
        "course"
      ].includes(e.key)
    ) {
      navigate(`/${e.key}`);
    } else if (e.key === "Create Segment") {
      setIsSegment(true);
    } else if (e.key === "dashboard") {
      setIsCatogory(false);
      setIsdata(true);
      setPageno(1);
      setSearchParams({ pageno: 1 });
      setpracticeopen("Tests");
      if (!isAll) {
        mockupdate();
      }
    } else if (e.key !== menuKey) {
      setMenuKey(e.key);
      setPageno(1);
      handleCategory(e.key, user);
    }
    setOpenDwa(false);
  };

  // Search
  const Search = useCallback(
    (val) => {
      const myParam = searchParams.get("pageno");
      getrequest(
        `/searchdetails/${user.subdomain}/${myParam || pageno}/?search=${val}`
      )
        .then((res) => {
          setMockdata(res.data.alldata);
          setIsdata(true);
          setTotalpages(res.data.totalCount);
        })
        .catch(() => notificationmessage("Server Error code-188", "error"));
    },
    [pageno, searchParams, user, notificationmessage]
  );

  // Debouncing for Search function
  useEffect(() => {
    if (searchTimeout.current !== null) {
      clearTimeout(searchTimeout.current);
    }
    if (user && search !== "") {
      searchTimeout.current = setTimeout(() => {
        Search(search);
      }, 1000);
    }
  }, [pageno, search, Search, user]);

  // Search Function
  const searchTestOrMock = (e) => {
    if (searchTimeout.current !== null) {
      clearTimeout(searchTimeout.current);
    }
    setIsdata(false);
    setSearch(e);
    if (e.length > 2) {
      searchTimeout.current = setTimeout(() => {
        Search(e);
      }, 1000);
    } else if (e.length === 0) {
      mockupdate();
    }
  };

  // Edit Test
  const editMock = (item, testType) => {
    navigate("/createmock", {
      state: {
        details: item,
        testtype: testType,
      },
    });
  };

  //Export Data To Excel
  const ExporttoExcel = async (item) => {
    try {
      let arr = [];
      item.users.map((val) => {
        let filtereddata = item.Attempted.filter(
          (it) => it.Email === val.value
        );
        let sum = 0,
          total = 0;
        filtereddata.length > 0 &&
          filtereddata[0].Answers.forEach((element) => {
            sum += Number(element.point);
            total += Number(element.mainpoints);
          });

        if (filtereddata.length > 0) {
          let obj = {
            FullName: filtereddata[0].FullName,
            Email: filtereddata[0].Email,
            Status: "Present",
            Startdate: new Date(filtereddata[0].starttime),
            FeedBackStatus: !filtereddata[0].FeedBack
              ? "Reviewing"
              : "Returned",
            TotalMark: sum,
            GrandTotal: total,
            Percentage: Math.floor((sum / total) * 100),
            Result: filtereddata[0].FeedBack
              ? (sum / total) * 100 > 60
                ? "Pass"
                : "Fail"
              : "",
            FeedBack: filtereddata[0].FeedBack
              ? filtereddata[0].OverAllFeedback
              : "",
            Reviewer: filtereddata[0].FeedBack
              ? filtereddata[0].FeedBackBy
              : "",
            Communication: filtereddata[0].Level
              ? filtereddata[0].Level.Communication
              : "",
            Theory: filtereddata[0].Level ? filtereddata[0].Level.Theory : "",
            Coding: filtereddata[0].Level ? filtereddata[0].Level.Coding : "",
          };
          arr.push(obj);
        } else {
          let obj = {
            FullName: val.label,
            Email: val.value,
            Status: "Absent",
            Startdate: "",
            FeedBackStatus: "",
            TotalMark: 0,
            GrandTotal: 0,
            Percentage: 0,
            Result: "Fail",
            FeedBack: "",
            Reviewer: "",
          };
          arr.push(obj);
        }
        return true;
      });
      const columns = [
        { header: "FullName", key: "FullName" },
        { header: "Email", key: "Email" },
        { header: "Status", key: "Status" },
        { header: "Startdate", key: "Startdate" },
        { header: "FeedBack", key: "FeedBackStatus" },
        { header: "Total", key: "GrandTotal" },
        { header: "ObtainMark", key: "TotalMark" },
        { header: "Percentage", key: "Percentage" },
        { header: "Communication", key: "Communication" },
        { header: "Theory", key: "Theory" },
        { header: "Coding", key: "Coding" },
        { header: "Result", key: "Result" },
        { header: "OverAllFeedBack", key: "FeedBack" },
        { header: "FeedBackBy", key: "Reviewer" },
      ];
      const workbook = new Excel.Workbook();
      const worksheet = workbook.addWorksheet(item.MockTitle);
      worksheet.columns = columns;
      worksheet.getRow(1).font = { bold: true };
      worksheet.columns.forEach((column) => {
        column.width =
          column.header !== "Email"
            ? column.header.length + 10
            : column.header.length + 20;
        column.alignment = { horizontal: "center" };
      });
      arr.forEach((singleData) => {
        worksheet.addRow(singleData);
      });
      worksheet.eachRow({ includeEmpty: true }, (row) => {
        // Iterate over each cell in the row
        row.eachCell((cell) => {
          const cellValue = cell.value;

          if (cellValue === "Bad") {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFF0000" },
            };
            cell.font = {
              color: { argb: "FFFFFFFF" },
              bold: true,
            };
          } else if (cellValue === "Ok") {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "00E8D94F" },
            };
            cell.font = {
              color: { argb: "FFFFFFFF" },
              bold: true,
            };
          } else if (cellValue === "Good") {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "00A9ED67" },
            };
            cell.font = {
              bold: true,
            };
          } else if (cellValue === "Excellent") {
            cell.font = {
              color: { argb: "FFFFFFFF" },
              bold: true,
            };
            cell.fill = {
              color: "FFFFFF",
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "002F732F" },
            };
          }
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      });

      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${item.MockTitle}.xlsx`);
    } catch (err) {
      console.log(err.message);
    }
  };

  // Copy The Link
  const shareTheLink = (val) => {
    let url = encodeURIComponent(
      `id=${val.Mockid}&client_id=${
        clientdetails?.isusers ? user?.subdomain : "main"
      }&ref=external`
    );
    navigator.clipboard
      .writeText(`https://skillhubpro.com/?${url}`)
      .then(() => {
        notificationmessage("Copied the Link", "success");
      })
      .catch((error) => {
        notificationmessage("Failed to copy the link", "error");
      });
    handleClose();
  };

  // MockDetails
  function MockDetails(props) {
    return (
      <Modal
        maskClosable={false}
        title={item.MockTitle || item.Test_Name}
        onOk={props.onHide}
        open={props.show}
        centered
        width={780}
        footer={(_, { OkBtn }) => (
          <>
            <OkBtn />
          </>
        )}
        closable={false}
      >
        <h4>Mock Details</h4>
        <div>
          {item !== "" &&
            item.MockData.map((it, ind) => {
              return (
                <div key={ind}>
                  {ind === 0 && (
                    <div
                      className="d-flex justify-content-around"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <div style={{ width: "150px" }}>Main Topics</div>
                      <div style={{ width: "150px" }}>Topics</div>
                      <div style={{ width: "150px" }}>Types</div>
                      <div style={{ width: "150px" }}>Easy</div>
                      <div style={{ width: "150px" }}>Medium</div>
                      <div style={{ width: "150px" }}>Hard</div>
                    </div>
                  )}

                  <div id={styles.mco_table}>
                    <div className={styles.mock_table_data1}>
                      <span id={styles.mock_table_tag}>
                        <label>{it.MainTags}</label>
                      </span>

                      <span id={styles.mock_table_tag}>
                        {item?.Extra?.TopicsWiseTags &&
                        item?.Extra?.TopicsWiseTags[it.MainTags] ? (
                          item?.Extra?.TopicsWiseTags[it.MainTags].map(
                            (ite, ind) => (
                              <label key={ind}>{`${ite}${
                                ind !==
                                item?.Extra?.TopicsWiseTags[it.MainTags]
                                  .length -
                                  1
                                  ? ","
                                  : ""
                              }`}</label>
                            )
                          )
                        ) : Array.isArray(it.Tags) ? (
                          it.Tags?.map((val, ind) => (
                            <label key={ind}>{`${val}${
                              ind !== it.Tags.length - 1 ? "," : ""
                            }`}</label>
                          ))
                        ) : (
                          <label>{it.Tags}</label>
                        )}

                        {/* */}
                      </span>
                      <span id={styles.mock_table_types}>
                        {(it?.MCQ ?? it?.Mcq) > 0 && <label>MCQ</label>}
                        {it.Coding > 0 && <label>Coding</label>}
                        {(it?.Theory ?? it?.Question) > 0 && (
                          <label>Theoretical</label>
                        )}
                      </span>
                      {["Easy", "Medium", "Hard"].map((val, ind) => {
                        return (
                          <span className={styles.mock_table_diff} key={ind}>
                            {(it?.MCQ || it?.Mcq) > 0 && (
                              <span>
                                <label style={{ width: "30px" }}>
                                  {it?.Global?.MCQ?.[val] !== 0 &&
                                  it?.Global?.Mcq?.[val] !== 0
                                    ? `G/${
                                        it?.Global?.MCQ?.[val] ??
                                        it?.Global?.Mcq?.[val]
                                      }`
                                    : "-"}
                                </label>
                                <label style={{ width: "30px" }}>
                                  {it?.Personal?.MCQ?.[val] !== 0 &&
                                  it?.Personal?.Mcq?.[val] !== 0
                                    ? `P/${
                                        it?.Personal?.Mcq?.[val] ??
                                        it?.Personal?.MCQ?.[val]
                                      }`
                                    : "-"}
                                </label>
                              </span>
                            )}

                            {it.Coding > 0 && (
                              <span style={{ position: "relative" }}>
                                <label style={{ width: "30px" }}>
                                  {it["Global"]["Coding"][val] !== 0
                                    ? `G/${it["Global"]["Coding"][val]}`
                                    : "-"}
                                </label>
                                <label style={{ width: "30px" }}>
                                  {it["Personal"]["Coding"][val] !== 0
                                    ? `P/${it["Personal"]["Coding"][val]}`
                                    : "-"}
                                </label>
                              </span>
                            )}
                            {(it?.Theory || it?.Question) > 0 && (
                              <span>
                                <label style={{ width: "30px" }}>
                                  {it?.Global?.Theory?.[val] !== 0 &&
                                  it?.Global?.Question?.[val] !== 0
                                    ? `G/${
                                        it?.Global?.Theory?.[val] ??
                                        it?.Global?.Question?.[val]
                                      }`
                                    : "-"}
                                </label>
                                <label style={{ width: "30px" }}>
                                  {it?.Personal?.Theory?.[val] !== 0 &&
                                  it?.Personal?.Question?.[val] !== 0
                                    ? `P/${
                                        it?.Personal?.Theory?.[val] ??
                                        it?.Personal?.Question?.[val]
                                      }`
                                    : "-"}
                                </label>
                              </span>
                            )}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </Modal>
    );
  }

  // Check the subscription
  const Subscription = () => {
    toast.warning("Subscription Expired", {
      position: "top-right",
      autoClose: 1500,
      closeOnClick: true,
    });
  };

  // Handle Users while Inviting users
  const handleuser = (e) => {
    let find = Users.filter((val) => {
      return e.includes(val.value);
    });

    e.forEach((val) => {
      if (!Users.some((user) => user.value === val)) {
        let obj = {
          label: val.trim(),
          value: val.trim(),
          name: "users",
        };
        find.push(obj);
      }
    });
    setSelecteduser(find);
  };

  // Clone the Test
  const CloneMock = (val) => {
    getrequest(`/clonemock/${user?.subdomain}/${val.Mockid}`)
      .then(() => mockupdate())
      .catch(() => {
        mockupdate();
        notificationmessage("Unable to clone the Test", "error");
      });
  };

  // Assign Test
  const assignTest = (val) => {
    if (Startdate !== "" && endDate !== "") {
      let obj = {
        startdate: Startdate,
        enddate: endDate,
        Test_Name: item.Test_Name,
      };
      postrequest(`/assign/${user?.subdomain}/${val}`, obj)
        .then(() => {
          toast.success("Assigned Successfully", {
            position: "top-center",
            autoClose: 1000,
          });
          notificationmessage("Assigned Successfully", "success");
          mockupdate();
          setIsAll(true);
          setShowadd(false);
          setMenuKey("dashboard");
          setSelecteduser([]);
          setStartdate("");
          setEnddate("");
        })
        .catch(() => {
          mockupdate();
          notificationmessage("Error while assigning Test", "error");
        });
    } else if (Startdate === "" || endDate === "") {
      notificationmessage("Please select the date", "warning");
    }
  };

  // passing createria
  const checkPassedStd = (item) => {
    let sum = 0,
      total = 0;

    let passed = item.Attempted?.filter((user) => {
      sum = 0; // Initialize sum for each user
      total = 0; // Initialize total for each user
      user.Answers?.map((element) => {
        sum += Number(element.point);
        total += Number(element.mainpoints);
        return true;
      });
      let percentage = (sum / total) * 100;

      return percentage >= 60;
    });

    return passed?.length;
  };

  // Warning when subscription expire
  const Warning = () => {
    toast.warning(`You can add upto ${clientdetails?.mockcount} users`, {
      position: "top-right",
      autoClose: 1500,
      closeOnClick: true,
    });
  };

  // Navigate to Check Test
  const navigateHandler = (event, mockData) => {
    event.stopPropagation();
    navigate(`/mockdetails/${mockData.Mockid}`);
  };

  // Date Show
  const convertDateIntoNormal = (inputDateString) => {
    // Create a Date object from the input string
    const date = new Date(inputDateString);

    // Define months array for formatting
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    // Extract the day, month, and year components
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    const formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
  };

  //Fetch All users of Particular Test
  const Getusers = (item) => {
    getrequest(`/getusers/${item?.Mockid}/${user?.subdomain}`)
      .then((res) => {
        let arr = [];
        res.data.length > 0 &&
          res.data.map((item) => {
            let obj = {
              value: item.Email,
              label: item.Name,
              name: item.name === undefined ? "users" : item.name,
            };
            arr.push(obj);
            return true;
          });
        setUsers(arr);
      })
      .catch((err) =>
        notificationmessage("Error while fetching the users code-754", "error")
      );
  };
  // Add users or Invite to Test
  const Addusers = (item) => {
    const Mockid = practiceopen === "Practice" ? item?.Testid : item?.Mockid;
    if (Selecteduser.length > clientdetails?.mockcount) {
      Warning();
    } else {
      let obj = {
        Selecteduser,
        MockData: item,
        subdomain: clientdetails?.isusers ? user?.subdomain : "main",
      };
      setSendingrequest(true);
      postrequest(
        `/deleteuser/${Mockid}/${user?.subdomain}/?practice=${
          practiceopen === "Practice"
        }`,
        obj
      )
        .then((res) => {
          if (practiceopen !== "Practice") {
            mockupdate();
          }
          handleClose();
          setSendingrequest(false);
          // Getusers(item);

          setSelecteduser([]);
          if (res.status === 200) {
            if (practiceopen === "Practice") {
              notificationmessage("Assigned successfully.", "success");
            } else {
              notificationmessage("User added successfully.", "success");
            }
          } else if (res.status === 201) {
            notificationmessage("Subscription Expired", "warning");
          }
        })
        .catch((err) => {
          console.log(err);
          notificationmessage(
            "Server error while adding users. code-858",
            "error"
          );
          setSendingrequest(false);
        });
    }
  };
  // Create new segment
  const createNewSegment = () => {
    postrequest(`/createnewcat/${user?.subdomain}`, {
      newcat: Segment?.label,
    })
      .then((res) => {
        setIsSegment(false);
        mockupdate();
        notificationmessage("New Segement created successfully.", "success");
      })
      .catch((err) => {
        notificationmessage("Error while creating createNewSegment", "error");
      });
  };
  const handleSegment = (e) => {
    setSegment(e);
  };
  // For Menu
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "black" : provided.color,
    }),
  };

  // Fetch Users All Available Users while assigning
  const Allusers = () => {
    getrequest(`/allusers/${user?.subdomain}/true`)
      .then((res) => {
        if (res.data.length > 0) {
          setUsers(
            res.data
              .filter((item) => item.name === "group")
              .map((item) => {
                return {
                  value: item.Email,
                  label: item.Name,
                  name: item.name,
                };
              })
          );
        }
      })
      .catch((err) => notificationmessage("Server error code-836", "error"));
  };

  // Input date string
  const convertDateIntoNormal2 = () => {
    // Create a Date object from the input string
    const date = new Date();

    // Extract the day, month, and year components
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formattedDate;
  };

  // Input date string
  const convertDateIntoNormalend = (val) => {
    // Create a Date object from the input string
    const date = new Date(val);
    // Extract the day, month, and year components
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formattedDate;
  };

  // Show skills
  const Skils = ({ val }) => {
    let StoredSkills = [];
    val?.MockData?.map((item) => {
      if (!StoredSkills.includes(item.MainTags)) {
        StoredSkills.push(item.MainTags);
      }
      return true;
    });
    return (
      <>
        {StoredSkills?.map((elee, ind) =>
          // ele?.MainTags.map((elee) =>console.log(elee))
          {
            return (
              <label key={ind}>
                {elee}
                {ind !== StoredSkills?.length - 1 && ","}
              </label>
            );
          }
        )}
      </>
    );
  };

  // Mock Update(Settings/Name/Date)
  const MockUpdate = () => {
    let obj = null;
    if (openSetting) {
      obj = {
        Settings: item.Settings,
      };
    } else if (opendate) {
      obj = {
        startdate: item.MockStart,
        enddate: item.Mockend,
      };
    } else if (openDelete) {
      obj = {
        mockid: item.Mockid,
      };
      postrequest(`/viewmocks/${user?.subdomain}`, obj)
        .then((res) => {
          mockupdate();
          setOpenDelete(false);
          setOpenModal(false);
        })
        .catch((err) => {
          mockupdate();
          setOpenDelete(false);
          setOpenModal(false);
          notificationmessage("Server error. code-920", "error");
        });
    } else {
      obj = {
        MockTitle: item.MockTitle,
      };
    }

    if (openSetting || opendate || openName) {
      postrequest(`/mockupdate/${item.Mockid}`, obj)
        .then((res) => {
          mockupdate();
          notificationmessage("Update Successfully", "success");
          setOpenDate(false);
          setOpenModal(false);
          setOpenSetting(false);
          setOpenName(false);
        })
        .catch(() => {
          notificationmessage("Error while updating", "error");
        });
    }
  };

  const ManageSettings = [
    {
      key: 1,
      label: (
        <div
          onClick={(e) => {
            e.stopPropagation();
            ExporttoExcel(item);
          }}
          className={styles.menuwraper}
        >
          <label>
            <SiMicrosoftexcel />
          </label>
          <label className="mx-2" style={{ cursor: "pointer" }}>
            Download
          </label>
        </div>
      ),
    },
    {
      key: 2,
      label: (
        <div
          onClick={(e) => {
            e.stopPropagation();
            CloneMock(item);
          }}
          className={styles.menuwraper}
        >
          <label>
            <CopyOutlined />
          </label>
          <label className="mx-2" style={{ cursor: "pointer" }}>
            Clone
          </label>
        </div>
      ),
    },
    {
      key: 3,
      label: (
        <div
          onClick={(e) => {
            e.stopPropagation();

            setEdit(true);
          }}
          className={styles.menuwraper}
        >
          <label>
            <IoInformationCircleOutline />
          </label>
          <label className="mx-2" style={{ cursor: "pointer" }}>
            Details
          </label>
        </div>
      ),
    },
    {
      key: 4,
      label: (
        <div
          onClick={(e) => {
            e.stopPropagation();
            setOpenModal(true);
            setOpenSetting(true);
          }}
          className={styles.menuwraper}
          style={{ position: "relative" }}
        >
          <label>
            <SettingOutlined />
          </label>
          <label className="mx-2" style={{ cursor: "pointer" }}>
            Settings
          </label>
        </div>
      ),
    },
    {
      key: 5,
      label: access &&access?.role==="Admin" && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            setOpenModal(true);
            setOpenDelete(true);
          }}
          className={styles.menuwraper}
          style={{ position: "relative" }}
        >
          <label>
            <DeleteOutlined />
          </label>
          <label className="mx-2" style={{ cursor: "pointer" }}>
            Delete
          </label>
        </div>
      ),
    },
    {
      key: 6,
      label: (
        <div
          onClick={(e) => {
            e.stopPropagation();
            setFileUploader(true);
          }}
          className={styles.menuwraper}
          style={{ position: "relative" }}
        >
          <label>
            <FileOutlined />
          </label>
          <label className="mx-2" style={{ cursor: "pointer" }}>
            Attach File
          </label>
        </div>
      ),
    },
  ];

  const items = [
    {
      key: 1,
      label: (
        <div
          onClick={(e) => {
            e.stopPropagation();
            setOpenName(true);
            setOpenModal(true);
          }}
          className={styles.menuwraper}
        >
          <label>
            <FontColorsOutlined />
          </label>
          <label className="mx-2" style={{ cursor: "pointer" }}>
            Change Name
          </label>
        </div>
      ),
    },
    {
      key: 2,
      label: (
        <div
          onClick={(e) => {
            e.stopPropagation();
            setOpenDate(true);
            setOpenModal(true);
          }}
          className={styles.menuwraper}
        >
          <label>
            <CalendarOutlined />
          </label>
          <label className="mx-2" style={{ cursor: "pointer" }}>
            Change Date
          </label>
        </div>
      ),
    },
    {
      key: 3,
      label: (
        <div
          onClick={(e) => {
            e.stopPropagation();
            editMock(item, "OTT");
          }}
          className={styles.menuwraper}
        >
          <label>
            <EditOutlined />
          </label>
          <label className="mx-2" style={{ cursor: "pointer" }}>
            Edit Questions
          </label>
        </div>
      ),
    },
  ];

  const UploadFile = () => {
    let formdata=new FormData()
            Object.values(selectedfiles).forEach((file) => {
  formdata.append("attach", file);
});

// console.log(item,selectedfiles)
setLoading(true)
              postrequest(`/attach_file/${item.Mockid}`,formdata).then(res=>{
                setFileUploader(false)
                setLoading(false)
                setSelectedFiles({})
                mockupdate()
              }).catch(err=>{
                console.log(err)
                setLoading(false)
                setFileUploader(false)
                setSelectedFiles({})
              })

  };


  const DeleteFile=(val)=>{
    setLoading(true)
    postrequest(`/delete_attach/${item.Mockid}`,val).then(res=>{
      setLoading(false)
       setItem(res.data)
      // console.log(res.data)
      mockupdate()
    }).catch(err=>{
      setLoading(false)
      console.log(err)
    
    })
  }

  return (
    <>
      {/* Exta Components */}
      {contextHolder}
      {/* File Upload */}
      <Modal
        open={openfileuploader}
        onCancel={() => {
          setFileUploader(false);
          setSelectedFiles({});
        }}
        title="Attach File"
        onOk={() => UploadFile()}
        okButtonProps={{
          loading:loading,
          disabled:loading
        }}
      >
        <input
          type="file"
          accept=".xlsx, .csv"
          id="filesupload"
          style={{ display: "none" }}
          multiple
          onChange={(e) => {
            const filesArray = Array.from(e.target.files);
            setSelectedFiles((prev) => {
              const newFiles = {};
              filesArray.forEach((file) => {
                if (!prev[file.name]) {
                  newFiles[file.name] = file;
                }
              });
              return { ...prev, ...newFiles };
            });
            e.target.value = "";
          }}
        />
        <label className={styles.uploadfiles} htmlFor="filesupload">
          <UploadOutlined /> Upload File
        </label>
        <br />


        {item &&item.Extra&&item.Extra.attachments &&
        <div>
          <label><b>Files</b></label>
          <ol>
          {item.Extra.attachments.map((file,ind)=>{
            return <li>{file.name}    <u
            className="mx-2"
             onClick={()=>DeleteFile(file)}
            style={{ color: "blue", cursor: "pointer" }}
          >
            Delete
          </u></li>
          })}
          </ol>
         
          </div>
          }

        {Object.keys(selectedfiles).length > 0 && (
          <label>
            <b>Selected Files</b>
          </label>
        )}
        <ol>
          {Object.values(selectedfiles).map((val, ind) => {
            return (
              <li>
                {val.name}{" "}
                <u
                  className="mx-2"
                  onClick={() => {
                    let duplicate = { ...selectedfiles };

                    delete duplicate[val.name];
                    setSelectedFiles(duplicate);
                  }}
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  Delete
                </u>
              </li>
            );
          })}
        </ol>
      </Modal>
      {/* Assign Test */}
      <Modal
        open={showadd}
        onCancel={() => setShowadd(false)}
        title={item.Test_Name}
        centered
        width={739}
        okText="Submit"
        onOk={() => assignTest(item.Testid)}
        maskClosable={false}
      >
        <label className={styles.headings}>Edit Name :</label>
        <br />
        <input
          className={styles.titlechangeinput}
          onChange={(e) => setItem({ ...item, Test_Name: e.target.value })}
          value={item.Test_Name}
        />

        <br />
        <div className="d-flex">
          <div>
            <label className={styles.headings}>Start Date :</label>
            <br />
            <input
              type="datetime-local"
              className={styles.datechangeinput}
              onChange={(e) => setStartdate(e.target.value)}
              min={convertDateIntoNormal2()}
            />
          </div>
          <div className="mx-5">
            <label className={styles.headings}>End Date :</label>
            <br />
            <input
              type="datetime-local"
              className={styles.datechangeinput}
              onChange={(e) => setEnddate(e.target.value)}
            />
          </div>
        </div>
      </Modal>

      {/* Change Settings/Name/Date */}
      <Modal
        open={openModal}
        title={
          openSetting
            ? "Change Setting"
            : opendate
            ? "Change Date"
            : openDelete
            ? "Delete Test"
            : "Change Title"
        }
        maskClosable={false}
        onCancel={() => {
          setOpenDate(false);
          setOpenModal(false);
          setOpenSetting(false);
          setOpenName(false);
          setOpenDelete(false);
        }}
        onOk={() => MockUpdate()}
        closable={false}
        okText={"Submit"}
      >
        {openSetting && (
          <div>
            <div className="d-flex justify-content-between">
              <div className={styles.lefttogglesetting}>
                <label>Capture User</label>
                <Switch
                  onChange={(e) => {
                    const totalQuantity = item.MockData.reduce(
                      (accumulator, currentItem) => {
                        return accumulator + currentItem.Question;
                      },
                      0
                    );
                    if (totalQuantity > 0 && e === false) {
                      toast.warning(
                        "To proceed with the theory questions, we need to enable the 'Capture User' option."
                      );
                    } else {
                      setItem((val) => ({
                        ...val,
                        Settings: {
                          ...val.Settings,
                          capture_user: e,
                        },
                      }));
                    }
                    if (e === false && totalQuantity === 0) {
                      setItem((val) => ({
                        ...val,
                        Settings: {
                          ...val.Settings,
                          capture_intro: e,
                        },
                      }));
                    }
                  }}
                  checked={item?.Settings?.capture_user}
                />
              </div>
              <div className={styles.lefttogglesetting}>
                <label>Capture Screen</label>
                <Switch
                  onChange={(e) => {
                    setItem((val) => ({
                      ...val,
                      Settings: {
                        ...val.Settings,
                        capture_screen: e,
                      },
                    }));
                  }}
                  checked={item?.Settings?.capture_screen}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <div className={styles.lefttogglesetting}>
                <label>Can Copy/Paste?</label>
                <Switch
                  onChange={(e) => {
                    setItem((val) => ({
                      ...val,
                      Settings: {
                        ...val.Settings,
                        copypaste: e,
                      },
                    }));
                  }}
                  checked={item?.Settings?.copypaste}
                />
              </div>
              <div className={styles.lefttogglesetting}>
                <label>Auto Feedback</label>
                <Switch
                  onChange={(e) => {
                    setItem((val) => ({
                      ...val,
                      Settings: {
                        ...val.Settings,
                        autofeedback: e,
                      },
                    }));
                  }}
                  checked={item?.Settings?.autofeedback}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <div className={styles.lefttogglesetting}>
                <label>User Intro</label>
                <Switch
                  onChange={(e) => {
                    if (!item?.Settings?.capture_user && e === true) {
                      toast.warning(
                        "To proceed with the introduction, you need to enable the 'Capture User' option."
                      );
                    } else {
                      setItem((val) => ({
                        ...val,
                        Settings: {
                          ...val.Settings,
                          capture_intro: e,
                        },
                      }));
                    }
                  }}
                  checked={item?.Settings?.capture_intro}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <label>Close On Tab</label>
              <div>
                <Switch
                  className="mx-5"
                  checked={item?.Settings?.close_on_tab?.close}
                  onChange={(e) => {
                    setItem((val) => ({
                      ...val,
                      Settings: {
                        ...val.Settings,

                        close_on_tab: {
                          ...val.Settings.close_on_tab,
                          close: e,
                        },
                      },
                    }));
                  }}
                />
                <InputNumber
                  value={item?.Settings?.close_on_tab?.limit ?? 3}
                  onChange={(e) => {
                    setItem((val) => ({
                      ...val,
                      Settings: {
                        ...val.Settings,

                        close_on_tab: {
                          ...val.Settings.close_on_tab,
                          limit: e,
                        },
                      },
                    }));
                  }}
                  disabled={!item?.Settings?.close_on_tab?.close}
                />
              </div>
            </div>
          </div>
        )}
        {opendate && (
          <div className="d-flex flex-wrap gap-lg-5 gap-2">
            <div className="d-flex flex-column">
              <label>Start Date</label>
              <input
                min={convertDateIntoNormal2()}
                value={convertDateIntoNormalend(item.MockStart)}
                type="datetime-local"
                onChange={(e) => {
                  setItem((val) => ({
                    ...val,
                    MockStart: new Date(e.target.value),
                  }));
                }}
              />
            </div>
            <div className="d-flex flex-column">
              <label>End Date</label>
              <input
                min={convertDateIntoNormalend(item.MockStart)}
                value={convertDateIntoNormalend(item.Mockend)}
                type="datetime-local"
                onChange={(e) => {
                  setItem((val) => ({
                    ...val,
                    Mockend: new Date(e.target.value),
                  }));
                }}
              />
            </div>
          </div>
        )}
        {openName && (
          <input
            value={item?.MockTitle}
            style={{ width: "100%", borderRadius: "5px" }}
            onChange={(e) =>
              setItem((val) => ({ ...val, MockTitle: e.target.value }))
            }
          />
        )}
        {openDelete && <label>Are you want to Delete?</label>}
      </Modal>

      <div className={styles.adminMock}>
        {/* Left Side Menu */}
        <div className={styles.leftMockTest}>
          <DashboardSlider
            menuItems={menuItems}
            handleSegment={handleSegment}
            // setMenuKey={setMenuKey}
            activekey={menuKey}
            handlerClick={handlerClick}
            categoryList={categoryList}
            createNewSegment={createNewSegment}
            isSegment={isSegment}
            setIsSegment={setIsSegment}
            customStyles={customStyles}
          />

          {/* Add Segments */}
          <Modal
            title="Create Segment"
            onCancel={() => setIsSegment(false)}
            open={isSegment}
            onOk={() => createNewSegment()}
            centered
            width={500}
            maskClosable={false}
          >
            <CreatableSelect
              options={categoryList}
              onChange={handleSegment}
              placeholder="Enter new segment name"
              isClearable={true}
              closeMenuOnSelect={true}
              isSearchable={true}
              isMulti={false}
              className="Add_user"
              styles={customStyles}
            />
          </Modal>
        </div>
        <div className={styles.rightMockTest}>
          {/* Top Navigation Bar */}
         <LatestNavbar setOpenDwa={setOpenDwa} openDwa={openDwa}/> 
          <div className={styles.create}>
            <div className="d-flex">
              {" "}
              {!isAll && (
                <div className="d-flex">
                  <label
                    className={styles.addtosection}
                    onClick={() =>
                      navigate(`/createmock/?add=${selected_Section}`)
                    }
                    title="Add to this Section"
                  >
                    Create New
                  </label>
                  <div>
                    <span className={styles.filter_label}>Filter : </span>
                    <Radio.Group
                      defaultValue="Tests"
                      buttonStyle="solid"
                      onChange={(val) => {
                        setpracticeopen(val.target.value);
                        setPageno(1);
                        if (val.target.value === "Practice") {
                          let length = mockdata.filter(
                            (ele) => ele?.Settings?.practice_mode ?? false
                          ).length;
                          setTotalpages(length);
                        } else {
                          let length = mockdata.filter(
                            (ele) => !ele?.Settings?.practice_mode ?? false
                          ).length;
                          setTotalpages(length);
                        }
                      }}
                      size="small"
                    >
                      <Radio.Button value="Tests">Tests</Radio.Button>
                      <Radio.Button value="Practice">Practice</Radio.Button>
                    </Radio.Group>
                  </div>
                </div>
              )}
              {isAll && (
                <section>
                  {" "}
                  <input
                    type="text"
                    placeholder={
                      !isAll ? "Search" : "Search by Test Name, Email or Name"
                    }
                    onChange={(e) => searchTestOrMock(e.target.value)}
                    className={styles.searchinput}
                  />
                </section>
              )}
            </div>

            <section className={styles.pagination}>
              <BsArrowLeftCircle
                className={
                  pageno > 1 ? styles.nextprevbtn : styles.disableArrow
                }
                onClick={(e) => {
                  e.stopPropagation();
                  pageno > 1 && setPageno(pageno - 1);
                  setSearchParams({ pageno: pageno - 1 });
                }}
              />
              <label className={styles.activePage}>
                {!isdata ? (
                  <Skeleton.Input active={true} size="small" />
                ) : (
                  <>
                    {" "}
                    <p className={styles.active}>{pageno}</p>/
                    <p className={styles.total}> {Math.ceil(totalpages / 8)}</p>
                  </>
                )}
              </label>
              <BsArrowRightCircle
                className={
                  Math.ceil(totalpages / 8) > pageno
                    ? styles.nextprevbtn
                    : styles.disableArrow
                }
                onClick={(e) => {
                  e.stopPropagation();
                  Math.ceil(totalpages / 8) > pageno && setPageno(pageno + 1);
                  setSearchParams({ pageno: pageno + 1 });
                }}
              />
            </section>
          </div>
          {contextHolder}
          {/* All Test */}
          <div className={styles.mockItem}>
            {isAll && isdata
              ? mockdata.map((ele, index) => {
                  return (
                    <Card
                      key={index}
                      hoverable
                      className={styles.testCard}
                      styles={{ body: { padding: "0 10px 10px 10px" } }}
                      
                      actions={[
                        <ShareAltOutlined
                          key="Invite"
                          title="Invite"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleShow();
                            if (!clientdetails?.issubscribed) {
                              Subscription();
                            } else {
                              Getusers(ele);

                              setItem(ele);
                            }
                          }}
                        />,
                        <Dropdown
                          menu={{
                            items,
                          }}
                          placement="top"
                          arrow
                          trigger={["hover"]}
                          className={styles.menu}
                          overlayStyle={{
                            width: "200px",
                            border: "1px solid #c2c2c2",
                            borderRadius: "5px",
                          }}
                          key={"edit"}
                        >
                          <EditOutlined
                            onClick={(e) => {
                              e.stopPropagation();
                              setItem(ele);
                            }}
                            onMouseEnter={(e) => {
                              e.stopPropagation();
                              setItem(ele);
                            }}
                          />
                        </Dropdown>,
                        <Dropdown
                          menu={{
                            items: ManageSettings,
                          }}
                          placement="topRight"
                          arrow={{
                            pointAtCenter: true,
                          }}
                          trigger={["hover"]}
                          className={styles.menu}
                          overlayStyle={{
                            width: "200px",
                            border: "1px solid #c2c2c2",
                            borderRadius: "5px",
                          }}
                          key={"managesetting"}
                        >
                          <EllipsisOutlined
                            onClick={(e) => {
                              e.stopPropagation();
                              setItem(ele);
                            }}
                            onMouseEnter={(e) => {
                              e.stopPropagation();
                              setItem(ele);
                            }}
                          />
                        </Dropdown>,
                      ]}
                      cover={[
                        <div
                          className="d-flex justify-content-end align-items-center"
                          style={{ padding: "10px 10px" }}
                        >
                          {" "}
                          <IoTimerOutline style={{ fontSize: "15px" }} />
                          <label>{ele.Duration} Min</label>
                        </div>,
                      ]}
                      onClick={(e) => {
                        navigateHandler(e, {
                          Mockid: ele.Mockid,
                        });
                      }}
                    >
                      <Meta title={ele?.MockTitle} />
                      <hr style={{ width: "100%" }} />
                      <div className={styles.details}>
                        <div className={styles.description}>
                          <label>Invites</label>{" "}
                          <label>{ele?.users?.length}</label>
                        </div>
                        <div>
                          <label>Attempted</label>{" "}
                          <label>{ele?.Attempted?.length}</label>
                        </div>
                        <div>
                          <label>Passed</label>{" "}
                          <label>{checkPassedStd(ele)}</label>
                        </div>
                      </div>

                      <div>
                        <div>
                          <label style={{ width: "80px", marginLeft: "5px" }}>
                            Start Date{" "}
                          </label>
                          <label style={{ color: "#87888a" }}>
                            {" "}
                            {convertDateIntoNormal(ele?.MockStart)}
                          </label>
                        </div>
                        <div>
                          <label style={{ width: "80px", marginLeft: "5px" }}>
                            End Date{" "}
                          </label>
                          <label style={{ color: "#87888a" }}>
                            {" "}
                            {convertDateIntoNormal(ele?.Mockend)}
                          </label>
                        </div>
                      </div>
                    </Card>
                  );
                })
              : !isdata && (
                  <>
                    {new Array(8).fill(0).map((_, ind) => {
                      return (
                        <div key={ind} className={styles.testCard}>
                          <div className="d-flex justify-content-between mt-2 mx-1">
                            {" "}
                            <Skeleton.Input size="small" active={true} />
                            <Skeleton.Button active={true} size="small" />
                          </div>
                          <div className="mt-4 mx-1 w-100">
                            <Skeleton.Input
                              style={{ width: "200px" }}
                              active={true}
                            />
                          </div>
                          <div className="mt-4 mx-2  d-flex justify-content-between">
                            <Skeleton.Button
                              size="small"
                              active={true}
                              style={{ width: "80px" }}
                            />
                            <Skeleton.Button
                              size="small"
                              active={true}
                              style={{ width: "80px" }}
                            />
                            <Skeleton.Button
                              size="small"
                              active={true}
                              style={{ width: "80px" }}
                            />
                          </div>
                          <div className="mx-1 mt-2 w-100 d-flex justify-content-between align-items-end">
                            <Skeleton.Input
                              style={{ width: "150px" }}
                              size="small"
                              active={true}
                            />

                            <Skeleton.Button
                              style={{ width: "100px" }}
                              className="mx-2"
                              size="large"
                              active={true}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}

            {IsCatogory ? (
              <>
                {new Array(8).fill(0).map((_, ind) => {
                  return (
                    <div key={ind} className={styles.testCard}>
                      <div className="d-flex justify-content-between mt-2 mx-1">
                        <Skeleton.Input size="small" active={true} />
                        <Skeleton.Button active={true} size="small" />
                      </div>
                      <div className="mt-4 mx-1 w-100">
                        <Skeleton.Input
                          style={{ width: "200px" }}
                          active={true}
                        />
                      </div>
                      <div className="mt-4 mx-1 w-100">
                        <Skeleton.Input
                          style={{ width: "150px" }}
                          size="small"
                          active={true}
                        />
                      </div>
                      <div className="mx-1 w-100 d-flex justify-content-between align-items-end">
                        <Skeleton.Input
                          style={{ width: "150px" }}
                          size="small"
                          active={true}
                        />

                        <Skeleton.Button
                          style={{ width: "100px" }}
                          className="mx-2"
                          size="large"
                          active={true}
                        />
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              !isAll &&
              mockdata
                .filter((ele) =>
                  practiceopen === "Practice"
                    ? ele?.Settings?.practice_mode ?? false
                    : !ele?.Settings?.practice_mode ?? false
                )
                .slice((pageno - 1) * 8, pageno * 8)
                .map((ele, index) => {
                  return (
                    <Card
                      key={index}
                      hoverable
                      className={styles.testCard}
                      styles={{ body: { padding: "0 10px 10px 10px" } }}
                      actions={[
                        <InfoCircleOutlined
                          key="ellipsis"
                          title="Details"
                          onClick={(e) => {
                            e.stopPropagation();

                            setEdit(true);
                            setItem(ele);
                          }}
                        />,
                        <EditOutlined
                          key="edit"
                          title="Edit"
                          onClick={(e) => {
                            e.stopPropagation();
                            editMock(ele, "CATupdate");
                            setItem(ele);
                          }}
                        />,

                        <PlusCircleOutlined
                          key="Invite"
                          title="Assign"
                          onClick={(e) => {
                            e.stopPropagation();

                            if (!clientdetails?.issubscribed ) {
                              Subscription();
                            } else {
                              if (practiceopen !== "Practice") {
                                setShowadd(true);
                              } else {
                                Allusers();
                                handleShow();
                              }

                              setItem(ele);
                            }
                          }}
                        />,
                      ]}
                      cover={[
                        <div
                          className="d-flex justify-content-end align-items-center"
                          style={{ padding: "10px 10px" }}
                        >
                          {" "}
                          <IoTimerOutline style={{ fontSize: "15px" }} />
                          <label>{ele.Duration} Min</label>
                        </div>,
                      ]}
                    >
                      <Meta title={ele?.Test_Name} key={index}/>
                      <hr style={{ width: "100%" }} />
                      <div>
                        <label style={{ width: "80px", marginLeft: "5px" }}>
                          Questions
                        </label>{" "}
                        <label style={{ color: "#87888a" }}>{ele.NoofQ}</label>
                      </div>
                      <div className="d-flex">
                        <label style={{ width: "80px", marginLeft: "5px" }}>
                          Skills
                        </label>
                        <label style={{ color: "#87888a" }}>
                          <Skils val={ele} />
                        </label>
                      </div>
                    </Card>
                  );
                })
            )}

            {/* Add Users */}
            {isdata && mockdata.length === 0 && (
              <div className={styles.errorPage}>
                <Result
                  style={{ margin: "auto" }}
                  extraMargin="auto"
                  status="403"
                  subTitle={`No result found`}
                  subtitleFontSize={32}
                />
              </div>
            )}
            <Modal
              open={show}
              onCancel={() => handleClose()}
              onOk={() => Addusers(item)}
              maskClosable={false}
              title={practiceopen !== "Practice" ? "Add User" : "Select Batch"}
              okText="Submit"
              footer={(_, { OkBtn }) => {
                return !sendingrequest ? (
                  practiceopen !== "Practice" ? (
                    <div className="d-flex justify-content-between">
                      <div
                        className={styles.sharelink}
                        onClick={() => shareTheLink(item)}
                      >
                        <LinkOutlined />{" "}
                        <label className="mx-1" style={{ cursor: "pointer" }}>
                          Copy Link
                        </label>
                      </div>
                      <OkBtn />
                    </div>
                  ) : (
                    <OkBtn />
                  )
                ) : (
                  <span>
                    <LoadingOutlined /> Adding Users... wait
                  </span>
                );
              }}
            >
              <Select
                mode="tags"
                style={{
                  width: "100%",
                  zIndex: "999",
                }}
                onChange={handleuser}
                tokenSeparators={[","]}
                options={Users}
                placeholder={
                  practiceopen !== "Practice"
                    ? "Enter the email with ',' separated"
                    : "Select Batch"
                }
                value={Selecteduser}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
              <label>
                Press{" "}
                <Tag
                  style={{
                    backgroundColor: "rgba(150,150,150,0.06)",
                    fontWeight: "600",
                  }}
                >
                  ,
                </Tag>
                or{" "}
                <Tag
                  style={{
                    backgroundColor: "rgba(150,150,150,0.06)",
                    fontWeight: "600",
                  }}
                >
                  Tab
                </Tag>
                or{" "}
                <Tag
                  style={{
                    backgroundColor: "rgba(150,150,150,0.06)",
                    fontWeight: "600",
                  }}
                >
                  Enter
                </Tag>{" "}
                to separate.
              </label>
            </Modal>
            <MockDetails show={edit} onHide={() => setEdit(false)} />
          </div>
        </div>
      </div>
      {/* Right Profile Menu */}
      <ProfileSlider
        setOpenDwa={setOpenDwa}
        openDwa={openDwa}
        user={user}
        menuItems={menuItems}
        handleSegment={handleSegment}
        setMenuKey={setMenuKey}
        handlerClick={handlerClick}
        categoryList={categoryList}
        createNewSegment={createNewSegment}
        isSegment={isSegment}
        setIsSegment={setIsSegment}
        customStyles={customStyles}
      />
      <ToastContainer />
    </>
  );
};
export default NewAdminHome;
