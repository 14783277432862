import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Table } from "antd";
import { getrequest } from "../../Middleware/managerequest";
import { useDispatch } from "react-redux";
import { EyeOutlined } from "@ant-design/icons";

const Assignments = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [AssignData, setAssignData] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [ind, setInd] = useState(-1);
  const { state } = useLocation();
  const { groupname } = state;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    setLoading(true);
    getrequest(`/home/fetchteam/${id}`)
      .then((res) => {
        if (res.data?.Assignments?.length > 0) {
          setAssignData(res.data.Assignments);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [id, dispatch]);

  const TotalTimeSpendPerTopics = (val) => {
    let TotalTime = 0;
    Object.keys(val).forEach((item) => {
      if (item !== "maintopics") {
        val[item].map((question) => {
          const totalMinutes = question.answer.reduce((total, time) => {
            const startDate = new Date(time.starttime);
            const endDate = new Date(time.endtime);

            // Calculate the difference in milliseconds
            const difference = Math.abs(endDate - startDate);
            // Convert milliseconds to minutes
            const minutes = Math.round(difference / (1000 * 60));

            // Accumulate total minutes
            return total + minutes;
          }, 0);
          TotalTime += Number(totalMinutes);
          return true;
        });
      }
    });
    return ConvertToHours(TotalTime);
  };

  const ConvertToHours = (val) => {
    if (val === 0) {
      return 0;
    } else if (val <= 60) {
      return val + " min(s)";
    } else {
      const hours = Math.floor(val / 60);
      const remainingMinutes = val % 60;
      return `${hours} hr ${remainingMinutes} min(s)`;
    }
  };

  const customeStylesforColumns = (percentage) => {
    return {
      backgroundColor:
        percentage < 50
          ? "#f56c6c"
          : 50 <= percentage && percentage < 100
          ? "#ffde58"
          : percentage >= 100
          ? "#5f9c5f"
          : "initial",
      height: "40px",
      color: 50 <= percentage && percentage < 100 ? "black" : "white",
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
  };

  // Handling Assignments
  const GetSubjectColumns = () => {
    let arr = [];
    if (AssignData.length > 0 && AssignData[0].Data.length > 0) {
      arr = AssignData[0].Data.map((item, index) => {
        return {
          title: item.maintopics,
          width: 100,
          responsive: ["md", "lg"],
          children: [
            {
              title: "Easy",
              dataIndex: "Easy",
              key: "easy",
              width: 30,
              align: "center",
              render: (_, record) => {
                if (record.Data && record.Data.length > 0) {
                  let submited =
                    record.Data[index].Easy.length > 0
                      ? record.Data[index].Easy.filter((it) => {
                          return it.answer.length > 0;
                        })
                      : [];

                  let percentage =
                    (submited.length / (record.Data[index].Easy.length / 2)) *
                    100;

                  return (
                    <div
                      onClick={() => {
                        if (submited.length > 0) {
                          navigate("/assignments", {
                            state: {
                              data: record.Data[index].Easy,
                              maintopics: record.Data[index].maintopics,
                              Diff: "Easy",
                              groupname: groupname,
                              username: record.User_Name,
                              id: id,
                              userid: record.userid,
                              ind: index,
                            },
                          });
                        }
                      }}
                      onMouseEnter={() => {
                        if (submited.length > 0) {
                          setIsHovered(true);
                          setInd({
                            id: record.userid,
                            topicname: record.Data[index].maintopics,
                            Diff: "Easy",
                          });
                        }
                      }}
                      onMouseLeave={() => {
                        setIsHovered(false);
                        setInd(-1);
                      }}
                      style={{
                        cursor: submited.length > 0 ? "pointer" : "initial",
                        ...customeStylesforColumns(percentage),
                      }}
                    >
                      {isHovered &&
                      ind?.id === record.userid &&
                      ind?.topicname === record.Data[index].maintopics &&
                      ind?.Diff === "Easy" ? (
                        <span
                          style={{ cursor: "pointer", fontSize: "20px" }}
                          title="Click To View"
                        >
                          <EyeOutlined />
                        </span>
                      ) : record.Data[index].Easy.length > 0 ? (
                        <>
                          {submited.length}/{record.Data[index].Easy.length}
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                  );
                } else {
                  return <div></div>;
                }
              },
            },
            {
              title: "Medium",
              dataIndex: "Medium",
              key: "medium",
              width: 30,
              align: "center",
              render: (_, record) => {
                if (record.Data && record.Data.length > 0) {
                  let submited =
                    record.Data[index].Medium.length > 0
                      ? record.Data[index].Medium.filter((it) => {
                          return it.answer.length > 0;
                        })
                      : [];
                  let percentage =
                    (submited.length / (record.Data[index].Medium.length / 2)) *
                    100;
                  return (
                    <div
                      onClick={() => {
                        if (submited.length > 0) {
                          navigate("/assignments", {
                            state: {
                              data: record.Data[index].Medium,
                              maintopics: record.Data[index].maintopics,
                              Diff: "Medium",
                              groupname: groupname,
                              username: record.User_Name,
                              id: id,
                              userid: record.userid,
                              ind: index,
                            },
                          });
                        }
                      }}
                      style={{
                        cursor: submited.length > 0 ? "pointer" : "initial",
                        ...customeStylesforColumns(percentage),
                      }}
                      onMouseEnter={() => {
                        if (submited.length > 0) {
                          setIsHovered(true);
                          setInd({
                            id: record.userid,
                            topicname: record.Data[index].maintopics,
                            Diff: "Medium",
                          });
                        }
                      }}
                      onMouseLeave={() => {
                        setIsHovered(false);
                        setInd(-1);
                      }}
                    >
                      {isHovered &&
                      ind?.id === record.userid &&
                      ind?.topicname === record.Data[index].maintopics &&
                      ind?.Diff === "Medium" ? (
                        <span
                          style={{ cursor: "pointer", fontSize: "20px" }}
                          title="Click To View"
                        >
                          <EyeOutlined />
                        </span>
                      ) : record.Data[index].Medium.length > 0 ? (
                        <>
                          {submited.length}/{record.Data[index].Medium.length}
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                  );
                } else {
                  return <div></div>;
                }
              },
            },
            {
              title: "Hard",
              dataIndex: "Hard",
              key: "hard",
              width: 30,
              align: "center",
              render: (_, record) => {
                if (record.Data && record.Data.length > 0) {
                  let submited =
                    record.Data[index].Hard.length > 0
                      ? record.Data[index].Hard.filter((it) => {
                          return it.answer.length > 0;
                        })
                      : [];
                  let percentage =
                    (submited.length / (record.Data[index].Hard.length / 2)) *
                    100;
                  return (
                    <div
                      onClick={() => {
                        if (submited.length > 0) {
                          navigate("/assignments", {
                            state: {
                              data: record.Data[index].Hard,
                              maintopics: record.Data[index].maintopics,
                              Diff: "Hard",
                              groupname: groupname,
                              username: record.User_Name,
                              id: id,
                              userid: record.userid,
                              ind: index,
                            },
                          });
                        }
                      }}
                      style={{
                        cursor: submited.length > 0 ? "pointer" : "initial",
                        ...customeStylesforColumns(percentage),
                      }}
                      onMouseEnter={() => {
                        if (submited.length > 0) {
                          setIsHovered(true);
                          setInd({
                            id: record.userid,
                            topicname: record.Data[index].maintopics,
                            Diff: "Hard",
                          });
                        }
                      }}
                      onMouseLeave={() => {
                        setIsHovered(false);
                        setInd(-1);
                      }}
                    >
                      {isHovered &&
                      ind?.id === record.userid &&
                      ind?.topicname === record.Data[index].maintopics &&
                      ind?.Diff === "Hard" ? (
                        <span
                          style={{ cursor: "pointer", fontSize: "20px" }}
                          title="Click To View"
                        >
                          <EyeOutlined />
                        </span>
                      ) : record.Data[index].Hard.length > 0 ? (
                        <>
                          {submited.length}/{record.Data[index].Hard.length}
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                  );
                } else {
                  return <div></div>;
                }
              },
            },
            {
              title: "Time Spend",
              dataIndex: "time_spend",
              key: "time_spend",
              width: 50,
              align: "center",
              render: (_, record) => {
                return TotalTimeSpendPerTopics(record.Data[index]);
              },
            },
          ],
        };
      });
    }
    return arr ?? [];
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "User_Name",
      key: "name",
      width: 50,
      fixed: "left",
      align: "center",
      responsive: ["md"],
    },
    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
      align: "center",
      width: 50,
      fixed: "left",
      responsive: ["md", "lg"],
      render: (text, record) => {
        return <label>{record.progress}%</label>;
      },
    },
    {
      title: "Total Time Spend",
      dataIndex: "Total_Time_Spend",
      key: "TotalTimeSpend",
      width: 50,
      align: "center",
      fixed: "left",
      responsive: ["md", "lg"],
      render: (text, record) => {
        return <label>{ConvertToHours(record.Total_Time_Spend)} </label>;
      },
      sorter: (a, b) => a.Total_Time_Spend - b.Total_Time_Spend,
    },
    ...GetSubjectColumns(),
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={AssignData}
        bordered
        size="small"
        loading={loading}
        scroll={{
          x: "calc(700px + 50%)",
          y: "58vh",
        }}
      />
    </div>
  );
};

export default Assignments;
