import React, { useState, useEffect } from "react";
import { postrequest } from "../../../Middleware/managerequest";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import { replaceEducation } from "../../../../store/EducationSlice";

const EditEduModal = ({ show, setShow, editEdu }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Data);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    id: 1,
    year: "",
    CurrentlyAppearing: false,
  });

  const handleClose = () => setShow(false);

  const handleSave = () => {
    postrequest(
      `/api/v1/user/personal_details/${user?.Email}/?sec=education&&operation=edit&&ind=${formData.id}`,
      formData
    )
      .then((res) => {
        dispatch(replaceEducation(res.data.EducationDetails));
      })
      .catch((err) => {
        console.log(err);
      });
    handleClose();
  };

  useEffect(() => {
    if (editEdu) {
      setFormData({ ...editEdu });
    }
  }, [editEdu]);


  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Education Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
           

            <Form.Group controlId="degree">
              <Form.Label>Degree</Form.Label>
              <Form.Control
                type="text"
                name="degree"
                value={formData?.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="university">
              <Form.Label>University</Form.Label>
              <Form.Control
                type="text"
                name="university"
                value={formData?.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="yearCompleted">
              <Form.Label>Year Completed</Form.Label>
              <Form.Control
                type="text"
                name="yearCompleted"
                value={formData?.year}
                onChange={(e) =>
                  setFormData({ ...formData, year: e.target.value })
                }
                disabled={formData?.CurrentlyAppearing}
              />
            </Form.Group>

            <Form.Group controlId="currentlyAppearing">
              <Form.Check
                type="checkbox"
                label="Currently Appearing"
                checked={formData?.CurrentlyAppearing}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    CurrentlyAppearing: !formData.CurrentlyAppearing,
                  });
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleSave(formData)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditEduModal;
