// Import createSlice from Redux Toolkit
import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  loginModal: false,
};

// Create ToolFlagsSlice slice
const ToolFlagsSlice = createSlice({
  name: 'ToolFlags',
  initialState,
  reducers: {
    // Reducer to set login modal visibility
    setLoginModalVisibility(state, action) {
      state.loginModal = action.payload;
    },
  },
});

// Export action creator
export const { setLoginModalVisibility } = ToolFlagsSlice.actions;

// Export reducer
export default ToolFlagsSlice.reducer;
