import { postrequest } from "../Middleware/managerequest";

let videoElement = document.createElement("video");
videoElement.muted = true;
let fd = new FormData();
let caminterval = null;
let chunks = [];
let mediaRecorder;

const videoConstraints = {
  video: {
    width: { ideal: 640 }, // Set the maximum width you desire
    height: { ideal: 500 }, // Set the maximum height you desire
  },
  audio: true,
};

function StartCamera() {
  return new Promise((resolve, reject) => {
    if (!videoElement || !(videoElement instanceof HTMLVideoElement)) {
      reject(new Error("Video element not found or invalid."));
      return;
    }

    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      reject({
        error: "getUserMedia not supported on this browser.",
        message: "getUserMedia is not available.",
      });
      return;
    }

    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const cameras = devices.filter(
          (device) => device.kind === "videoinput"
        );

        if (cameras.length > 0) {
          const finalVideoConstraints =
            window.innerWidth < 768 ? { video: true, audio: true } : videoConstraints;
     console.log(finalVideoConstraints)
          navigator.mediaDevices
            .getUserMedia(finalVideoConstraints)
            .then((stream) => {
              videoElement.srcObject = stream;
              videoElement.play();
              resolve(true);
            })
            .catch((error) => {
              reject({
                error: error,
                message: "Please give access to Camera and Microphone",
              });
            });
        } else {
          reject({
            error: "Camera is not supported.",
            message: "No camera available.",
          });
        }
      })
      .catch((err) => {
        reject({ error: "Error accessing media devices.", message: err });
      });
  });
}


function captureScreenshot() {
  return new Promise((resolve, reject) => {
    if (!videoElement) {
      reject(new Error("Video element not found."));
      return;
    }
    const canvas = document.createElement("canvas");
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    const context = canvas.getContext("2d");
    context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

    canvas.toBlob(
      (blob) => {
        if (!blob) {
          reject(new Error("Failed to create Blob from canvas data."));
          return;
        }
        let qno = localStorage.getItem("qno") ?? 1;
        let difficulty = localStorage.getItem("diff") ?? "Test";
        let random = Math.floor(Math.random() * 9999);
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed
        const day = date.getDate().toString().padStart(2, "0");
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");

        const formattedDate = `${day}-${month}-${year}-${hours}-${minutes}-${seconds}`;

        const file = new File(
          [blob],
          `cam_${difficulty}_${random}_${qno}_${formattedDate}.webp`,
          {
            type: "image/webp",
          }
        );

        fd.append("myimage", file);
        resolve(true);
      },
      "image/webp",
      0.7
    );

    resolve(true);
  });
}

function captureVideo() {
  return new Promise((resolve, reject) => {
    if (!videoElement) {
      reject(new Error("Video element not found."));
      return;
    }
    mediaRecorder = new MediaRecorder(videoElement.srcObject);
    mediaRecorder.ondataavailable = (event) => {
      chunks.push(event.data);
    };
    mediaRecorder.onstart = () => {
      resolve(true);
    };
    mediaRecorder.start();
    mediaRecorder.onerror = (error) => {
      reject(error);
    };
  });
}

function stopVideo() {
  return new Promise((resolve, reject) => {
    if (!mediaRecorder) {
      reject(new Error("MediaRecorder not found."));
      return;
    }
    mediaRecorder.onstop = () => {
      const blob = new Blob(chunks, { type: "video/webm" });

      let random = Math.floor(Math.random() * 9999);
      const date = new Date();

      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");

      const formattedDate = `${day}-${month}-${year}-${hours}-${minutes}-${seconds}`;

      const file = new File(
        [blob],
        `cam_Test_${random}_1_${formattedDate}.mp4`,
        {
          type: "video/mp4",
        }
      );
      fd.append("myimage", file);
      chunks = []; // Clear chunks array

      resolve(fd);
      fd = new FormData();
    };
    mediaRecorder.onerror = (error) => {
      reject(error);
    };
    mediaRecorder.stop();
  });
}

function StartScreenShots(interval) {
  return new Promise((resolve, reject) => {
    caminterval = setInterval(captureScreenshot, interval || 5000);
    if (caminterval) {
      resolve(true);
    } else {
      reject("Failed to start capturing screenshots.");
    }
  });
}

function stopScreenshots() {
  return new Promise((resolve, reject) => {
    clearInterval(caminterval);
    if (fd) {
      resolve(fd);
    } else {
      reject(new Error("FormData is not available."));
    }
    fd = new FormData();
  });
}

function StopCamera() {
  return new Promise((resolve, reject) => {
    if (videoElement) {
      videoElement.pause();
      videoElement.srcObject.getTracks().forEach((track) => {
        track.stop();
      });
      resolve(true);
    } else {
      reject(false);
    }
  });
}
let screen = document.createElement("video");
// const recordedChunks = [];
//eslint-disable-next-line
let screenRecorder;
let screenInterval = null;
// let isscreenactive=null

const startRecording = async () => {
  try {
    const stream = await navigator.mediaDevices.getDisplayMedia({
      video: true,
    });
    screen.srcObject = stream;
    screenRecorder = new MediaRecorder(stream, {
      mimeType: "video/webm",
    });
    await new Promise((resolve) => {
      screen.onloadedmetadata = () => {
        resolve(true);
      };
    });
    stream.getTracks().forEach((track) => {
      track.addEventListener("ended", handleScreenStopped);
    });

    screen.play();
    let setting = stream.getTracks()[0].getSettings().displaySurface;
    if (setting === "monitor") {
      localStorage.setItem("isshared", true);
      return true;
    } else {
      stopScreenSharing(stream);
      return false;
    }
  } catch (error) {
    //  console.log(error)
    return false;
  }
};

function startScreenshot() {
  return new Promise((resolve, reject) => {
    if (!screen) {
      reject(new Error("Video element not found."));
      return;
    }
    const canvas = document.createElement("canvas");
    canvas.width = screen.videoWidth;
    canvas.height = screen.videoHeight;
    const context = canvas.getContext("2d");
    context.drawImage(screen, 0, 0, canvas.width, canvas.height);
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          reject(new Error("Failed to create Blob from canvas data."));
          return;
        }

        let random = Math.floor(Math.random() * 9999);
        let qno = localStorage.getItem("qno") ?? 1;
        let difficulty = localStorage.getItem("diff") ?? "Test";
        const date = new Date();

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed
        const day = date.getDate().toString().padStart(2, "0");
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");

        const formattedDate = `${day}-${month}-${year}-${hours}-${minutes}-${seconds}`;
        const file = new File(
          [blob],
          `screen_${difficulty}_${random}_${qno}_${formattedDate}.webp`,
          {
            type: "image/webp",
          }
        );

        fd.append("myimage", file);
        context.clearRect(0, 0, canvas.width, canvas.height);
        resolve(true);
      },
      "image/webp",
      0.7
    );

    resolve(true);
  });
}

const StartTakingScreenshots = (interval) => {
  return new Promise((resolve, reject) => {
    screenInterval = setInterval(startScreenshot, interval || 5000);
    if (screenInterval) {
      resolve(true);
    } else {
      reject("Failed to start capturing screenshots.");
    }
  });
};

const StopTakingScreenshots = () => {
  clearInterval(screenInterval);
};

const handleScreenStopped = () => {
  localStorage.setItem("isshared", false);
};

const stopScreenSharing = () => {
  if (screen && screen.srcObject) {
    const tracks = screen.srcObject.getTracks();
    tracks.forEach((track) => {
      track.removeEventListener("ended", handleScreenStopped);
      track.stop();
    });
    screen.srcObject = null;
    screen = null;
    screenRecorder = null;
  }
};
const SendScreenShots = (val) => {
  const currentUrl = window.location.href;
  const parsedUrl = new URL(currentUrl);
  const pathnameSegments = parsedUrl.pathname
    .split("/")
    .filter((segment) => segment !== "");
  let username = sessionStorage.getItem("Email");
  postrequest(
    `/user/practice_image/${pathnameSegments[2]}/${username}/${pathnameSegments[3]}`,
    val
  )
    .then((res) => {
      fd = new FormData();
    })
    .catch((err) => console.log(err));
};
let startsendinterval = null;
const StartSendingScreenshots = async (interval) => {
  return new Promise((resolve, reject) => {
    startsendinterval = setInterval(
      () => SendScreenShots(fd),
      interval || 5000
    );
    if (startsendinterval) {
      resolve(true);
    } else {
      reject("Failed to start capturing screenshots.");
    }
  });
};

const StopSendingScreenshots = () => {
  clearInterval(startsendinterval);
};

export {
  StartCamera,
  StartScreenShots,
  stopScreenshots,
  captureVideo,
  stopVideo,
  StopCamera,
  // Screen Functions
  startRecording,
  stopScreenSharing,
  StartTakingScreenshots,
  StopTakingScreenshots,
  StartSendingScreenshots,
  StopSendingScreenshots,
};
