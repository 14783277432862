import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import styles from "./questions.module.css";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { FcInfo } from "react-icons/fc";
import { postrequest } from "../../Middleware/managerequest";
import { Popconfirm} from "antd";
const CopyQuestions = ({
  editdata,
  handleModal,
  topics,
  maintopics,
}) => {
    
      const {user}=useSelector((state=>state.Data))
     
  const [difficulty, setDifficulty] = useState(editdata.level);
  const [Options, setOptions] = useState({
    opt1: editdata.opt1,
    opt2: editdata.opt2,
    opt3: editdata.opt3,
    opt4: editdata.opt4,
  });
  const [answer, setAnswer] = useState(editdata.ans);
  const [question, setQuestion] = useState(editdata.qstn);

  const [labels, setLabels] = useState(() => {
    let topics = [];
    editdata?.concept?.map((item) => {
      let obj = {
        label: item,
        value: item,
      };
      topics.push(obj);
      return true;
    });
    return topics;
  });
  const [maintopic, setMainTopic] = useState(
    editdata.maintopics !== undefined
      ? { label: editdata.maintopics[0], value: editdata.maintopics[0] }
      : ""
  );

  const handleTopics = (e) => {
    setLabels(e);
  };

  const handleMainTopics = (e) => {
    setMainTopic(e);
  };
  const handleOptions = (e) => {
    setOptions({ ...Options, [e.target.name]: e.target.value });
  };

  const Submit = () => {
    let arr = [],
      tags = [];
    labels.map((item) => {
      if (item.__isNew__) {
        arr.push(item);
      }
      tags.push(item.value);
      return true;
    });
    let maint = [];
    //     maintopic.map(item=>{
    // maint.push(item.value)
    // return true
    //     })
    maint.push(maintopic.value);

    let obj = {};
    if (editdata.type === "Mcq") {
      obj = {
        newtopics: arr,
        details: {
          type: editdata.type,
          qstn: question,
          point: difficulty === "Easy" ? 1 : difficulty === "Medium" ? 2 : 3,
          ...Options,
          ans: answer,
          level: difficulty,
          concept: tags,
          maintopics: maint,
          qid: editdata.qid,
          attachments:editdata.attachments
        },
      };
    } else if (editdata.type === "Question") {
      obj = {
        newtopics: arr,
        details: {
          type: editdata.type,
          qstn: question,
          point: difficulty === "Easy" ? 2 : difficulty === "Medium" ? 3 : 4,
          level: difficulty,
          concept: tags,
          maintopics: maint,
          qid: editdata.qid,
          attachments:editdata.attachments
        },
      };
    } else {
      obj = {
        newtopics: arr,
        details: {
          type: editdata.type,
          qstn: question,
          point: difficulty === "Easy" ? 3 : difficulty === "Medium" ? 4 : 5,
          level: difficulty,
          concept: tags,
          TestCase: editdata.TestCase,
          maintopics: maint,
          qid: editdata.qid,
          PreInputs:editdata.PreInputs,
          Outputstocheck:editdata.Outputstocheck,
          attachments:editdata.attachments
        },
      };
    }

    postrequest(`/clone/${user?.subdomain}`, obj)
      .then((res) => {
      
      
      if(res.status===201){
            handleModal(true,"duplicate");
      }
      else{
           handleModal(true)
           
      }
    
      })
      .catch((err) =>
        toast.warning("server error", {
          position: "top-right",
          autoClose: 1500,
        })
      );
  };

  return (
    <div>
     
      <ToastContainer />
      <Modal
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModal}
        backdrop="static"
      >
        <Modal.Header
          closeButton
          className="d-flex justify-content-between"
          style={{ width: "100%" }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            Copy To Personal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="d-flex mb-3 mx-3">
              <label className={styles.titles}>Question :</label>{" "}
              <textarea
                className={styles.example}
                cols={82}
                rows={5}
                value={question?.question}
                name="question"
                onChange={(e) =>
                  setQuestion({
                    ...question,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </div>
            {editdata?.type === "Coding" && (
              <div className="d-flex align-items-start mx-3 mb-3">
                <label className={styles.titles}>Example :</label>{" "}
                <textarea
                  className={styles.example}
                  cols={82}
                  rows={5}
                  value={question?.example}
                  name="example"
                  onChange={(e) =>
                    setQuestion({
                      ...question,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            )}
            <div className="d-flex mx-3 mb-3">
              <label className={styles.titles}>Main Topic :</label>
              <Select
                options={maintopics}
                placeholder="Search Concept"
                value={maintopic}
                onChange={handleMainTopics}
                isClearable={true}
                isSearchable={true}
                className={styles.edit_select}
              />
            </div>
            <div className="d-flex mx-3">
              <label className={styles.titles}>Topics :</label>
              <CreatableSelect
                options={topics}
                placeholder="Search Concept"
                value={labels}
                onChange={handleTopics}
                isClearable={true}
                isSearchable={true}
                className={styles.edit_select}
                isMulti
              />
            </div>
            <div className="d-flex mt-3 mx-3 align-items-center">
              <label className={styles.titles}>Difficulty :</label>
              <div className={styles.difficultyoptions}>
                <span className="d-flex align-items-center">
                  <input
                    type="radio"
                    name="level"
                    className={styles.radiobutton}
                    id="level1"
                    defaultChecked={editdata.level === "Easy"}
                    value={"Easy"}
                    onChange={(e) => setDifficulty(e.target.value)}
                  />
                  <label
                    className="mx-2"
                    htmlFor="level1"
                    style={{ cursor: "pointer" }}
                  >
                    Easy
                  </label>
                </span>
                <span className="d-flex align-items-center">
                  <input
                    type="radio"
                    name="level"
                    className={styles.radiobutton}
                    id="level2"
                    defaultChecked={editdata.level === "Medium"}
                    value={"Medium"}
                    onChange={(e) => setDifficulty(e.target.value)}
                  />
                  <label
                    className="mx-2"
                    htmlFor="level2"
                    style={{ cursor: "pointer" }}
                  >
                    Medium
                  </label>
                </span>
                <span className="d-flex align-items-center">
                  <input
                    type="radio"
                    name="level"
                    className={styles.radiobutton}
                    id="level3"
                    defaultChecked={editdata.level === "Hard"}
                    value={"Hard"}
                    onChange={(e) => setDifficulty(e.target.value)}
                  />
                  <label
                    className="mx-2"
                    htmlFor="level3"
                    style={{ cursor: "pointer" }}
                  >
                    Hard
                  </label>
                </span>
              </div>
            </div>
            {editdata.type === "Mcq" && (
              <div className="d-flex mx-3 mt-3">
                <label className={`${styles.titles} mt-3`}>Options :</label>
                <div className={styles.mcqoptions}>
                  {[Options.opt1, Options.opt2, Options.opt3, Options.opt4].map(
                    (item, index) => {
                      return (
                        <div
                          key={index}
                          className="mt-3 d-flex align-items-center"
                        >
                          <input
                            type="radio"
                            name="options"
                            value={Options[`opt${index + 1}`]}
                            defaultChecked={item === editdata.ans}
                            className={styles.radiobutton}
                            onChange={(e) => setAnswer(e.target.value)}
                          />
                          <input
                            className="mx-3"
                            defaultValue={item}
                            name={`opt${index + 1}`}
                            placeholder={`option ${index + 1}`}
                            onChange={(e) => handleOptions(e)}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <div>
            <FcInfo />
            <label style={{ fontSize: "15px" }}>
              Feel free to make any edits before copying it to your personal
              question bank.
            </label>
          </div>
          <Popconfirm
            title="Copy the Question"
            description="Are you sure to copy this question ?"
            okText="Copy"
            cancelText="No"
            onConfirm={() => Submit()}
          >
            <Button  variant="outline-success">
              Copy To Personal
            </Button>
          </Popconfirm>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CopyQuestions;
