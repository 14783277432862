import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getrequest, postrequest } from "../Middleware/managerequest";
import Editor from "@monaco-editor/react";
import { useSelector } from "react-redux";
import languagedata from "../../utils/language.json";

import {
  LoadingOutlined,
  GlobalOutlined,
  LockOutlined,
  CloudSyncOutlined,
  JavaScriptOutlined,
  Html5Outlined,
  JavaOutlined,
  PythonOutlined,
  MenuOutlined,
  CodeOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { MdOutlineCloudDone } from "react-icons/md";
import { FaCss3Alt } from "react-icons/fa";
import { GrMysql } from "react-icons/gr";
import { toast, ToastContainer } from "react-toastify";
import styles from "./practice.module.css";
// import { TestCaseData } from "../User/Mock/TestCaseData";
import {
  
  Select,
  Modal,
 
  Result,
  Popconfirm,
  Image,
  Drawer,
} from "antd";


let htmlcode = `
<!DOCTYPE html>
<html>
  <head>
    <link rel="stylesheet" href="style.css" />
  </head>
  <body>
    
      <script src="script.js"></script>
  </body>
</html>`;

const setIcons = {
  html: <Html5Outlined title="index.html" />,
  css: <FaCss3Alt title="styles.css" />,
  js: <JavaScriptOutlined title="index.js" />,
  java: <JavaOutlined title="main.java" />,
  py: <PythonOutlined title="main.py" />,
  sql: <GrMysql title="query.sql" />,
};

const Practice_Mode = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { qid, gid, ind } = useParams();
  const typingTimerRef = useRef(null);
  const stopwatch = useRef(null);
  const Sendingrequest = useRef(null);
  const [saving, setSaving] = useState(false);
  const { user, clientdetails } = useSelector((state) => state.Data);
  const [starttime, setStarttime] = useState(new Date());
  const [isTyping, setIsTyping] = useState(false);
  const [data, setData] = useState([]);
  const [editor, setEditor] = useState(null);
  const [openWebview, setOpenWebView] = useState(false);
  const [difficulty, setDiffuculty] = useState("Easy");
  const [selected_question_index, setSelected_question_index] = useState(0);
  const [issubmitted, setisSubmitted] = useState(false);
  const [Typing, setTyping] = useState(false);
  const [openwarning, setOpenWarning] = useState({
    inactive: false,
    isonline: true,
    isscreenshared: true,
  });
  const [SelectedQuestion, setSelectedQuestion] = useState(null);
  const [SelectedLanguage, setSelectedLanguage] = useState({
    language: "javascript",
    extension: "js",
  });
  const [SelectedFile, setSelectedFile] = useState({
    language: SelectedLanguage?.language,
    extension: SelectedLanguage?.extension,
  });
  const [FilesData, setFilesData] = useState({
    css: "",
    html: "",
    markdown: "",
  });
  const [time, setTime] = useState(15);
 const [loading,setLoading]=useState(true)
  const [Output, setOutput] = useState("");
  const [CompilerStatus, setCompilerStatus] = useState({
    isrunning: false,
    Isinfinite: false,
    openconsole: false,
  });
  // Fetch AssignMents Data

  const Fetcher = (Qid, val, diff) => {

    getrequest(`/user/practice/${gid}/${user?.Email}/${ind}`)
      .then((res) => {
        setLoading(false)
        setData(() => res.data);
        let flattenedArray = res.data?.flatMap((level) =>
          [].concat(level?.Easy, level?.Medium, level?.Hard)
        );

        let findQuestion = [];
        if (val) {
          let flattenedArray2 = res.data?.flatMap((level) =>
            [].concat(level[difficulty])
          );
          let ind = flattenedArray2?.findIndex((item) => item.qid === Qid);
          if (ind !== flattenedArray2?.length - 1) {
            let find = flattenedArray2[ind + 1];
            findQuestion = find;
            setSelected_question_index(ind + 1);
          } else {
            setSelected_question_index(ind + 1);
            findQuestion = flattenedArray?.find((item) => item.qid === Qid);
          }
          setSelectedFile({ language: SelectedLanguage.language, extension: SelectedLanguage.extension });
        } else {
          let ind = flattenedArray
            ?.filter((item) => item.level === difficulty)
            .findIndex((item) => item.qid === Qid);

          setSelected_question_index(ind);
          findQuestion = flattenedArray?.find((item) => item.qid === Qid);
          setSelectedFile({
            language: SelectedFile?.language,
            extension: SelectedFile?.extension,
          });
        }

        setSelectedQuestion(findQuestion);
        localStorage.setItem("qno", findQuestion?.qid);
        localStorage.setItem("diff", findQuestion?.level);
        setisSubmitted(false);

        setDiffuculty(diff ?? findQuestion?.level);
        if (findQuestion?.answer?.length > 0) {
          const { markdown, ...rest } =
            findQuestion?.answer[findQuestion?.answer?.length - 1]?.code;

          setFilesData((val) => ({
            ...rest,

            markdown: findQuestion?.qstn?.question,
          }));
        } else {
          setFilesData((val) => ({
            ...val,
            [SelectedLanguage.language]: "",
            markdown: findQuestion?.qstn?.question,
          }));
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    let isshared = localStorage.getItem("isshared");
    if (isshared === "false" || isshared === null) {
      setOpenWarning((val) => ({ ...val, isscreenshared: false }));
    } else if (user && data?.length === 0) {
      let question_number = localStorage.getItem("qno");
      Fetcher(question_number || qid, false);
    }
    // eslint-disable-next-line
  }, [user]);

  // Submit on every 3 min
  const SendRequest = (val, qid, value, diff) => {
    postrequest(`/user/practice/${gid}/${user?.Email}`, val)
      .then((res) => {
        let ishared = localStorage.getItem("isshared");
        if (ishared === "false" || ishared === null) {
          setOpenWarning((val) => ({ ...val, isscreenshared: false }));

          return true;
        }
        setSaving(false);
        Fetcher(qid, value, diff);
        if (value) {
          toast.success("Saved Successfully", {
            position: "top-center",
            autoClose: 1000,
          });
        }
        setTyping(false);
        setStarttime(new Date());
      })
      .catch((err) => {
        let ishared = localStorage.getItem("isshared");
        setSaving(false);
        if (ishared === "false" || ishared == null) {
          setOpenWarning((val) => ({ ...val, isscreenshared: false }));
          return true;
        }

        setTyping(false);
        setStarttime(new Date());
        console.log(err);
      });
  };

  const Sync = (question, val, qid, diff) => {
    setSaving(true);

    if (SelectedQuestion) {
      let obj = {
        qid: question?.qid ?? SelectedQuestion?.qid,
        topicindex: Number(ind),
        starttime: starttime,
        difficulty: question.level ?? SelectedQuestion?.level,
        answer: FilesData,
        submit: val,
        language: SelectedLanguage,
      };
      SendRequest(obj, qid || SelectedQuestion?.qid, val, diff);
    }
  };

  const HandleMenuSelect = (e) => {
    let flattenedArray = data.flatMap((level) =>
      [].concat(level.Easy, level.Medium, level.Hard)
    );
    let findQuestion = flattenedArray?.find((item) => item.qid === e);
    setSelectedQuestion(findQuestion);

    if (Typing) {
      clearTimeout(Sendingrequest?.current);
      Sync(SelectedQuestion, false, findQuestion?.qid);
    }
    localStorage.setItem("qno", findQuestion?.qid);

    setSelectedFile({
      language: SelectedLanguage.language,
      extension: SelectedLanguage.extension,
    });
    const setFileData = (key, value, fallbackValue = "", css, js) => {
      if (key === "html") {
        setFilesData((val) => ({
          ...val,
          [key]: value !== undefined && value !== "" ? value : fallbackValue,
          css: css !== undefined && css !== "" ? css : "",
          javascript: js !== undefined && js !== "" ? js : "",
          markdown: findQuestion?.qstn?.question,
        }));
      } else {
        setFilesData((val) => ({
          ...val,
          [key]: value !== undefined && value !== "" ? value : fallbackValue,
          markdown: findQuestion?.qstn?.question,
        }));
      }
    };

    if (SelectedLanguage?.language === "html") {
      const { markdown, ...rest } =
        findQuestion.answer[findQuestion?.answer?.length - 1]?.code || {};
      setFileData(
        "html",
        rest[SelectedLanguage?.language] || htmlcode,
        "",
        rest["css"],
        rest["javascript"]
      );
    } else {
      const { markdown, ...rest } =
        findQuestion?.answer[findQuestion?.answer?.length - 1]?.code || {};

      setFileData(
        SelectedLanguage?.language,
        rest[SelectedLanguage?.language],
        ""
      );
    }
    setTyping(false);
    setOutput("");

    setisSubmitted(false);
    // Sync(SelectedQuestion);
  };

  const DisableEnable = useCallback(
    (diff, subind) => {
      if (
        data[0][diff][subind]?.answer[data[0][diff][subind]?.answer?.length - 1]
          ?.issubmitted ??
        false
      ) {
        return true;
      } else {
        return false;
      }
    },
    [data]
  );

  const QuestionRenderer = (...args) => {
    const [diff, subind, val] = args;
    let disabled = DisableEnable(diff, subind);

    return (
      <div
        className={`${selected_question_index === subind
          ? styles.selected_question
          : styles.unselected_question} ${disabled?styles.submitted:styles.notsubmitted}`
          
        }
        style={{ cursor: "pointer" }}
      >
        <label
          className="mx-2  w-100"
          style={{
            cursor: "pointer",
            height: "47px",

            width: "133px",

            overflow: "hidden",
          }}
          dangerouslySetInnerHTML={{
            __html: val?.title ?? val?.qstn?.question,
          }}
        />{" "}
        {disabled && <CheckOutlined title="Submitted"/>}
      </div>
    );
  };

  // Menu items
  const QuestionList = () => {
    return (
      <div className={styles.question_render_main}>
        {data[0]?.[difficulty]?.length > 0 &&
          data[0]?.[difficulty].map((val, ind) => {
            return (
              <div
                onClick={() => {
                  setSelected_question_index(ind);
                  HandleMenuSelect(val.qid);
                  setOpen(false);
                }}
                key={ind}
                className={styles.question_render}
              >
                {QuestionRenderer(difficulty, ind, val)}
              </div>
            );
          })}
      </div>
    );
  };

  const HandleLanguageChange = (e) => {
    const language = e.split("-")[1];
    const extension = e.split("-")[0];
    setisSubmitted(false);
    setSelectedLanguage({ language, extension });
    setSelectedFile({ language, extension });

    const setFileData = (key, value, fallbackValue = "", css, js) => {
      if (key === "html") {
        setFilesData((val) => ({
          ...val,
          [key]: value !== undefined && value !== "" ? value : fallbackValue,
          css: css !== undefined && css !== "" ? css : "",
          javascript: js !== undefined && js !== "" ? js : "",
        }));
      } else {
        setFilesData((val) => ({
          ...val,
          [key]: value !== undefined && value !== "" ? value : fallbackValue,
        }));
      }
    };

    if (language === "html") {
      const { markdown, ...rest } =
        SelectedQuestion?.answer[SelectedQuestion?.answer?.length - 1]?.code ||
        {};
      setFileData(
        "html",
        rest[language] || htmlcode,
        "",
        rest["css"],
        rest["javascript"]
      );
    } else {
      const { markdown, ...rest } =
        SelectedQuestion?.answer[SelectedQuestion?.answer?.length - 1]?.code ||
        {};

      setFileData(language, rest[language], "");
    }
  };

  // Run the Code

  const HandleRun = () => {
    if (SelectedLanguage?.language === "html") {
      // console.log(FilesData)

      const modifiedCSS = FilesData.html.replace(
        /<link\s+[^>]*href="style\.css"[^>]*>/g,
        `<style>\n ${FilesData.css} \n</style>`
      );

      const modifiedScript = modifiedCSS.replace(
        /<script\s+[^>]*src="script\.js"[^>]*>[\s\S]*?<\/script>/g,
        `<script>\n   ${FilesData.javascript}\n</script>`
      );

      setOutput(modifiedScript);
      setOpenWebView(true);
    } else {
      setCompilerStatus((val) => ({
        ...val,
        isrunning: true,
        Isinfinite: false,
      }));
      let data = {
        language: SelectedLanguage.language,
        stdin: "",
        files: [
          {
            name: `Main.${SelectedLanguage?.extension}`,
            content: FilesData[SelectedLanguage?.language],
          },
        ],
      };

      postrequest(`/compiler/${SelectedLanguage?.language}`, data)
        .then((res) => {
          setCompilerStatus((val) => ({
            ...val,
            isrunning: false,
            openconsole: true,
          }));
          setOutput(res.data?.data);
        })
        .catch((err) => {
          setCompilerStatus((val) => ({
            ...val,
            isrunning: false,
            Isinfinite: true,
          }));
        });
    }
  };

  // Confirmation Timer
  const StartExpiration = () => {
    stopwatch.current = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);
  };

  const ClearTimer = () => {
    clearInterval(stopwatch.current);
    setIsTyping(false);
    setOpenWarning((val) => ({ ...val, inactive: false }));
    setTime(1);
  };

  // Side Effects
  useEffect(() => {
    const handleTyping = () => {
      setIsTyping(true);
      resetTimer();
    };

    const resetTimer = () => {
      clearTimeout(typingTimerRef?.current);
      if (isTyping && editor) {
        typingTimerRef.current = setTimeout(() => {
          setIsTyping(false);
          setOpenWarning((val) => ({ ...val, inactive: true }));
          StartExpiration();
        }, 300000);
      }
    };

    if (editor) {
      // Check if the editor has been disposed
      if (!editor._isDisposed) {
        // Add event listener to the Monaco Editor instance
        const disposable = editor.onDidChangeModelContent(handleTyping);

        // Clean up: remove the event listener when the component unmounts
        return () => disposable.dispose();
      }
    }

    return () => {
      clearTimeout(typingTimerRef.current);
    };
  }, [editor, isTyping]);

  // For auto Submit
  useEffect(() => {
    if (time === 0) {
      Sync(SelectedQuestion);

      if (window.opener) {
        window.close();
        localStorage.setItem(
          "redirectionOrigin",
          `/practice/${qid}/${gid}/${ind}`
        );
        window.opener.location.href = `/access/${qid}/${gid}/${ind}`;
      }
    }
    //eslint-disable-next-line
  }, [time]);

  // Network Handle
  const handleNetworkChange = () => {
    setOpenWarning((prevState) => ({
      ...prevState,
      isonline: navigator.onLine,
    }));
  };

  const disableRightClick = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    let flag = false;
    if (flag) {
      document.addEventListener("contextmenu", disableRightClick);
      return () => {
        document.removeEventListener("contextmenu", disableRightClick);
      };
    }

    window.addEventListener("online", handleNetworkChange);
    window.addEventListener("offline", handleNetworkChange);

    return () => {
      window.removeEventListener("online", handleNetworkChange);
      window.removeEventListener("offline", handleNetworkChange);
    };
  }, []);

  useEffect(() => {
    if (Sendingrequest?.current !== null) {
      clearTimeout(Sendingrequest.current);
    }
    let isshared = localStorage.getItem("isshared");

    if (isshared === "false" || isshared === null) {
      setOpenWarning((val) => ({ ...val, isscreenshared: false }));
    } else if (
      FilesData[SelectedLanguage?.language] !== undefined &&
      Typing &&
      openwarning?.isonline
    ) {
      Sendingrequest.current = setTimeout(() => {
        Sync(SelectedQuestion, false, SelectedQuestion?.qid);
      }, 60000);
    }
    return () => {
      clearTimeout(Sendingrequest?.current);
    };
    // eslint-disable-next-line
  }, [FilesData]);

  const HandleDifficultyChange = (val) => {
    let flattenedArray = data.flatMap((level) => [].concat(level[val]));
    if (flattenedArray?.length > 0) {
      let findind = flattenedArray.findIndex((item) =>
        item?.answer?.find((val) => !val.issubmitted)
      );

      let findQuestion = flattenedArray[findind === -1 ? 0 : findind];
      if (Typing) {
        Sync(SelectedQuestion, false, findQuestion.qid, val);
      }
      // console.log(findind)
      setSelected_question_index(findind === -1 ? 0 : findind);

      setSelectedQuestion(findQuestion);

      setDiffuculty(val);
      localStorage.setItem("qno", findQuestion.qid);
      setSelectedFile({ language: SelectedLanguage.language, extension: SelectedLanguage.extension });
      const setFileData = (key, value, fallbackValue = "", css, js) => {
        if (key === "html") {
          setFilesData((val) => ({
            ...val,
            [key]: value !== undefined && value !== "" ? value : fallbackValue,
            css: css !== undefined && css !== "" ? css : "",
            javascript: js !== undefined && js !== "" ? js : "",
            markdown: findQuestion?.qstn?.question,
          }));
        } else {
          setFilesData((val) => ({
            ...val,
            [key]: value !== undefined && value !== "" ? value : fallbackValue,
            markdown: findQuestion?.qstn?.question,
          }));
        }
      };

      if (SelectedLanguage?.language === "html") {
        const { markdown, ...rest } =
          findQuestion?.answer[findQuestion?.answer?.length - 1]?.code || {};
        setFileData(
          "html",
          rest[SelectedLanguage?.language] || htmlcode,
          "",
          rest["css"],
          rest["javascript"]
        );
      } else {
        const { markdown, ...rest } =
          findQuestion?.answer[findQuestion?.answer?.length - 1]?.code || {};

        setFileData(
          SelectedLanguage?.language,
          rest[SelectedLanguage?.language],
          ""
        );
      }
      setisSubmitted(false);
      setTyping(false);
      localStorage.setItem("diff", val);
    }
  };

  const LastSavedTime = (val) => {
    // Create a Date object from the input string
    if (val) {
      const date = new Date(val);

      // Extract the day, month, and year components
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const second = date.getSeconds().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${second}`;
      return formattedDate;
    }
  };

  return (
    <>
      {/* WebView Modal */}
      <ToastContainer />
      <Modal
        open={openWebview}
        onCancel={() => setOpenWebView(false)}
        width={"100vw"}
        title="Output"
        centered
        style={{ height: "100vh" }}
        // closable={false}
        maskClosable={false}
        footer={null}
      >
        <iframe
          title="HTML"
          srcDoc={Output}
          width="100%"
          style={{ height: "88vh" }}
        />
      </Modal>
      
      <Modal
        open={issubmitted}
        onCancel={() => setisSubmitted(false)}
        title="Question Submitted"
        centered
        // closable={false}
        maskClosable={false}
        footer={null}
      >
        You submitted the question. Please change the question and keep
        practicing.
      </Modal>

      {/* In Active Modal //Network Modal */}

      <Modal
        title={"InActive"}
        open={openwarning.inactive}
        onOk={() => {
          ClearTimer();
          setOpenWarning((val) => ({ ...val, inactive: false }));
        }}
        closable={false}
        maskClosable={false}
        okText={`Yes ${time}`}
        centered
        footer={(_, { OkBtn }) => <>{openwarning.inactive && <OkBtn />}</>}
      >
        {openwarning.inactive && (
          <>
            {" "}
            <span>
              We've noticed that you have been inactive for some time.
            </span>
            <br />
            <span>Do you want to continue?</span>
          </>
        )}
      </Modal>

      <Modal
        title={"Screen Stopped"}
        open={!openwarning.isscreenshared}
        onOk={() => {
          if (window.opener) {
            window.close();
            localStorage.setItem(
              "redirectionOrigin",
              `/practice/${qid}/${gid}/${ind}`
            );
            window.opener.location.href = `/access/${qid}/${gid}/${ind}`;
          } else {
            clearTimeout(Sendingrequest.current);
            clearTimeout(typingTimerRef.current);
            clearInterval(stopwatch);
            navigate("/user");
          }
        }}
        closable={false}
        maskClosable={false}
        okText={"Ok"}
        centered
        footer={(_, { OkBtn }) => (
          <>{!openwarning.isscreenshared && <OkBtn />}</>
        )}
      >
        {!openwarning.isscreenshared && (
          <>
            {" "}
            <span>
              Screen sharing has been interrupted. Don't worry, your data has
              been saved.
              <br /> You will now be redirected to the access page.
            </span>
            <br />
          </>
        )}
      </Modal>
      <Modal
        open={!openwarning.isonline}
        closable={false}
        maskClosable={false}
        centered
        footer={null}
      >
        {!openwarning.isonline && (
          <Result
            status="warning"
            icon={<GlobalOutlined />}
            title="Check your internet connectivity."
          />
        )}
      </Modal>

      {/* Navbar */}
      <section className={styles.newnavbar}>
        <img
         onClick={() => {
          if (window.opener) {
            window.close();
            localStorage.setItem(
              "redirectionOrigin",
              `/practice/${qid}/${gid}/${ind}`
            );
            window.opener.location.href = `/access/${qid}/${gid}/${ind}`;
          } else {
            navigate("/user");
          }
        }}
          src={clientdetails?.logo}
          style={{ cursor: "pointer" }}
          alt="logo"
          width={35}
        />
        <span
                style={{ color: "black", cursor: "pointer",fontSize:"13px" }}
                onClick={() => {
                  if (window.opener) {
                    window.close();
                    localStorage.setItem(
                      "redirectionOrigin",
                      `/practice/${qid}/${gid}/${ind}`
                    );
                    window.opener.location.href = `/access/${qid}/${gid}/${ind}`;
                  } else {
                    clearTimeout(Sendingrequest.current);
                    clearTimeout(typingTimerRef.current);
                    clearInterval(stopwatch);
                    navigate("/user");
                  }
                }}
              >
                Home
              </span>
      </section>
      <div className="d-flex">
        {/* Left Side */}
        <section className={styles.leftside}>
          {/* Row First */}
          <div className={styles.first_left_row}>
            <label>Description</label>
            <label title="Upcoming Feature">Feedback <LockOutlined/></label>
          </div>
          
            {loading ?<center><LoadingOutlined style={{fontSize:"40px",marginTop:"50px"}}/></center>:<>{/* Question Description */}
          <div className={styles.description}> <div
              style={{ overflow: "auto", padding: "0.4rem 1.6rem",fontSize:"14px" }}
              dangerouslySetInnerHTML={{ __html: FilesData?.markdown }}
            />
            {SelectedQuestion?.attachments?.length > 0 && (
              <div>
                <span>Attachments :</span>
                <br />
                <Image.PreviewGroup>
                  {SelectedQuestion &&
                    SelectedQuestion?.attachments?.length > 0 &&
                    SelectedQuestion?.attachments.map((item, index) => {
                      return <Image width={100} src={item} key={index} />;
                    })}
                </Image.PreviewGroup>
              </div>
            )}
          </div></>}
           
          {/* All Question  */}
          <Drawer
            title="All Questions"
            placement="left"
            closable={true}
            onClose={() => setOpen(false)}
            open={open}
            getContainer={false}
            height="90%"
            width={"100%"}
            style={{
             
              margin: "0 1px 0 1px",
              overflow: "auto",
            }}
            extra={
              <Select
                value={difficulty}
                size="small"
                style={{
                  width:  "150px" ,
                  textAlign: "left"
                }}
                onChange={(e) => HandleDifficultyChange(e)}
                options={[
                  {
                    value: "Easy",
                    label: "Easy",
                  },
                  {
                    value: "Medium",
                    label: "Medium",
                  },
                  {
                    value: "Hard",
                    label: "Hard",
                  },
                ]}
              />
            }
          >
            <QuestionList />
          </Drawer>
        </section>

        {/* Right Side */}
        <section className={styles.rightside}>
          {/* Row First */}
          <div className={styles.first_right_row}>
            <Select
              options={languagedata}
              onChange={(e) => HandleLanguageChange(e)}
              style={{
                width: "150px",
              }}
              defaultValue={"Javascript"}
              size="small"
            />
            <button onClick={() => HandleRun()} className={styles.run_btn}>
              {CompilerStatus.isrunning ? (
                <LoadingOutlined
                  style={{
                    color: "black",
                    textAlign: "center",
                    fontSize: "15px",
                  }}
                />
              ) : (
                "Run"
              )}
            </button>
          </div>
          <div className={styles.right_side_editor}>
            <div className={styles.files_group}>
              {SelectedLanguage.language === "html" ? (
                <>
                  {[
                    {
                      language: "html",
                      extension: "html",
                    },
                    {
                      language: "css",
                      extension: "css",
                    },
                    {
                      language: "javascript",
                      extension: "js",
                    },
                  ].map((lang, ind) => {
                    return (
                      <label
                        onClick={() =>
                          setSelectedFile({
                            language: lang.language,
                            extension: lang.extension,
                          })
                        }
                        style={{
                          borderColor:
                            SelectedFile.language === lang.language
                              ? "#000000"
                              : null,
                        }}
                        className={styles.language_icons}
                      >
                        {setIcons[lang.extension]}
                      </label>
                    );
                  })}
                </>
              ) : (
                <label
                  onClick={() =>
                    setSelectedFile({
                      language: SelectedLanguage.language,
                      extension: SelectedLanguage.extension,
                    })
                  }
                  className={styles.language_icons}
                  style={{
                    borderColor:
                      SelectedFile.language === SelectedLanguage.language
                        ? "#022240"
                        : null,
                  }}
                >
                  {setIcons[SelectedLanguage.extension]}
                </label>
              )}
            </div>
            <Editor
              className={styles.codeeditor}
              width={"100%"}
              onMount={(editor) => {
                setEditor(editor);
              }}
              theme="vs-dark"
              language={SelectedFile.language}
              value={FilesData[SelectedFile.language]}
              onChange={(e) => {
                if (
                  SelectedQuestion &&
                  SelectedQuestion?.answer?.length > 0 &&
                  SelectedQuestion?.answer[SelectedQuestion.answer?.length - 1]
                    .issubmitted
                ) {
                  setisSubmitted(true);
                } else {
                  setTyping(true);
                  setFilesData((val) => ({
                    ...val,
                    [SelectedFile.language]: e,
                  }));
                  setCompilerStatus((val) => ({
                    ...val,
                    Isinfinite: false,
                  }));
                  setIsTyping(true);
                }
              }}
            />
          </div>

          <Drawer
            title={
              <label>
                <CodeOutlined /> Output
              </label>
            }
            placement="right"
            closable={true}
            onClose={() =>
              setCompilerStatus((prev) => ({ ...prev, openconsole: false }))
            }
            open={CompilerStatus?.openconsole}
            getContainer={false}
           
          
            width={"100%"}
            style={{
              height: "70%",
              marginBottom:"0",
              overflow: "auto",
              position:"absolute",
              bottom:"0"
            }}
          >
            
              <pre className={styles.output}>
                {/* Output */}

                {Output && (Output.stdout || Output.stderr)}
                {/* {["mysql", "postgresql", "mongodb"].includes(
                        SelectedLanguage.language
                      ) &&
                        Output &&
                        (Output.stdout || Output.stderr)} */}
                {CompilerStatus.isrunning && "Running"}
                {CompilerStatus.Isinfinite && "Infinite Loop"}
              </pre>
            
          </Drawer>
        </section>
      </div>
      <section className={styles.practice_footer}>
        <div
          className={styles.footer_left}
          style={{ backgroundColor: open ? "lightgray" : null }}
          onClick={() => setOpen(!open)}
        >
          {" "}
          <MenuOutlined /> All Questions{" "}
        </div>
        <div className="d-flex align-items-center">
          <label className="mx-2">
            {" "}
            {saving ? (
              <span className="d-flex  align-items-center">
                {" "}
                <CloudSyncOutlined
                  style={{ fontSize: "20px" }}
                  className="mx-2"
                />{" "}
                <label style={{ cursor: "pointer" }}>Saving...</label>
              </span>
            ) : (
              SelectedQuestion?.answer[SelectedQuestion?.answer?.length - 1]
                ?.endtime && (
                <span
                  className="d-flex  align-items-center"
                  style={{fontSize:"12px"}}
                  title={
                    "Last Saved on " +
                    LastSavedTime(
                      SelectedQuestion?.answer[
                        SelectedQuestion?.answer?.length - 1
                      ]?.endtime
                    )
                  }
                >
                  <MdOutlineCloudDone
                    className="mx-2"
                    style={{ fontSize: "20px" }}
                  />{" "}
                  <label style={{ cursor: "pointer" }}>
                    Last Saved on {"  "}
                    {LastSavedTime(
                      SelectedQuestion?.answer[
                        SelectedQuestion?.answer?.length - 1
                      ]?.endtime
                    )}
                  </label>
                </span>
              )
            )}
          </label>
          <Popconfirm
            title="confirm"
            description="After submit you won't able to write code again. Are you want to Submit the question"
            placement="bottomLeft"
            onConfirm={() => Sync(SelectedQuestion, true)}
          >
            <button className={styles.submit_btn_final}>Submit</button>
          </Popconfirm>
        </div>
      </section>
    </>
  );
};
export default Practice_Mode;
