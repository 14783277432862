import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Tag,Modal } from "antd";
import { useSelector } from "react-redux";
import { FaRegUserCircle } from "react-icons/fa";
// import treeDotsMenu from "../../icons/treeDotsMenu.svg";
import { toast, ToastContainer } from "react-toastify";
import styles from "./StudentsGivenMock.module.css";
import Spinner from "react-bootstrap/Spinner";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import restest from "../../icons/retest.svg";
import { useNavigate } from "react-router-dom";
// import Form from "react-bootstrap/Form";
import revaluationPic from "../../icons/revaluation.svg";
import { getrequest } from "../Middleware/managerequest";
const StudentGivenMock = () => {
  const { id } = useParams();
  const { user } = useSelector((state) => state.Data);
  const [MockData, setMockData] = useState(null);
  const [Answers, setAnswers] = useState(null);
  const [duration, setDuration] = useState(null);
  const [revaluation, setRevaluation] = useState(false);
  const [IsData, setIaData] = useState(false);
  const [result, setResult] = useState(0);
  const [QNO, setQno] = useState(0);
  const [show, setShow] = useState(false);
  // const [showModal, setShowModal] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const nevigate = useNavigate();
  const [selectedpage, setSelectedPage] = useState("main");
  // const [reasionText, setReasionText] = useState("");
  // const handleClose = () => setShowModal(false);
  // const handleShow = () => setShowModal(true);

  const handleUserData = useCallback(
    (value) => {
      let find = value.Attempted.find((item) => item.Email === user?.Email);
      setAnswers(find);
      const difference = +new Date(find.endtime) - +new Date(find.starttime);
      let timeleft = {};
      if (difference > 0) {
        timeleft = {
          hours:
            Math.floor(difference / (1000 * 60 * 60)) < 10
              ? "0" + Math.floor(difference / (1000 * 60 * 60))
              : Math.floor(difference / (1000 * 60 * 60)),
          minutes:
            Math.floor((difference / 1000 / 60) % 60) < 10
              ? "0" + Math.floor((difference / 1000 / 60) % 60)
              : Math.floor((difference / 1000 / 60) % 60),
          seconds:
            Math.floor((difference / 1000) % 60) < 10
              ? "0" + Math.floor((difference / 1000) % 60)
              : Math.floor((difference / 1000) % 60),
        };
      }
      setDuration(timeleft);

      let total = 0,
        overall = 0;
      find.Answers.map((item) => {
        total += Number(item.mainpoints);
        overall += Number(item.point);
        return true;
      });
      let percentage = (Number(overall) / Number(total)) * 100;
      let res = percentage < 60 ? "Fail" : "Pass";
      setResult({ Total: total, overall: overall, Result: res });
    },
    [user]
  );

  useEffect(() => {
    if (user) {
      getrequest(`/user/givenmock/${id}`)
        .then((res) => {
          setMockData(res.data);
          handleUserData(res.data);
          setIaData(true);
        })
        .catch((err) =>
          toast.warning("server error", {
            position: "top-right",
            autoClose: 1500,
          })
        );
    }
  }, [id, user, handleUserData]);
  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const convertDateIntoNormal = (inputDateString) => {
    // Create a Date object from the input string
    const date = new Date(inputDateString);

    // Define months array for formatting
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract the day, month, and year components
    const day = date.getDate();
    const month = months[date.getMonth()];
    // Format the date as "DD Mon YYYY"
    const formattedDate = `${day} ${month}`;

    // Output: "22 Sept 2023"
    return formattedDate;
  };

  const Tags = (val) => {
    let StoredSkills = [];
    val?.MockData?.map((item) => {
      if (!StoredSkills.includes(item.MainTags)) {
        StoredSkills.push(item.MainTags);
      }
      return true;
    });
    return (
      <>
        {StoredSkills?.map((elee, ind) =>
          // ele?.MainTags.map((elee) =>console.log(elee))
          {
            return (
              <Tag key={ind}>
                {elee}
                {ind !== StoredSkills?.length - 1 && ","}
              </Tag>
            );
          }
        )}
      </>
    );
  };
  return (
    <>
      <ToastContainer />
      {IsData ? (
        <div className={styles.main}>
          <label className={styles.navigation}>
            <span
              onClick={() => nevigate("/user")}
              style={{ cursor: "pointer" }}
            >
              Home{" "}
            </span>
            &gt;
            <span>
              {MockData?.MockTitle} &gt;<span> {"Reviewed"}</span>
            </span>
          </label>
          <div className={styles.userDetails}>
            <div>
              <label style={{ fontSize: "70px", marginTop: "-20px" }}>
                <FaRegUserCircle />
              </label>
            </div>
            <div>
              <label>{MockData?.MockTitle}</label>
              <span>
                Valid - {MockData && convertDateIntoNormal(MockData?.MockStart)}{" "}
                {MockData?.Mockend !== " " && "- "}
                {MockData &&
                  MockData.Mockend !== " " &&
                  convertDateIntoNormal(MockData?.Mockend)}
              </span>
              <span>
                Duration - {duration?.hours}:{duration?.minutes}:
                {duration?.seconds}
              </span>
            </div>

            <div>
              <label>Language</label>
              <span>{MockData && Tags(MockData)}</span>
              {/* {console.log(MockData.MockData[0].Tags)} */}
            </div>
            <div style={{ borderRight: "none" }}>
              <label>Overall Score</label>
              <span>
                {result.overall}/{result.Total}
              </span>
            </div>
            {/* <div style={{ borderRight: "none" }}>
            <label>
              <img onClick={handleClick} src={treeDotsMenu} alt="Menu icon" />
            </label>
          </div> */}
          </div>
          <section>
            <div ref={ref}>
              <Overlay
                show={show}
                target={target}
                placement="bottom"
                container={ref}
                containerPadding={20}
              >
                <Popover id="popover-contained">
                  <div className={styles.popup}>
                    <p
                      onClick={() => {
                        setRevaluation(true);
                        handleClick();
                      }}
                    >
                      <img src={revaluationPic} alt="revaluation" />
                      Revaluation
                    </p>
                    <p
                      onClick={() => {
                        // handleShow();
                        handleClick();
                      }}
                    >
                      <img src={restest} alt="retest" />
                      Retest Request
                    </p>
                  </div>
                </Popover>
              </Overlay>
            </div>
          </section>

          <p
            className={revaluation ? styles.cancel : styles.apply}
            onClick={() => {
              setRevaluation(false);
              // handleClose();
            }}
          >
            Cancel
          </p>

          <div className={styles.cards}>
            {Answers &&
              Answers.Answers.map((item, index) => {
                return (
                  <div
                    className={styles.card}
                    key={index}
                    onClick={() => {
                      setShowFeedbackModal(true);
                      setQno(index);
                    }}
                  >
                    <label>
                      Question {index + 1} - {item?.Type}
                      {revaluation && (
                        <span>
                          <input
                            type="checkbox"
                            onClick={(e) => e.stopPropagation()}
                          />
                        </span>
                      )}
                    </label>
                    <p
                      className={styles.question1}
                      dangerouslySetInnerHTML={{
                        __html: item?.Question?.question,
                      }}
                    />
                    <section className="d-flex justify-content-around flex-wrap">
                      <label>
                        Status -{" "}
                        <span
                          className={
                            (item.point / 2) * 100 >= 50
                              ? styles.minPoint
                              : styles.fail
                          }
                        >
                          {(item.point / 2) * 100 >= 50 ? "Pass" : "Fail"}
                        </span>
                      </label>
                     
                      <label>
                        Marks -{" "}
                        <span
                          className={
                            item.point === 0 ? styles.minPoint : styles.maxPoint
                          }
                        >
                          {item.point}/{item.mainpoints}
                        </span>
                      </label>
                      <label style={{cursor:"pointer"}} className={styles.viewfeedback} > View Feedback </label>
                    </section>
                  </div>
                );
              })}
            {MockData && (
              <div className={styles.overAllFedback}>
                <label>Remark</label>
                <div>
                  {Answers?.Level.Communication && (
                    <label>
                      Communication :<span>{Answers?.Level.Communication}</span>
                    </label>
                  )}
                  {Answers?.Level.Coding && (
                    <label>
                      Practical :<span>{Answers?.Level.Coding}</span>
                    </label>
                  )}
                  {Answers?.Level.Theory && (
                    <label>
                      Theoretical :<span>{Answers?.Level.Theory}</span>
                    </label>
                  )}
                </div>
                <div>
                  <label>Overall Feedback</label>
                  <span>{Answers?.OverAllFeedback}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "49vh",
          }}
        >
          <Spinner size="xl" variant="success" />
        </div>
      )}

      {/* <Modal
        show={!revaluation && showModal}
        onHide={handleClose}
        style={{ marginTop: "100px" }}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Retest Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Reasion for Retest</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Write your retest reasion min words 250"
                onChange={(e) => setReasionText(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button disabled={reasionText.trim().length < 10}>Submit</button>
        </Modal.Footer>
      </Modal> */}

      <Modal
        open={showFeedbackModal}
        onCancel={() => {setShowFeedbackModal(false);setSelectedPage("main")}}
        centered
        width={Answers?.Answers[QNO].Type === "Coding" ? "100%" : 900}
        onOk={() => {setShowFeedbackModal(false);setSelectedPage("main")}}
        okText="Close"
        footer={(_, { OkBtn }) => (
          <>
           
            <OkBtn />
          </>
        )}
        // style={{height:"80vh"}}
      >
        <select
          onChange={(e) => {setQno(e.target.value);setSelectedPage("main")}}
          value={QNO}
          className={styles.dropDown}
        >
          {Answers &&
            Answers.Answers.map((item, index) => {
              return (
                <option
                  key={index}
                  value={index}
                  style={{
                    color: item.answer === "don't know" ? "white" : "green",
                    backgroundColor:
                      item.answer === "don't know" ? "red" : "white",
                  }}
                >
                  Question {index + 1} :{" "}
                  {item.Type === "Question" ? "Theory" : item.Type}
                </option>
              );
            })}
        </select>

        <div className={styles.feedbackBox}>
          <div className={styles.coding_container}>
            <p
              className={styles.question}
              dangerouslySetInnerHTML={{
                __html: Answers?.Answers[QNO]?.Question.question,
              }}
            />
            {Answers?.Answers[QNO]?.Type === "Coding" && (
              <div className={styles.coding_editor}>
                <span>
                  <label
                    style={{ cursor: "pointer",textDecoration:selectedpage==="main"?"underline":null }}
                    onClick={() => setSelectedPage("main")}
                  >
                    index.{Answers?.Answers[QNO]?.extension}
                  </label>
                  {Answers?.Answers[QNO]?.language === "HTML" && (
                    <>
                      <label className="mx-3"  style={{ cursor: "pointer",textDecoration:selectedpage==="css"?"underline":null }} onClick={() => setSelectedPage("css")}>
                        style.css
                      </label>
                      <label className="mx-3"  style={{ cursor: "pointer",textDecoration:selectedpage==="js"?"underline":null }} onClick={() => setSelectedPage("js")}>
                        Script.js
                      </label>
                    </>
                  )}
                </span>
                <hr style={{ width: "100%" }} />
                <pre className={styles.code_show}>
                  {selectedpage === "main" && Answers?.Answers[QNO]?.answer}
                  {selectedpage === "css" &&
                    Answers?.Answers[QNO]?.HTMLCode?.csscode}
                  {selectedpage === "js" &&
                    Answers?.Answers[QNO]?.HTMLCode?.scriptcode}
                </pre>
              </div>
            )}
          </div>

          <div
            className="d-flex flex-column mb-3"
            style={{ borderStyle: "none" }}
          >
            <col span={12}>
            {Answers?.Answers[QNO]?.Type === "Mcq" && (
              <>
                <label
                  style={{
                    color:
                      (Answers?.Answers[QNO].Options.opt1 ===
                        Answers?.Answers[QNO].answer &&
                      Answers?.Answers[QNO].point > 0
                        ? "green"
                        : "") ||
                      (Answers?.Answers[QNO].Options.opt1 ===
                        Answers?.Answers[QNO].answer &&
                      //eslint-disable-next-line
                      Answers?.Answers[QNO].point == 0
                        ? "red"
                        : ""),
                  }}
                >
                  1. {Answers?.Answers[QNO].Options.opt1}
                </label>
                <label
                  style={{
                    color:
                      (Answers?.Answers[QNO].Options.opt2 ===
                        Answers?.Answers[QNO].answer &&
                      Answers?.Answers[QNO].point > 0
                        ? "green"
                        : "") ||
                      (Answers?.Answers[QNO].Options.opt2 ===
                        Answers?.Answers[QNO].answer &&
                      //eslint-disable-next-line
                      Answers?.Answers[QNO].point == 0
                        ? "red"
                        : ""),
                  }}
                >
                  2. {Answers?.Answers[QNO].Options.opt2}
                </label>
                <label
                  style={{
                    color:
                      (Answers?.Answers[QNO].Options.opt3 ===
                        Answers?.Answers[QNO].answer &&
                      Answers?.Answers[QNO].point > 0
                        ? "green"
                        : "") ||
                      (Answers?.Answers[QNO].Options.opt3 ===
                        Answers?.Answers[QNO].answer &&
                      //eslint-disable-next-line
                      Answers?.Answers[QNO].point == 0
                        ? "red"
                        : ""),
                  }}
                >
                  3. {Answers?.Answers[QNO].Options.opt3}
                </label>
                <label
                  style={{
                    color:
                      (Answers?.Answers[QNO].Options.opt4 ===
                        Answers?.Answers[QNO].answer &&
                      Answers?.Answers[QNO].point > 0
                        ? "green"
                        : "") ||
                      (Answers?.Answers[QNO].Options.opt4 ===
                        Answers?.Answers[QNO].answer &&
                      //eslint-disable-next-line
                      Answers?.Answers[QNO].point == 0
                        ? "red"
                        : ""),
                  }}
                >
                  4. {Answers?.Answers[QNO].Options.opt4}
                </label>
              </>
            )}
            
            </col>
            <col span={12}>
            hi
            </col>
            
          </div>
          {/* <div>
              {Answers?.Answers[QNO].transcribe?.length <= 0
                ? "Transcript Not Available"
                : Answers?.Answers[QNO].transcribe}
            </div> */}
          <label>Feedback</label>
          <div>
            {Answers?.Answers[QNO]?.feedback ? (
              <div className={styles.feedback_container}>
                {Answers?.Answers[QNO]?.feedback}
              </div>
            ) : (
              <label>
                Uh-oh! It seems there's an issue with the feedback, possibly due
                to some background noise. No worries! You can connect with the
                coordinator for a manual check.
              </label>
            )}
            <label>
              Point{" "}
              <span>
                {Answers?.Answers[QNO]?.point}/
                {Answers?.Answers[QNO]?.mainpoints}
              </span>
            </label>
          </div>
          {Answers?.Answers[QNO]?.explanation &&Answers?.Answers[QNO]?.Type==="Coding" && <section className={styles.explanation_main}>
          <label>Explanation</label>
          <div>
            {Answers?.Answers[QNO]?.feedback ? (
              <div className={styles.feedback_container}>
                {Answers?.Answers[QNO]?.explanation}
              </div>
            ) : (
              <label>
                Uh-oh! It seems there's an issue with the feedback, possibly due
                to some background noise. No worries! You can connect with the
                coordinator for a manual check.
              </label>
            )}
           
          </div>
          </section>}
         
          
        </div>
      </Modal>
    </>
  );
};

export default StudentGivenMock;
