import React, { useState } from "react";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import styles from "./styles/split.module.css";
import {
  EditOutlined,
  UserOutlined,
  MailOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { Image } from "antd";
import EduSteps from "./EduSteps";
import EditProfile from "./Modals/EditProfile";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import ExpSteps from "./ExpSteps";
import { postrequest } from "../../Middleware/managerequest";
import AddEducationModal from "./Modals/AddEducationModal";
import { useSelector, useDispatch } from "react-redux";
import {
  updateProfilePhoto,
  updateResumeLink,
} from "../../../store/StudentProfileSlice";
import EditExpModal from "./Modals/EditExpModal";
import EditEduModal from "./Modals/EditEduModal";
import AddExperienceModal from "./Modals/AddExperienceModal";

function SplitInfo() {
 
  const [showEditExpModal, setShowEditExpModal] = useState(false);
  const [showEditEduModal, setShowEditEduModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showAddEduModal, setShowAddEduModal] = useState(false);
  const [showAddExpModal, setShowAddExpModal] = useState(false);
  
  const StudentProfile = useSelector((state) => state.StudentProfile);
  const { user } = useSelector((state) => state.Data);
  const dispatch = useDispatch();
  const [editEdu, setEditEdu] = useState({
    title: "",
    description: "",
    id: 1,
    year: "",
    CurrentlyAppearing: false,
  });
  const [editExp, setEditExp] = useState({
    title: "",
    total: "",
    description: "",
    id: 1,
    start: "",
    end: "",
    currentlyWorking: false,
  });
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("myimage", file);
    // Send formData to server using fetch or Axios
    postrequest(
      `/api/v1/user/profileImageUpload/${user.Email}/?file=profile`,
      formData
    )
      .then((res) => {
        dispatch(updateProfilePhoto(res.data));
      })
      .catch((err) => console.log(err));
  };

  const handleResumeChange = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("myimage", file);
    postrequest(
      `/api/v1/user/profileImageUpload/${user.Email}/?file=resume`,
      formData
    )
      .then((res) => {
        dispatch(updateResumeLink(res.data));
       
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDownload = () => {
    window.open(StudentProfile?.resumeLink, "_blank");
  };
  return (
    <div className={styles.main}>
      <div className={styles.profile}>
        <input
          type="file"
          accept=".jpg, .jpeg, .webp, .png"
          onChange={handleFileChange}
          style={{ display: "none" }}
          id="profilePhotoInput"
        />

       

        <div
          style={{
            display: "flex",
            gap: "30px",
            alignItems: "center",
            justifyContent:"space-between"
          }}
        >
          <div style={{
            display: "flex",
            gap: "30px",
            alignItems: "center",
          }}>
          <div
            className={styles.profilePic}
            style={{
              position: "relative",
              padding: StudentProfile?.imagesurl !== undefined ? "0px" : "15px",
            }}
          >
            {StudentProfile?.imagesurl !== undefined ? (
              <Image
                src={StudentProfile?.imagesurl}
                style={{
                  height: "90px",
                  width: "90px",
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
                alt="Profile Photo"
                preview={{
                  onPreview: (e) => {
                    e.stopPropagation(); // Stop propagation here
                  },
                }}
              />
            ) : (
              <UserOutlined />
            )}
            <label htmlFor="profilePhotoInput">
              <div
                style={{
                  position: "absolute",
                  fontSize: "16px",
                  backgroundColor: "aliceblue",
                  color: "black",
                  paddingInline: "4px",
                  borderRadius: "55%",
                  bottom: "2px",
                  right: "0px",
                  border: "1px solid black",
                  cursor: "pointer",
                }}
              >
                <EditOutlined />
              </div>
            </label>
            
          </div>

          <div>
            <h3 style={{ fontFamily: "Poppins" }}>
              {StudentProfile.firstName} {StudentProfile.lastName}
            </h3>
            <div className={styles.social}>
              <span
                onClick={() => {
                  window.open(StudentProfile?.linkedIn, "_blank");
                }}
              >
                <FaLinkedin />
              </span>
              <span
                onClick={() => {
                  window.open(StudentProfile?.gitHub, "_blank");
                }}
              >
                <FaGithub />
              </span>
            </div>
          </div>
          </div>
          <span
          className={styles.editProfile}
          onClick={() => setShowProfileModal(true)}
        >
          <EditOutlined />
        </span>
        </div>

        <p >{StudentProfile.description}</p>

        <div>
          <h5 style={{ fontFamily: "Poppins", fontWeight: "500" }}>
            Contact Information
          </h5>
          <div className={styles.contactsDiv}>
            <div className={styles.AddEle}>
              <div className={styles.icon}>
                <MailOutlined style={{color:"black",fontSize:"15px"}}/>
              </div>
              <div className={styles.innerContact}>
                <h6>Email</h6>
                <p>{StudentProfile?.email}</p>
              </div>
            </div>
            <div className={styles.AddEle}>
              <div className={styles.icon}>
                <FaMapMarkerAlt style={{color:"black",fontSize:"15px"}}/>
              </div>
              <div className={styles.innerContact}>
                <h6>Address</h6>
                <p>
                  {StudentProfile?.address &&<> {StudentProfile?.address?.city+", "}
                  {StudentProfile?.address?.state}</>}
                 
                </p>
              </div>
            </div>
            <div className={styles.AddEle}>
              <div className={styles.icon}>
                <FaPhoneAlt style={{color:"black",fontSize:"15px"}}/>
              </div>
              <div className={styles.innerContact}>
                <h6>Phone</h6>
                <p>{StudentProfile?.phone}</p>
              </div>
            </div>
            <div className={styles.AddEle}>
              <div className={styles.icon}>
                <FileTextOutlined style={{color:"black",fontSize:"15px"}}/>
              </div>
              <div className={styles.innerContact}>
                <h6>
                  
                  Resume
                </h6>
                <div style={{ display: "flex", gap: "10px" }}>
                  {StudentProfile?.resumeLink &&<label className={styles.label} onClick={handleDownload}>
                    View
                  </label>}
                  

                  <input
                    type="file"
                    accept=".pdf"
                    onChange={handleResumeChange}
                    style={{ display: "none" }}
                    id="resumeInput"
                  />
                  <label htmlFor="resumeInput" className={styles.label}>
                    Upload
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.steps}>
        <ExpSteps
          setShowEditExpModal={setShowEditExpModal}
          setShow={setShowAddExpModal}
          setEditExp={setEditExp}
        />
        <EduSteps
          setShowEditEduModal={setShowEditEduModal}
          setShow={setShowAddEduModal}
          setEditEdu={setEditEdu}
        />
      </div>
      <EditProfile show={showProfileModal}  setShow={setShowProfileModal} />
      <AddEducationModal
        show={showAddEduModal}
        setShow={setShowAddEduModal}
        editEdu={editEdu}
      
      />
      <AddExperienceModal    show={showAddExpModal} setShow={setShowAddExpModal} />
      <EditExpModal
        show={showEditExpModal}
        setShow={setShowEditExpModal}
        editExp={editExp}
        setEditExp={setEditExp}
        
      />
      <EditEduModal
        show={showEditEduModal}
        setShow={setShowEditEduModal}
        editEdu={editEdu}
        setEditEdu={setEditEdu}
       
      />
    </div>
  );
}

export default SplitInfo;
