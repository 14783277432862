import React from "react";
import logo1 from "../../utils/logo.png"
import styles from "../../styles/Footer.module.css";
import location from '../../icons/location.svg';
import linkedinImg from '../../icons/linkedin.svg';
import facebookImg from '../../icons/facebook.svg';
import instagramImg from '../../icons/instagram.svg';
import youtubeImg from '../../icons/youtube.svg';
import twitterImg from '../../icons/twitter.svg';
import phoneImg from '../../icons/phone.svg';
import mailImg from '../../icons/mail.svg';
// Modal

const Footer = () => {
 

  return (
    <div>
     
      <div className={styles.footer_wrapper}>
        <div className={styles.footer_first}>
          <div className={styles.logo_footer}>
            <img src={logo1} alt="logo"/>
           
          </div>
          <p>
            Digikull education is inspired by our ancient education system
            called Gurukul. Unlike our current education system where the
            quality education is only accessible to only those who all well off
            financially, the gurukul education system was open to everyone where
            the student could join even the best of education institute in the
            world without paying any upfront fee. We also allow the student to
            join the Digikull education by paying a very small upfront fee and
            the rest of the fee is paid in the form of GURU DAKSHINA after
            getting the job.
          </p>
        </div>
        <div className={styles.footer_second}>
          <h2>Courses</h2>
          <div>
           
              {/* <image src={full_Stack} alt="full_Stack" />{" "} */}
              <a href="https://www.digikull.com/frontend-development-course-react" target="_blank" rel="noreferrer">Full Stack Development</a>
           
          </div>
          <div>
           
              {/* <image src={front_end} alt="front_end" />{" "} */}
              <a href="https://www.digikull.com/fullstack-development-course" target="_blank" rel="noreferrer">Frontend Development</a>
           
          </div>
          <div>
       
              {/* <image src={data_ana} alt="data_ana" /> */}
               <a href="https://digikull.com/data-analytics-python-course" target="_blank" rel="noreferrer">Data Analytics</a>
          
          </div>
          <div>
       
       {/* <image src={data_ana} alt="data_ana" /> */}
        <a href="https://digikull.com/data-analytics-python-course" target="_blank" rel="noreferrer">Data Science</a>
   
   </div>
         
        
        </div>
        <div className={styles.footer_third}>
          <p className={styles.Contact_us}>Contact Us</p>
          <div>
            <img src={location} alt="location_2" />
            <p>
              603, White Square, Hinjewadi-Wakad Road, Bridge, Near Wakad, Pune,
              Maharashtra, 411057
            </p>
          </div>
          <div>
            <img src={location} alt="location" />
            <p>D -32 , Sector 50, Noida Uttar Pradesh , 201301</p>
          </div>
          <div>
            <img src={location} alt="location" />
            <p>C-707, Noida One, Sector 62, Noida 201309</p>
          </div>
          <div>
            <img src={mailImg} alt="email" /> <p>info@digikull.com</p>
          </div>
          <div>
            <img src={phoneImg} alt="phone" /> <p>+91 8383047314</p>
          </div>
        </div>
        <div className={styles.footer_forth}>
         
          <h4>Follow Us</h4>
          <div>
         
          <a href="https://www.linkedin.com/company/digikull/" target="_blank" rel="noreferrer">
            <img src={linkedinImg} alt="LinkedIn" width={40} height={40} />
          </a>
          <a href="https://www.instagram.com/digikull/?hl=en" target="_blank" rel="noreferrer">
            <img src={instagramImg} alt="Instagram" width={40} height={40}/>
          </a>
          <a href="https://twitter.com/digikull" target="_blank" rel="noreferrer">
            <img src={twitterImg} alt="Twitter" width={40} height={40}/>
          </a>
          <a href="https://www.youtube.com/channel/UCr2GkDsCvJyG6gD7EsC7x7g" target="_blank" rel="noreferrer">
            <img src={youtubeImg} alt="YouTube" width={40} height={40}/>
          </a>
          <a href="https://www.facebook.com/digikulledu/" target="_blank" rel="noreferrer">
            <img src={facebookImg} alt="Facebook" width={40} height={40}/>
          </a>
          </div>
        </div>
      </div>
    
    </div>
  );
};

export default Footer;