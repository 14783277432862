import React from "react";
import styles from "./warning.module.css";
import { IoIosCloudDone } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const Warning = ({ Settings }) => {
  const data = useSelector((state) => state.Data);
  const navigate = useNavigate();
 

  const { user } = data;
  return (
    <div className="d-flex justify-content-center">
      <div className={styles.warning_main}>
        <center>
          <h2 className={styles.heading}>Mock Submitted</h2>
          <label className={styles.greeting}> Hello {user?.FullName}</label>
          <br />
          <IoIosCloudDone className={styles.doneicon} />
          <br />

          <label className={styles.greeting}>
            Your Mock or Test is successfully submitted.
            {(Settings?.autofeedback ?? false) && (
              <>
                <br /> You can view the result{" "}
             after 15 Min.
              </>
            )}
          </label>
          <br />

          <label
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => navigate("/user")}
          >
            Home
          </label>
        </center>
      </div>
    </div>
  );
};

export default Warning;
