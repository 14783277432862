import React from "react";
import { Drawer } from "antd";
import {
  UnlockOutlined,
  UserOutlined,
  ExportOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Menu, Modal, Avatar } from "antd";
import { useMsal } from "@azure/msal-react";
import styles from "./DashboardSlider.module.css";
import CreatableSelect from "react-select/creatable";
import {
  setuser,
  setAdmin,
  setIslogin,
  setIssubscribed,
  setIsSuperAdmin,
} from "../../store/Reducer";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const ProfileSlider = ({
  setOpenDwa,
  openDwa,
  user,
  menuItems,
  setMenuKey,
  handleSegment,
  handlerClick,
  setIsSegment,
  isSegment,
  createNewSegment,
  categoryList,
  customStyles,
  imageurl,
}) => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { admin, IsSuperAdmin } = useSelector((state) => state.Data);

  const handleclearsignout = () => {
    let obj = null,
      isUsers = false,
      isGroup = false,
      ClientName = "Digikull",
      isSubscribed = false,
      MockCount = 0;
    dispatch(setuser(obj));
    dispatch(setAdmin(false));
    dispatch(setIslogin(false));
    dispatch(setIsSuperAdmin(null));
    localStorage.removeItem("FullName");
    localStorage.removeItem("my_profile_image");
    dispatch(
      setIssubscribed({ isUsers, isGroup, ClientName, isSubscribed, MockCount })
    );
    navigate("/");
    process.env.REACT_APP_ENVIRONMENT === "local"
      ? Cookies.remove("digikull_local")
      : Cookies.remove("digikull", { domain: ".skillhubpro.com" });
    sessionStorage.clear();
  };

  const signout = async () => {
    if (accounts.length > 0) {
      instance.logoutPopup().then(() => {
        handleclearsignout();
      });
    } else if (accounts.length === 0) {
      handleclearsignout();
    }
  };

  return (
    <div>
      <Drawer
        title="Your Profile"
        placement={window.innerWidth < 550 ? "left" : "right"}
        style={{ backdropFilter: "blur(10px)" }}
        onClose={() => setOpenDwa(false)}
        open={openDwa}
        closeIcon={true}
        width={window.innerWidth < 550 ? "90%" : 400}
      >
        <div className="userProfile">
          <div className="profile1">
            <Avatar
              src={imageurl ?? ""}
              icon={<UserOutlined />}
              style={{ width: "64px", height: "64px" }}
              size={64}
            />
            <label>
              <label>
                <label className="mx-2">{user?.FullName} </label>{" "}
                {!admin && (
                  <ExportOutlined
                    style={{ fontSize: "15px", cursor: "pointer" }}
                    onClick={() => navigate("/profile")}
                  />
                )}{" "}
              </label>
              <span>{user?.Email}</span>
            </label>
          </div>
        </div>
        <div className={styles.menuItem}>
          <Menu
            onClick={(e) => {
              setMenuKey(e.key);
              handlerClick(e);
            }}
            mode="inline"
            theme="light"
            items={menuItems}
          />
        </div>
        {!admin && (
          <div className={styles.submenu_user}>
            <span
              onClick={() => navigate("/profile")}
              className={styles.submenu_profile}
            >
              <UserOutlined style={{ fontSize: "20px" }} />{" "}
              <label className={styles.menu_label}>My Profile</label>
            </span>
          </div>
        )}
        {admin && IsSuperAdmin && (
          <div className={styles.submenu_user}>
            <span
              onClick={() => navigate("/settings")}
              className={styles.submenu_profile}
            >
              <SettingOutlined style={{ fontSize: "20px" }} />{" "}
              <label className={styles.menu_label}>Manage Organization</label>
            </span>
          </div>
        )}

        <main
          onClick={() => {
            signout();
            setOpenDwa(false);
          }}
          className="signoutbtn"
        >
          <span>
            <UnlockOutlined />
          </span>

          <label>Sign out</label>
        </main>
        <Modal
          title="Create Segment"
          onCancel={() => setIsSegment(false)}
          open={isSegment}
          onOk={() => createNewSegment()}
          centered
          width={500}
          maskClosable={false}
        >
          <CreatableSelect
            options={categoryList}
            onChange={handleSegment}
            placeholder="Enter new segment name"
            isClearable={true}
            closeMenuOnSelect={true}
            isSearchable={true}
            isMulti={false}
            className="Add_user"
            styles={customStyles}
          />
        </Modal>
      </Drawer>
    </div>
  );
};

export default ProfileSlider;
