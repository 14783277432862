import React from "react";

import { useNavigate } from "react-router-dom";
import { Result,Button } from "antd";

const Page404 = () => {
  const navigate = useNavigate();

  return (
    <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={<Button type="primary" onClick={()=>navigate("/")}>Back Home</Button>}
    className="mt-5"
  />
  );
};

export default Page404;
