import React from "react";
import check from "../../icons/check.webp";
import { useNavigate } from "react-router-dom";
const Subscriptionthanks = () => {
  const navigate=useNavigate()
  return (
    <center>
      {" "}
      <div className="mt-5 pt-5">
        <img src={check} alt="subscribed" width="100" height="auto" />
        <br />
        <br />
        <h3>Thank You!</h3>
        <label className="mx-5">
          <b>We are delighted to welcome you to the Assessment Tool.</b>
          <br /> Thank you for choosing Assessment Tool . You will receive an
          email through the Assessment Tool.
          <br /> Our team will activate your account within 24 hours.
          <br />
          <br />
          Contact us on <a href="mailto:interview@digikull.com">interview@digikull.com</a>
          <br/>
          <br/>
          <u onClick={()=>navigate("/")} style={{cursor:"pointer"}}>Home</u>
        </label>
      </div>
    </center>
  );
};

export default Subscriptionthanks;
