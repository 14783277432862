import React, { useState, useEffect, useRef } from "react";

export const CountdownTimer = React.memo(({ minutes, isPaused }) => {
  const intervalRef = useRef(null);
  const initialCountdownTime = minutes * 60;
  const [countdownTime, setCountdownTime] = useState(initialCountdownTime);

  useEffect(() => {
    if (countdownTime <= 0) {
      clearInterval(intervalRef.current);
      return;
    }

    intervalRef.current = setInterval(() => {
      if (!isPaused) {
        setCountdownTime((prevCountdownTime) => prevCountdownTime - 1);
      }
    }, 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [countdownTime, isPaused]);

  return (
    <div>
      {countdownTime <= 0 ? "Countdown expired" : formatTime(countdownTime)}
    </div>
  );
});


export const TestTimer = ({ seconds, movetonext }) => {
  const timerRef = useRef(null);
  const [countdownTime, setCountdownTime] = useState(seconds); 

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setCountdownTime((prevCountdownTime) => {
        if (prevCountdownTime > 0) {
          return prevCountdownTime - 1;
        } else {
          clearInterval(timerRef.current);
          movetonext("know", "timer");
          return 0;
        }
      });
    }, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [movetonext]);

  useEffect(() => {
    setCountdownTime(seconds); // Convert minutes to seconds
  }, [seconds]);

  return (
    <div>
      {countdownTime <= 0 ? "Time Over" : formatTime(countdownTime)}
    </div>
  );
};


export const QuestionwiseTimer = ({ seconds, movetonext, qno }) => {
  const timerRef = useRef(null);
  const [countdownTime, setCountdownTime] = useState(seconds); 

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setCountdownTime((prevCountdownTime) => {
        if (prevCountdownTime > 0) {
          return prevCountdownTime - 1;
        } else {
          clearInterval(timerRef.current);
          movetonext("know", "timer");
          return 0;
        }
      });
    }, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [movetonext]);

  useEffect(() => {
    setCountdownTime(seconds); // Convert minutes to seconds
  }, [seconds, qno]);

  return (
    <div>
      {countdownTime <= 0 ? "Time Over" : formatTime(countdownTime)}
    </div>
  );
};

// Function to format time (convert seconds to HH:MM:SS format)
function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return <label style={{color:hours===0&&minutes===0&&remainingSeconds<20?"red":null}}>{`${hours}h ${minutes}m ${remainingSeconds}s`}</label>
}
