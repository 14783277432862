import React from "react";
import Col from "react-bootstrap/Col";
import {Spin} from "antd"
import Form from "react-bootstrap/Form";
import { useState, useRef, useEffect } from "react";

import { ToastContainer, toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import { postrequest } from "../Middleware/managerequest";

const VerifyOTP = ({ id,mockid}) => {
  const [isenterotp,setIsenterotp]=useState(false)
  const [data] = useState([1, 2, 3, 4]);
  const inputRefs = useRef([1, 2, 3, 4]);
  const handleKeyPress = (index) => {
    let nextIndex = null;
    if (inputRefs.current[index].value.length === 1) {
      nextIndex = index + 1;
    } else if (index === 0) {
      nextIndex = 0;
    } else {
      nextIndex = index - 1;
    }
    if (nextIndex !== inputRefs.current.length) {
      inputRefs.current[nextIndex].focus();
    }
    if (nextIndex === inputRefs.current.length) {
      Submit();
    }
  };
  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  const navigate = useNavigate();
  const Submit = () => {
    let OTP = "";
    inputRefs.current.map((item) => (OTP += item.value));
    let obj = {
      id: id,
      otp: OTP,
    };
    setIsenterotp(true)
    postrequest(`/home/verificationemail`,obj)
      .then((res) => {
        setIsenterotp(false)
        if (res.status === 201) {
          toast.error("OTP is Expired", {
            position: "top-center",
            autoClose: 1500,
          });
        } else {
          toast.success("OTP verified", {
            position: "top-center",
            autoClose: 1500,
          });
          navigate("/recovery",{state:{id:mockid}});
        }
      })
      .catch((err) => {
        setIsenterotp(false)
        if (err.response.status === 400) {
          toast.error("Please check OTP", {
            position: "top-center",
            autoClose: 1500,
          });
        } else {
          toast.error("Server not responding", {
            position: "top-center",
            autoClose: 1500,
          });
        }
      });
  };
  const resend = () => {
    let obj = {
      id: id,
    };
    setIsenterotp(true)
    postrequest(`/home/verificationemail`, obj).then((res) => {
      if (res.status === 201) {
        setIsenterotp(false)
        toast.success("OTP send successfully", {
          position: "top-center",
          autoClose: 1500,
        });
      }
    });
  };
  return (
    <div className="mt-5 pt-5">
      
      <ToastContainer />
      <div className="d-flex justify-content-center align-items-center">
        <h2> Email Verification</h2>
      </div>
      <Col
        lg={{ span: 2, offset: 5 }}
        sm={{ span: 4, offset: 4 }}
        xs={{ span: 6, offset: 3 }}
      >
        <div className="d-flex justify-content-around">
          {data.map((data, index) => (
            <div key={index} style={{ width: "50px" }}>
              <Form.Control
                type="text"
                maxLength={1}
                onChange={() => handleKeyPress(index)}
                ref={(element) => (inputRefs.current[index] = element)}
                style={{ textAlign: "center" }}
              />
            </div>
          ))}
        </div>
      </Col>

      <div className="d-flex justify-content-center align-items-center">
        {isenterotp ?<Spin spinning={isenterotp} className="mt-2"/>:<p onClick={() => resend()} style={{ cursor: "pointer" }}>
          <u>Resend OTP.</u>
        </p>}
        
      </div>
    </div>
  );
};

export default VerifyOTP;
