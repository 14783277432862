import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./createquestion.module.css";
import {
  HomeOutlined,
  ContainerOutlined,
  UsergroupAddOutlined,
  LoadingOutlined,
  UploadOutlined,
  SyncOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";

import { toast, ToastContainer } from "react-toastify";
import {
  AutoComplete,
  Button,
  Input,
  InputNumber,
  Select,
  Tag,
  message,
} from "antd";
import DashboardSlider from "../../Navigator_Footer/DashboardSlider";
import ProfileSlider from "../../Navigator_Footer/ProfileSlider";

import { postrequest } from "../../Middleware/managerequest";
import LatestNavbar from "../../Navigator_Footer/LatestNavbar";
import Sider from "antd/es/layout/Sider";
const QuestionWiseTime = {
  Question: 300,
  Mcq: 180,
  Coding: 1800,
};

const minutesMapping = {
  Coding: {
    Easy: 600,
    Medium: 900,
    Hard: 1200,
  },
  Subjective: {
    Easy: 50,
    Medium: 60,
    Hard: 100,
  },
  Mcq: {
    Easy: 50,
    Medium: 70,
    Hard: 110,
  },
};
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const CreateQuestion = ({
  fetcher,
  MainTopics,
  setFlag,
  user,
  list,
  Topics,
  access,
}) => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [options, setOptions] = useState([]);
  const [menuKey, setMenuKey] = useState("");
  const [openDwa, setOpenDwa] = useState(false);
  const [searching, setSearching] = useState(false);
  const [editorHtml, setEditorHtml] = useState("");
  const [qstn, setQstn] = useState("");
  const [level, setLevel] = useState("Easy");
  const [type, setType] = useState("Subjective");
  const [selectedsubTopic, setSelectedSubTopics] = useState([]);
  const [MainTags, setMainTags] = useState("Select Topic");
  // const [Topics, setTopics] = useState([]);
  const [Points, setPoints] = useState(0);
  const [time, setTime] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [title, setTitle] = useState("");
  const [issendrequest, setIssendrequest] = useState(false);
  const [Options, setOption] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });
  // MCQ Answer
  const [answer, setAnswer] = useState("");

  useEffect(() => {
    setTime(minutesMapping[type][level])
  }, [type, level])

  // MCQ Answer
  const setAnswers = (e) => {
    if (e.target.checked) {
      setAnswer(e.target.value);
    }
  };
  // Handle Options
  const Onchange = (e) => {
    let value = { ...Options, [e.target.name]: e.target.value };
    setOption(value);
  };

  const editorRef = useRef(null);

  const handleEditorChange = (html) => {
    setEditorHtml(html);
    setQstn({ ...qstn, question: html });
  };

  // Handle Image Change
  useEffect(() => {
    const editor = editorRef.current;
    if (!editor || !editor.editor || !editor.editor.root) return;

    const editorRoot = editor.editor.root;
    const handleImageInserted = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
          const images = editorRoot.querySelectorAll("img");

          images.forEach((image) => {
            image.setAttribute("width", "150"); // Default width
            image.setAttribute("height", "100"); // Default height
          });
        }
      });
    };

    const observer = new MutationObserver(handleImageInserted);
    observer.observe(editorRoot, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  // Editor Modules
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  // Editor Formats
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const parseHTMLText = (htmlString) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText || "";
  };

  // Handle Question Search
  const handleSearch = (value) => {
    setSearching(true);
    let arr = list
      .filter(
        (item) =>
          item?.title?.toString().toLowerCase().includes(value.toLowerCase()) ||
          parseHTMLText(item.qstn?.question)
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
      )
      .map((item, index) => ({
        value: index.toString(),
        label: parseHTMLText(
          (item.title?.toString().toLowerCase().includes(value.toLowerCase()) &&
            item.title) ||
          (item.qstn?.question
            ?.toString()
            .toLowerCase()
            .includes(value.toLowerCase()) &&
            item.qstn?.question)
        ),
        disabled: true,
        style: { color: "black" },
      }));

    setOptions(arr);
    setSearching(false);
  };

  // Menu Items
  const menuItems = [
    getItem(
      <span className={styles.menulabel}>Dashboard</span>,
      "admin",
      <HomeOutlined />
    ),
    getItem(
      <span className={styles.menulabel}>Create One Time Test</span>,
      "createmock",
      <ContainerOutlined />,
      null,
      null
    ),
    getItem(
      <span className={styles.menulabel}>Question Bank</span>,
      "questions",
      <UnorderedListOutlined />,
      null,
      null
    ),

    getItem(
      <span className={styles.menulabel}>Batches</span>,
      "managegroups",
      <UsergroupAddOutlined style={{ fontWeight: "600", fontSize: "17px" }} />,
      null,
      null
    ),
  ];

  // Handle Sidbar
  const handlerClick = (e) => {
    setMenuKey(e.key);
    if (["admin", "managegroups", "createmock"].includes(e.key)) {
      navigate(`/${e.key}`);
    } else if (e.key === "questions") {
      setFlag(false);
    }
    setOpenDwa(false);
  };

  // Upload Attachments
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
  };

  // Convert Files to Blob
  function base64ToBlob(base64Content) {
    const byteCharacters = atob(base64Content);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: "image/jpeg" }); // Specify the MIME type accordingly
  }

  // Warning
  const notificationmessage = useCallback(
    (val, err) => {
      messageApi.open({
        type: "warning",
        content: val,
      });
    },
    [messageApi]
  );

  //Final Submit
  const submit = () => {
    const formData = new FormData();

    const parser = new DOMParser();
    const doc = parser.parseFromString(qstn.question, "text/html");

    const imgElement = doc.querySelectorAll("img");
    let images = [...selectedFiles];
    if (imgElement.length > 0) {
      imgElement.forEach((item, ind) => {
        const srcData = item.getAttribute("src");
        const blob1 = base64ToBlob(srcData.split(",")[1]);

        const file = new File([blob1], `questioneditor-${ind}.jpeg`, {
          type: "image/jpeg",
        });

        images.push(file);
      });
    }

    imgElement.forEach((img) => {
      img.removeAttribute("src");
    });

    const updatedHTML = doc.documentElement.outerHTML;

    // Append each selected file to the FormData object
    images?.forEach((file, index) => {
      formData.append(`questionimg`, file);
    });

    let arrValuesInTopics = Topics.map((obj) => obj.value);
    let NewValues = selectedsubTopic.filter(
      (value) => !arrValuesInTopics.includes(value)
    );
    const arr = NewValues.map((item) => ({ value: item, label: item }));
    const isAnyOptionEmpty = Object.values(Options).some(
      (option) => option === ""
    );

    const inputValidation = () => {
      if (title === "" && qstn === "") {
        notificationmessage("Please enter the title or Description");
        return false;
      } else if (MainTags === "Select Topic") {
        notificationmessage("Please select Main Topic");
        return false;
      } else if (selectedsubTopic.length === 0) {
        notificationmessage("Please select at least one Sub Tag");
        return false;
      } else if (type === "Mcq" && (isAnyOptionEmpty || answer === "")) {
        notificationmessage(
          "Please enter all options and select right answer."
        );
        return false;
      }
      return true;
    };

    const typeConfig = {
      Mcq: {
        type: "Mcq",
        point:
          Points > 0
            ? Points
            : level === "Easy"
              ? 1
              : level === "Medium"
                ? 2
                : 3,
        options: [
          Options.option1,
          Options.option2,
          Options.option3,
          Options.option4,
        ],
      },
      Coding: {
        type: "Coding",
        point:
          Points > 0
            ? Points
            : level === "Easy"
              ? 3
              : level === "Medium"
                ? 4
                : 5,
      },
      Subjective: {
        type: "Question",
        point:
          Points > 0
            ? Points
            : level === "Easy"
              ? 2
              : level === "Medium"
                ? 3
                : 4,
      },
    };

    if (!inputValidation()) return;

    const config = typeConfig[type];

    if (config) {
      const obj = {
        newtopics: arr,
        details: {
          qid: uuidv4(),
          type: config.type,
          qstn: {
            ...qstn,
            question:
              parseHTMLText(updatedHTML) === "undefined" ? "" : updatedHTML,
          },
          point: config.point,
          level: level,
          title: title !== "" ? title : undefined,
          concept: selectedsubTopic,
          time: time,
          createdby: `${user?.FullName} on ${new Date()}`,
          maintopics: [MainTags],
          ...(config.options && {
            opt1: config.options[0],
            opt2: config.options[1],
            opt3: config.options[2],
            opt4: config.options[3],
            ans: answer,
          }),
        },
      };

      formData.append("questiondata", JSON.stringify(obj));
      setIssendrequest(true);
      postrequest(`/home/questions/${user?.subdomain}`, formData)
        .then((res) => {
          toast.success("Successfully Added", {
            position: "top-center",
            autoClose: 1500,
          });
          setIssendrequest(false);
          setSelectedSubTopics([]);
          setQstn("");
          setLevel("Easy");
          setType("Subjective");
          fetcher();
          setFlag();
        })
        .catch((err) => {
          setIssendrequest(false);
          toast.warning("server error", {
            position: "top-right",
            autoClose: 1500,
          });
        });
    }
  };
  return (
    <>
      <ToastContainer />
      {contextHolder}

      <div className="d-flex">
        <Sider
          className={styles.slider}
          breakpoint="lg"
          collapsedWidth="0"
          width={window.innerWidth < 350 ? 200 : "18%"}
          trigger={null}
        >
          <DashboardSlider
            menuItems={menuItems}
            // setMenuKey={setMenuKey}
            activekey={menuKey}
            handlerClick={handlerClick}
          />
        </Sider>

        <div>
          {/* Navbar */}
          <LatestNavbar openDwa={openDwa} setOpenDwa={setOpenDwa} />
        </div>
        {/* Create Question Section */}
        <section className={styles.createquestion_main}>
          <div className={styles.leftside}>
            <div>
              {" "}
              <AutoComplete
                className={styles.titleStyle}
                options={options}
                // onSelect={onSelect}
                onSearch={handleSearch}
              >
                <Input
                  size="large"
                  className={styles.titleStyle}
                  placeholder="Question Title"
                  onChange={(val) => setTitle(val.target.value)}
                />
              </AutoComplete>
              {searching && <LoadingOutlined />}{" "}
            </div>
            <div className={styles.descriptioneditor}>
              <ReactQuill
                theme="snow"
                value={editorHtml}
                onChange={handleEditorChange}
                modules={modules}
                formats={formats}
                style={{ height: "250px", borderRadius: "5px" }}
                ref={editorRef}
                placeholder="Question Descriptions"
              />
            </div>
            {/* MCQ Options */}
            {type === "Mcq" && (
              <div className="d-flex mt-5 pt-3 justify-content-around">
                <div>
                  <Form.Group className="d-flex align-items-center w-100">
                    <Form.Check
                      type="radio"
                      name="option"
                      value={Options.option1}
                      className="mx-2"
                      onChange={(e) => setAnswers(e)}
                    />
                    <Form.FloatingLabel label="Option 1" className="Options">
                      <Form.Control
                        value={Options.option1}
                        name="option1"
                        onChange={(e) => Onchange(e)}
                      />
                    </Form.FloatingLabel>
                  </Form.Group>
                  <Form.Group className="d-flex align-items-center mt-3">
                    <Form.Check
                      type="radio"
                      name="option"
                      value={Options.option3}
                      className="mx-2"
                      onChange={(e) => setAnswers(e)}
                    />
                    <Form.FloatingLabel label="Option 3" className="Options">
                      <Form.Control
                        value={Options.option3}
                        name="option3"
                        onChange={(e) => Onchange(e)}
                      />
                    </Form.FloatingLabel>
                  </Form.Group>
                </div>
                <div>
                  <Form.Group className="d-flex align-items-center">
                    <Form.Check
                      type="radio"
                      name="option"
                      value={Options.option2}
                      className="mx-2"
                      onChange={(e) => setAnswers(e)}
                    />
                    <Form.FloatingLabel label="Option 2" className="Options">
                      <Form.Control
                        value={Options.option2}
                        name="option2"
                        onChange={(e) => Onchange(e)}
                      />
                    </Form.FloatingLabel>
                  </Form.Group>
                  <Form.Group className="d-flex align-items-center mt-3">
                    <Form.Check
                      type="radio"
                      name="option"
                      value={Options.option4}
                      className="mx-2"
                      onChange={(e) => setAnswers(e)}
                    />
                    <Form.FloatingLabel label="Option 4" className="Options">
                      <Form.Control
                        value={Options.option4}
                        name="option4"
                        onChange={(e) => Onchange(e)}
                      />
                    </Form.FloatingLabel>
                  </Form.Group>
                </div>
              </div>
            )}
            <div className="mt-5"> {/* Test Cases */}</div>
          </div>
          <div className={styles.rightside}>
            {/* Difficulty  */}
            <Select
              placeholder="Select Difficulty"
              // variant="filled"
              value={level}
              onChange={(e) => setLevel(e)}
              size="large"
              style={{
                width: "200px",
              }}
              options={[
                {
                  value: "Easy",
                  label: "Easy",
                },
                {
                  value: "Medium",
                  label: "Medium",
                },
                {
                  value: "Hard",
                  label: "Hard",
                },
              ]}
            />
            {/* Question Type (Theory/Coding/MCQ) */}
            <Select
              placeholder="Select Question Type"
              // variant="filled"
              onChange={(e) => setType(e)}
              size="large"
              value={type}
              style={{
                width: "200px",
              }}
              options={[
                {
                  value: "Subjective",
                  label: "Subjective",
                },
                {
                  value: "Mcq",
                  label: "MCQ",
                },
                {
                  value: "Coding",
                  label: "Coding",
                },
              ]}
            />
            {/* Main Topic */}
            <Select
              placeholder="Select Main Topic"
              // variant="filled"
              onChange={(val) => setMainTags(val)}
              size="large"
              style={{
                width: "200px",
              }}
              options={MainTopics}
              value={MainTags}
            />
            {/* SubTags */}
            <Select
              placeholder="Select Sub Tag"
              // variant="filled"
              mode={access && access?.role === "Admin" ? "tags" : "multiple"}
              maxTagCount={"responsive"}
              onChange={(val) => setSelectedSubTopics(val)}
              size="large"
              style={{
                width: "200px",
              }}
              options={Topics}
              value={selectedsubTopic}
            />
       
            {/* Question Point */}
            <div className="d-flex flex-column">
              <span>Points (Optional)</span>
              <InputNumber
                max={5}
                addonAfter={"Max " + 5}
                style={{ width: "200px" }}
                controls={false}
                onChange={(val) => setPoints(val)}
                value={Points}
              />
            </div>
            {/* Question Time */}
            <div className="d-flex flex-column">
              <span>Question Time (Optional)</span>
              <InputNumber
                onChange={(val) => setTime(val)}
                value={time}
                max={QuestionWiseTime[type] ?? 300}
                addonBefore={`Max-${QuestionWiseTime[type] ?? 300}`}
                addonAfter={"/Sec"}
                style={{ width: "200px" }}
                controls={false}
                min={minutesMapping[type][level]}
              />
            </div>
            {/* {type === "Coding" && (
              <span>
                <input
                  type="checkbox"
                  style={{ cursor: "pointer" }}
                  onChange={(e) => setIsTestCase(e.target.checked)}
                  id="testcase"
                  checked={IsTestCase || Test}
                />{" "}
                <label htmlFor="testcase" style={{ cursor: "pointer" }}>
                  Add TestCase
                </label>
              </span>
            )} */}
            <div>
              <input
                type="file"
                accept=".png, .jpeg"
                multiple={true}
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="fileupload"
              />
              <label htmlFor="fileupload" className="fileuploadbutton">
                <UploadOutlined /> Upload
              </label>{" "}
              {selectedFiles.length} files selected
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center w-100 mt-3">
            {issendrequest ? (
              <Tag
                bordered={false}
                icon={<SyncOutlined spin />}
                color="processing"
              >
                Creating
              </Tag>
            ) : (
              <Button onClick={() => submit()} ghost type="primary">
                Submit
              </Button>
            )}
          </div>
        </section>
      </div>
      <ProfileSlider
        setOpenDwa={setOpenDwa}
        openDwa={openDwa}
        user={user}
        menuItems={menuItems}
        // setMenuKey={setMenuKey}
        handlerClick={handlerClick}
      />
    </>
  );
};

export default CreateQuestion;
