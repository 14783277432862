import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./LoginModal.module.css";
import microsoftlogo from "../../icons/microsoft.svg";
import logo from "../../../src/utils/logo.png";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { setLoginModalVisibility } from "../../store/ToolFlagsSlice";
import { postrequest, getrequest } from "../Middleware/managerequest";
import Cookies from "js-cookie";
import { loginRequest } from "../Middleware/authConfig";
import { useMsal, UnauthenticatedTemplate } from "@azure/msal-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form, Input, message } from "antd";
import { updateProfilePhoto } from "../../store/StudentProfileSlice";
import {
  setIslogin,
  setAdmin,
  setuser,
  setIssubscribed,
} from "../../store/Reducer";
import jwtDecode from "jwt-decode";
import { LoadingOutlined, SmileOutlined } from "@ant-design/icons";

function LoginModal() {
  const { instance } = useMsal();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [params, setParams] = useState({});
  const [show, setShow] = useState(false);
  const [searchParams] = useSearchParams();
  const { id, ref, client_id } = params;
  const [sendRequest, setSendrequest] = useState(false);
  const { loginModal } = useSelector((state) => state.ToolFlags);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    let params = {};
    for (const key of searchParams) {
      key[0].split("&").forEach((item) => {
        params[item.split("=")[0]] = item.split("=")[1];
      });
    }
    setParams(params);
  }, [searchParams]);

  const handleClose = () => {
    setShow(false);
    dispatch(setLoginModalVisibility(false));
  };
  useEffect(() => {
    if (loginModal === false || loginModal === true) {
      setShow(loginModal);
    }
  }, [loginModal]);
  const handlerequest = (res) => {
    const decodetoken = jwtDecode(res.data.token);
    if (process.env.REACT_APP_ENVIRONMENT === "local") {
      Cookies.set("digikull_local", res.data.token, { expires: 1 });
    }

   
      localStorage.setItem(
        "FullName",
        res.data.FullName ?? decodetoken.FullName
      );
    

    if (res.data.imagesurl) {
      localStorage.setItem("my_profile_image", res.data.imagesurl);
      dispatch(updateProfilePhoto(res.data.imagesurl));
    }
    let obj = {
      Email: decodetoken.Email,
      FullName: res.data.FullName ?? decodetoken.FullName,
      subdomain: decodetoken.subdomain,
    };
    dispatch(setuser(obj));
    dispatch(setAdmin(decodetoken.isAdmin));
    sessionStorage.setItem("Email", decodetoken.Email);
    sessionStorage.setItem("secret", decodetoken.isAdmin);

    if (decodetoken.issubscribed !== undefined) {
      if (decodetoken.issubscribed) {
        let isUsers = decodetoken.isUsers,
          isGroup = decodetoken.isGroup,
          ClientName = decodetoken.companyname;
        dispatch(setIssubscribed({ isUsers, isGroup, ClientName }));
      } else {
        message.info({
          content: "Activate Subscription",
        });
        return true;
      }
    }

    sessionStorage.setItem("subdomain", decodetoken.subdomain);
    let flag = true;
    dispatch(setIslogin(flag));
    dispatch(setLoginModalVisibility(false));
    if (id) {
      if (decodetoken.isAdmin) {
        navigate(`/mockdetails/${id}`);
      } else {
        navigate(`/MyMock/${id}`, {
          state: { external: ref ? true : false },
        });
      }
    } else {
      if (decodetoken.isAdmin) {
        navigate("/admin");
      } else {
        navigate("/user");
      }
    }
  };

  const handleSubmit = (userDetails) => {
    let obj = {
      username: userDetails.email.toLowerCase(),
      password: userDetails.password,
    };
    setSendrequest(true);

    postrequest(`/home/Signin`, obj)
      .then((res) => {
        setSendrequest(false);

        if (res.status === 200) {
          handlerequest(res);
          dispatch(setLoginModalVisibility(false));
        } else {
          if (res.status === 203) {
            messageApi.error({
              content: res?.data?.message,
            });
          }
        }
      })
      .catch((err) => {
        // setSendrequest(false);
        messageApi.error({
          content: "Internal Server Error",
        });
      });
  };

  // Single-Sign-On
  const handleLoginPopup = () => {
    instance
      .loginPopup({
        loginRequest,
      })
      .then((mainres) => {
        if (mainres.accessToken) {
          getrequest(
            `/home/auth/singlesignin/?name=${mainres.account.name}&username=${mainres.account.username}`
          )
            .then((res) => {
              if (res.status === 200) {
                handlerequest(res);
              }
            })
            .catch((err) => {
              messageApi.error({
                content: "server error while login.",
              });
            });
        }
      })
      .catch((error) => {
        messageApi.error({
          content: "There is error while login with Microsoft.",
        });
      });
  };

  return (
    <>
      {/* <ToastContainer/> */}
      {contextHolder}
      <Modal
        show={show}
        onHide={handleClose}
        className={styles.customModal}
        dialogClassName="modal-dialog-centered" // Add this line
      >
        <h2 className={styles.modalTitle}>
          <img src={logo} alt="logi" height={"32px"} />
          DigiKull
        </h2>
        <h5 className={styles.modalSubtitle}>
          Welcome <SmileOutlined />{" "}
        </h5>

        <div
          style={{
            width: "78%",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            marginBottom: "5px",
            gap: "10px",
          }}
        >
          <Form
            form={form}
            name="register"
            onFinish={handleSubmit}
            style={{
              maxWidth: 600,
            }}
            // scrollToFirstError
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter email address",
                },
              ]}
            >
              <Input
                type="text"
                className={styles.inputField}
                placeholder="Enter your email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password!",
                },
              ]}
              // hasFeedback
            >
              <Input.Password
                className={styles.inputField}
                placeholder="Enter your password"
              />
            </Form.Item>
            <Form.Item>
              <button
                disabled={sendRequest}
                type="submit"
                className={styles.loginButton}
              >
                {sendRequest ? <LoadingOutlined /> : "Login"}
              </button>
            </Form.Item>
          </Form>
        </div>

        <h6 className={styles.modalSubtitle}>Or continue with</h6>
        <UnauthenticatedTemplate>
          <div
            className={styles.continueWith}
            onClick={() => handleLoginPopup()}
          >
            <img src={microsoftlogo} alt="microsoft" width={24} />{" "}
            <h5>Digikull</h5>
          </div>
        </UnauthenticatedTemplate>

        <div className={styles.digiKullOptions}>
          <p
            className={styles.createacc}
            onClick={() => {
              navigate("/forget", {
                state: {
                  id: id,
                  client_id: client_id,
                  external: ref ? true : false,
                },
              });
              dispatch(setLoginModalVisibility(false));
            }}
          >
            Forgot Password ?{" "}
          </p>
          <li
            className={styles.createacc}
            onClick={() => {
              navigate("/signup", {
                state: {
                  id: id,
                  client_id: client_id,
                  external: ref ? true : false,
                },
              });
              dispatch(setLoginModalVisibility(false));
            }}
          >
            Create new account
          </li>
        </div>

        <div
          style={{
            width: "78%",
            margin: "auto",
            position: "relative",
            bottom: "5px",
          }}
        >
          <hr style={{ width: "100%" }} />
        </div>

        <p className={styles.privacyPolicy}>Digikull - A Digital Gurukull</p>
      </Modal>
    </>
  );
}

export default LoginModal;
