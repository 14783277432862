import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "+91478-457-7874",
  linkedIn: "",
  resumeLink: "",
  imagesurl: "",
  gitHub: "",
  description: "Please add small description about you",
  address: {
    firstLine: "",
    secondLine: "",
    city: "",
    state: "",
  },
};

const StudentProfileSlice = createSlice({
  name: "studentProfileData",
  initialState,
  reducers: {
 
    updateResumeLink: (state, action) => {
      state.resumeLink = action.payload;
    },
    updateProfilePhoto: (state, action) => {
      state.imagesurl = action.payload;
      localStorage.setItem("my_profile_image",action.payload)
    },
    replaceStudentProfileData: (state, action) => {
      return action.payload;
    },
  },
});

export const {
  updateResumeLink,
  updateProfilePhoto,
  replaceStudentProfileData,
} = StudentProfileSlice.actions;

export default StudentProfileSlice.reducer;
