import React,{useEffect} from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setuser, setAdmin, setIslogin, setIssubscribed } from "../../store/Reducer";
const Sessiontimeout = () => {
  const navigate=useNavigate()
const dispatch=useDispatch()
useEffect(()=>{
  let obj = null,
  flag = false,
  isUsers=false,
  isGroup=false,
  ClientName="Digikull",
  isSubscribed=false,
  MockCount=0
dispatch(setuser(obj));
dispatch(setAdmin(flag));
dispatch(setIslogin(flag));
dispatch(setIssubscribed({isUsers,isGroup,ClientName,isSubscribed,MockCount}))
sessionStorage.clear()
},[dispatch])
  return(
    <Result
    status="403"
    title="403"
    subTitle="Sorry. Your session has been expired. Please Sign In."
    extra={<Button type="primary" onClick={()=>navigate("/")}>Sign In</Button>}
    className='mt-5'
  />
    )
  };
export default Sessiontimeout;