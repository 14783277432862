import React from "react";
import "../styles/home.css";
import bullsEye from "../icons/bullseye.svg";
import Footer from "./Navigator_Footer/Footer";
import html from "../icons/html.svg";
import css from "../icons/css.svg";
import git from "../icons/git.svg";
import github from "../icons/github.svg";
import nodejs from "../icons/nodejs.svg";
import mongodb from "../icons/mongodb.svg";
import javascript from "../icons/javascript.svg";


const Home = () => {
  return (
    <div className="home mt-5 pt-4">
      <main>
        <div className="info">
          <div>
            <img src={bullsEye} alt="Target" width={200} height={200} />
          </div>
          <h2>
            <div>Ace the Interviews</div>
            <div>with Digikull</div>
          </h2>
          <div>Sharpen your Skills and Shine in the Job Market</div>
          {/* <div>
            <button>Free Demo</button>
          </div> */}
        </div>
      </main>

      <section className="library">
        <div>
          <figure>
            <img src={html} alt="html" width={150} height={150} />
            <figcaption>HTML</figcaption>
          </figure>
          <figure>
            <img src={css} alt="css" width={150} height={150} />
            <figcaption>CSS</figcaption>
          </figure>
          <figure>
            <img src={git} alt="git" width={150} height={150} />
            <figcaption>Git</figcaption>
          </figure>
          <figure>
            <img src={javascript} alt="js" width={150} height={150} />
            <figcaption>JavaScript</figcaption>
          </figure>
          <figure>
            <img src={github} alt="github" width={150} height={150} />
            <figcaption>GitHub</figcaption>
          </figure>
          <figure>
            <img src={nodejs} alt="nodejs" width={150} height={150} />
            <figcaption>Node</figcaption>
          </figure>
          <figure>
            <img src={mongodb} alt="mongodb" width={150} height={150} />
            <figcaption>MongoDB</figcaption>
          </figure>
        </div>
        <h2>
          <div>Technologies</div>
          <div>to Master</div>
        </h2>
        <div>Practice makes Perfect</div>
      </section>
      
      <Footer />
    </div>
  );
};

export default Home;
