import styles from "./DashboardSlider.module.css";
import React from "react";

import { Menu, Modal, Layout,Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import CreatableSelect from "react-select/creatable";
const { Sider } = Layout;
const DashboardSlider = ({
  menuItems,
  setMenuKey,
  handleSegment,
  handlerClick,
  setIsSegment,
  isSegment,
  createNewSegment,
  categoryList,
  customStyles,
  activekey,
}) => {
  const { user } = useSelector((state) => state.Data);
  const {imagesurl}=useSelector((state)=>state.StudentProfile)
  return (
    <div className={styles.DashboardBox}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        width={"100%"}

        style={{ backgroundColor: "#ffffff", height: "100vh" }}
      >
        <div className={styles.userProfile}>
        <Avatar src={imagesurl??""}  size={80} icon={<UserOutlined style={{color:"black"}} />} />

          <label style={{color:"black"}}>
            {user?.FullName?.length >= 14
              ? user?.FullName.trim().split(" ")[0]
              : user?.FullName}{" "}
          </label>
          <span style={{color:"black"}}>{user?.Email}</span>
        </div>

        <Menu
          onClick={(e) => {
            handlerClick(e);
          }}
          mode="vertical"
          theme="light"
          items={menuItems}
          className={styles.menuitems}
          selectedKeys={activekey}
        
        />
      </Sider>
      {/* Add Segments */}
      <Modal
        title="Create Segment"
        onCancel={() => setIsSegment(false)}
        open={isSegment}
        onOk={() => createNewSegment()}
        centered
        width={500}
        maskClosable={false}
      >
        <CreatableSelect
          options={categoryList}
          onChange={handleSegment}
          placeholder="Enter new segment name"
          isClearable={true}
          closeMenuOnSelect={true}
          isSearchable={true}
          isMulti={false}
          className="Add_user"
          styles={customStyles}
        />
      </Modal>
    </div>
  );
};

export default DashboardSlider;
