import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Collapse, Steps } from "antd";
// import {UserOutlined,SolutionOutlined,LoadingOutlined,SmileOutlined} from "@ant-design/icons"

const months = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const AllTests = () => {
  const { mockdata } = useSelector((state) => state.Data);
  const [allTopics, setAllTopics] = useState([]);
  const [activekey, setActiveKey] = useState([0]);

  useEffect(() => {
    const topics = [
      ...new Set(
        mockdata.flatMap((mock) => mock?.MockData.map((item) => item.MainTags))
      ),
    ];
    setAllTopics(topics);
  }, [mockdata]);

// Mock Start
const MockStart = ({ val }) => {
  let date = new Date(val);
  let startdate = String(date.getDate()).padStart(2, "0");
  let startmonth = months[date.getMonth()];
  let startyear = date.getFullYear();
  let hours = date.getHours();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  return (
    <div>
      <span>{startdate + " " + startmonth + " " + startyear}</span>
      {` ${
        hours + ":" + String(date.getMinutes()).padStart(2, "0") + " " + ampm
      }`}
    </div>
  );
};


  const RenderDetails=(mock)=>{
return <div className="d-flex w-100 align-items-center ">
  <label>{mock?.MockTitle}</label>
  <label><MockStart val={mock?.MockStart}/></label>
  <label><MockStart val={mock?.Mockend}/></label>
</div>
  }

  const RenderMocks=(topic)=>{
   
    return mockdata.filter(mock=>{
      return mock?.MockData.some(tp=>tp.MainTags===topic)
    }).map(mock=>{
      return {
        title:RenderDetails(mock),
        status:"finish",
        MockStart: new Date(mock.MockStart)
      
      }
    }).sort((a, b) => a.MockStart - b.MockStart);
  }

  const TestItems = (topics) => {
    return topics.map((topic, index) => {
      return {
        key: index,
        label: topic,
        children:<Steps current={2}  direction="vertical" items={RenderMocks(topic)}/>
      };
    });
  };

  return (
    <div>
      <Collapse
        accordion
        defaultActiveKey={activekey}
        onChange={(val) => setActiveKey(val)}
        expandIconPosition="end"
        bordered={false}
        ghost
        items={TestItems(allTopics)}
      />
      {console.log(mockdata)}
    </div>
  );
};

export default AllTests;
