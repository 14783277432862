import React from "react";
import { Popconfirm, Steps } from "antd";
import styles from "./styles/comps.module.css";
import { useSelector, useDispatch } from "react-redux";
import { replaceEducation } from "../../../store/EducationSlice";
import { postrequest } from "../../Middleware/managerequest";
import {
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";

const EduSteps = ({ setShowEditEduModal, setShow, setEditEdu }) => {
  const Education = useSelector((state) => state.Education);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Data);

  return (
    <div style={{ width: "100%", padding: "0 40px 0 40px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px"
        }}
      >
        <h4 style={{ fontFamily: "Poppins" }}>
          Education journey
        </h4>
        <span onClick={() => setShow(true)}>
          <h6 style={{ fontFamily: "Poppins" }} className={styles.AddExp}>
            Add
          </h6>
        </span>
      </div>
      <div>
        {Education.length !== 0 ? (
          <Steps
            direction="vertical"
            current={3}
            items={Education?.map((step, index) => {
              return {
                key: index,
                icon:
                   step.CurrentlyAppearing ? (
                    <ClockCircleOutlined style={{ fontSize: "15px" }} />
                  ) : (
                    <CheckOutlined style={{ fontSize: "15px" }} />
                  ),
                title: (
                  <div className={styles.singleEdu}>
                    <div>
                      {" "}
                      {step.title}
                      {" - "}
                      {step.year}&nbsp;&nbsp;&nbsp;
                    </div>

                    <div className={styles.singleEduIcons} >
                      <span
                        onClick={() => {
                          setEditEdu({ ...step, id: index });
                          setShowEditEduModal(true);
                        }}
                      >
                        <EditOutlined />
                      </span>
                      <Popconfirm
                        okText="Yes"
                        onConfirm={() => {
                          postrequest(
                            `/api/v1/user/personal_details/${user.Email}/?sec=education&&operation=delete&&ind=${index}`
                          )
                            .then((res) => {
                              dispatch(
                                replaceEducation(res.data.EducationDetails)
                              );
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                        title="Are you sure to delete?"
                      >
                        <span>
                          <DeleteOutlined />
                        </span>
                      </Popconfirm>
                    </div>
                  </div>
                ),
                description: step.description,
              };
            })}
          ></Steps>
        ) : (
          <h6
            style={{
              fontFamily: "Poppins",
              // marginBlock: "7vh",
              marginTop: "4vh",
            }}
          >
            No education added yet
          </h6>
        )}
      </div>
    </div>
  );
};

export default EduSteps;
