import React, { useState, useEffect } from "react";
import { setName } from "../../../../store/Reducer";
import { useSelector, useDispatch } from "react-redux";
import { postrequest } from "../../../Middleware/managerequest";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { replaceStudentProfileData } from "../../../../store/StudentProfileSlice";

const EditProfile = ({ show, setShow }) => {
  // const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Data);
  const dispatch = useDispatch()
  const StudentProfile = useSelector((state) => state.StudentProfile);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    description: "",
    gitHub: "",
    linkedIn: "",
    address: {
      firstLine: "",
      secondLine: "",
      city: "",
      state: "",
    },
  });

  useEffect(() => {
    if (StudentProfile) {
      setFormData({ ...StudentProfile });
    }
  }, [StudentProfile]);

  const handleClose = () => setShow(false);

  const handleSave = (data) => {
    // Handle saving data
    postrequest(
      `/api/v1/user/personal_details/${user?.Email}/?sec=personal`,
      formData
    )
      .then((res) => {
      dispatch(replaceStudentProfileData(res.data.Personal_Details
        ))
   dispatch(setName(res.data.Personal_Details))
        
      })
      .catch((err) => {
        console.log(err);
      });
    handleClose();
  };

  const handleAddressChange = (key, value) => {
    setFormData({
      ...formData,
      address: {
        ...formData.address,
        [key]: value,
      },
    });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group controlId="firstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={(e) =>
                      setFormData({ ...formData, firstName: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="lastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={(e) =>
                      setFormData({ ...formData, lastName: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="github">
              <Form.Label>GitHub Link</Form.Label>
              <Form.Control
                type="text"
                name="github"
                value={formData.gitHub}
                onChange={(e) =>
                  setFormData({ ...formData, gitHub: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="linkedIn">
              <Form.Label>LinkedIn Link</Form.Label>
              <Form.Control
                type="text"
                name="linkedIn"
                value={formData.linkedIn}
                onChange={(e) =>
                  setFormData({ ...formData, linkedIn: e.target.value })
                }
              />
            </Form.Group>

            <Form.Group controlId="address">
              <Form.Label>Address</Form.Label>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="City"
                    value={formData.address.city}
                    onChange={(e) =>
                      handleAddressChange("city", e.target.value)
                    }
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="State"
                    value={formData.address.state}
                    onChange={(e) =>
                      handleAddressChange("state", e.target.value)
                    }
                  />
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleSave(formData)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditProfile;
