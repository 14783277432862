import { configureStore } from "@reduxjs/toolkit";
import StudentProfileSlice from "./StudentProfileSlice";
import ExperienceSlice from "./ExperienceSlice";
import EducationSlice from "./EducationSlice";
import QuizData from "./Reducer";
import SessionsReducer from "./Reducers/SessionsReducer";
import ToolFlagsSlice from "./ToolFlagsSlice";

const Store = configureStore({
  reducer: {
    Data: QuizData,
    StudentProfile: StudentProfileSlice,
    Education: EducationSlice,
    Experience: ExperienceSlice,
    Sessions: SessionsReducer,
    ToolFlags: ToolFlagsSlice,
  },
});
export default Store;
